import { ReactNode } from 'react'
import { TooltipStyled, Tooltiptext } from './Tooltip.style'

interface TooltipProps {
  text: string
  children: ReactNode
}

export const Tooltip = ({ text, children }: TooltipProps) => {
  return (
    <TooltipStyled>
      {children}
      <Tooltiptext className='tooltiptext'>{text}</Tooltiptext>
    </TooltipStyled>
  )
}
