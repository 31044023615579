import styled from 'styled-components/macro'
//import { Breakpoints } from 'styles/breakpoints'

export const CardSubMenuStyled = styled.div`
  width: 100%;
`

export const FirstLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #61616b;
  border-top: 1px solid #61616b;
  padding: 16px 0px;
  cursor: pointer;

  margin-bottom: -1px;
`
export const CardSubMenuTitle = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #f8fafc;
`

export const SecondLineContainer = styled.div<{ opened: boolean }>`
  display: ${(props) => (props.opened ? 'block' : 'none')};
`
