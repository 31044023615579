import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

export const useIsWrongChain = () => {
  const wallet = useSelector((state: State) => state.wallet)
  const [isWrongChain, setIsWrongChain] = useState(false)

  useEffect(() => {
    if (wallet.provider) {
      const rightChain = parseInt(process.env.REACT_APP_DEFAULT_CHAINID as string)
      if (wallet.chainId !== rightChain) {
        setIsWrongChain(true)
      }
    }
  }, [wallet])

  return isWrongChain
}
