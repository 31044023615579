// prettier-ignore
import { useErrorListener } from 'hooks/useErrorListener'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { MenuView } from './Menu.view'

export const Menu = () => {
  useErrorListener()
  const version = useSelector((state: State) => state.version.number)

  return <MenuView version={version} />
}
