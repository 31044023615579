
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { BigNumber } from 'ethers'
import BaseContract from './BaseContract'

export default class Erc20 extends BaseContract {
  protected static ctx: Erc20
  protected protocol: EProtocol = EProtocol.ERC20

  constructor(address: string) {
    super()
    if (Erc20.ctx?.address === address) return Erc20.ctx
    this.address = address
    Erc20.ctx = this
  }

  public async balanceOf(account: string) {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return await instance['balanceOf'](account)
  }

  public async getAllowance(owner: string) {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return await instance['allowance'](owner, this.getSpender())
  }

  public async approve(amount: BigNumber) {
    const contractWithSigner = await this.getSmartContractWithSigner()
    return await contractWithSigner['approve'](this.getSpender(), amount)
  }

  public getSpender(): string {
    const spender = this.contractConfig?.transferProxy
    if (!spender) throw Error(`Invalid transfer proxy for protocol ${this.protocol}`)
    return spender
  }
}
