import styled from 'styled-components/macro'
import { backgroundColor, InputIconRight, Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const MarketplaceStyled = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 80px 0 0 0;
`

export const MarketplaceHeader = styled.div`
  position: sticky;
  top: 80px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #61616b;
  background: ${backgroundColor};
  z-index: 1;

  @media (max-width: ${Breakpoints.lg}) {
    justify-content: space-between;
    z-index: 2;
  }
`

export const MarketplaceSearchContainer = styled.div`
  position: relative;
  padding: 24px 68px 16px 40px;
  display: flex;
  flex-direction: column;
  width: 330px;
  border-right: 1px solid #61616b;
  min-height: 100px;

  @media (max-width: ${Breakpoints.lg}) {
    margin: 24px 0px 24px 34px;
    min-height: unset;
    border-right: unset;
  }
`
export const MarketplaceSearch = styled(InputIconRight)`
  border-radius: 32px;
  width: 255px;
  height: 42.79px;
  padding: 0px 15px 0px 15px;
  border: 0px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  ::placeholder {
    color: rgba(248, 250, 252, 0.5);
    opacity: 1;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`

export const ResultsNumber = styled.span`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  color: #f8fafc;

  margin-top: 8px;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  margin-left: 40px;
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const FiltersContainerResponsive = styled.div`
  display: none;
  border-bottom: 1px solid #61616b;
  @media (max-width: ${Breakpoints.lg}) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export const FilterButton = styled.button`
  background: #4934c2;
  box-shadow: 0px 0px 24px #4934c2;
  border-radius: 32px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #f8fafc;
  height: 42.79px;

  border: 0px;
  margin: 24px 12px;

  padding: 12px 24px;
`

export const DeleteFiltersButton = styled(FilterButton)`
  cursor: pointer;
  background: none;
  box-shadow: none;
`

export const FiltersSelectorContainer = styled.div<{ showing: boolean }>`
  border-right: 1px solid;
  border-image: linear-gradient(#616161, rgba(0, 0, 0, 0)) 0 1;
  border-width: 1px;
  width: 330px;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  @media (min-width: ${Breakpoints.lg}) {
    padding-bottom: 4em;
  }

  @media (max-width: ${Breakpoints.lg}) {
    position: absolute;
    width: 100%;
    background: #171717;
    border: 0px;
    overflow: hidden;
    z-index: 1;
    max-height: ${(props) => (props.showing ? 'none' : '0px')};
  }
`
export const FilterSelector = styled.div`
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  > h3 {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #f8fafc;
  }

  & + & {
    border-top: solid 1px #61616b;
  }
`

export const FilterSelectorSearchContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`

export const ArrowImage = styled.img`
  position: absolute;
  right: 24px;
  top: 34px;
  pointer-events: none;
`

export const FilterSelectorSearch = styled(MarketplaceSearch)`
  border-radius: 5px;
  margin-top: 16px;
  margin-bottom: 24px;
`

export const FilterSelectorSelect = styled.select<{ active?: boolean }>`
  background: #232327;
  border-radius: 5px;
  cursor: pointer;
  width: 255px;
  height: 42.79px;
  padding: 0px 15px 0px 15px;
  border: 0px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  margin-top: 16px;
  margin-bottom: 24px;
  color: ${(props) => (props.active ? 'white' : 'rgba(248, 250, 252, 0.5)')};

  -webkit-appearance: none;
  -moz-appearance: none;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`

export const FilterStatusContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 8px;
  margin-bottom: 24px;
  margin-right: -16px;
`
export const FilterStatusButton = styled.button<{ active: boolean }>`
  width: calc(50% - 8px);
  height: 41px;
  text-align: center;
  border-radius: 8px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;
  border: 0px;
  margin-top: 8px;
  cursor: pointer;
  margin-right: 8px;

  background: ${(props) => (props.active ? '#3a3a41' : '#232327')};
`

export const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 40px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const MinMaxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #f8fafc;
  margin-bottom: 24px;
  width: 255px;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`
export const MinMaxInput = styled(FilterSelectorSearch)`
  width: 105px;
  margin: 0px;

  @media (max-width: ${Breakpoints.lg}) {
    width: 45%;
  }
`

export const MarketplaceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`

export const CardsContainer = styled.div`
  flex: 1;
  > div {
    display: grid;
    grid-gap: 60px;
    padding: 38px 90px;

    grid-template-columns: repeat(6, 1fr);

    @media (max-width: ${Breakpoints.quatrek}) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: ${Breakpoints.xxxl}) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: ${Breakpoints.xxl}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${Breakpoints.xxl}) {
      padding: 38px 45px;
    }

    @media (max-width: ${Breakpoints.lg}) {
      width: 100%;
      grid-gap: 48px;
      padding: 32px;
    }

    @media (max-width: ${Breakpoints.md}) {
      grid-gap: 24px;
    }

    @media (max-width: ${Breakpoints.sm}) {
      grid-gap: 16px;
      padding: 24px 32px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${Breakpoints.xs}) {
      grid-gap: 16px;
      padding: 12px 16px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

export const ResponsiveFilterButton = styled.button`
  background: none;
  border: 0;
  display: none;
  margin-right: 34px;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
  }
`

export const RemoveButton = styled.img``

export const MarketplaceLoaderContainer = styled.div`
  grid-column: 1/7;

  @media (max-width: ${Breakpoints.quatrek}) {
    grid-column: 1/6;
  }

  @media (max-width: ${Breakpoints.xxxl}) {
    grid-column: 1/5;
  }

  @media (max-width: ${Breakpoints.xxl}) {
    grid-column: 1/4;
  }

  @media (max-width: ${Breakpoints.sm}) {
    grid-column: 1/3;
  }
`
