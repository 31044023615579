import { Abi, EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { ISignature } from 'Entities/Whitelist/SignatureEntity'
import { BigNumber, ethers } from 'ethers'
import { store } from 'index'

export default class NftCollectionStandard {
  protected protocol: EProtocol = EProtocol.ERC721
  protected static ctx: NftCollectionStandard
  protected address?: string

  constructor(address: string, protected abi: Abi) {
    if (NftCollectionStandard.ctx?.address === address) return NftCollectionStandard.ctx
    this.address = address
    NftCollectionStandard.ctx = this
  }

  public async balanceOfUser(tokenId: number) {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['balanceOfUser'](tokenId)
  }

  public async maxTotalSupply() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['maxTotalSupply']()
  }

  public async totalSupply() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['totalSupply']()
  }

  public async publicSaleStatus() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['publicSaleStatus']()
  }

  public async privateSaleStatus() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['privateSaleStatus']()
  }

  public async privateSaleHardcap() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['privateSaleHardcap']()
  }

  public async maxPublicSaleMintPerTx() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['maxPublicSaleMintPerTx']()
  }

  public async maxPrivateSaleMintPerTx() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['maxPrivateSaleMintPerTx']()
  }

  public async totalSoldInPrivateSale() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['totalSoldInPrivateSale']()
  }

  public async totalSoldInPublicSale() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['totalSoldInPublicSale']()
  }

  public async privateSalePrice() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['privateSalePrice']()
  }

  public async privateSalePhaseID() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['privateSalePhaseID']()
  }

  public async publicSalePrice() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['publicSalePrice']()
  }

  public async availableForSale() {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['availableForSale']()
  }

  public async addressToPrivateSaleMintingAmount(user: string) {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['addressToPrivateSaleMintingAmount'](user)
  }

  public async addressToPrivateSalePhaseToMintingAmount(user: string, phaseId: number) {
    const instance = await this.getSmartContractInstance()
    if (!instance) return
    return instance['addressToPrivateSalePhaseToMintingAmount'](user, phaseId)
  }

  public async mintPublicSale(amount: BigNumber, price: BigNumber) {
    const contractWithSigner = await this.getSmartContractWithSigner()
    if (!contractWithSigner) return
    return contractWithSigner['mintPublicSale'](amount, { value: price.mul(amount) })
  }

  public async mintPrivateSale(signature: ISignature, amount: BigNumber, price: BigNumber) {
    const contractWithSigner = await this.getSmartContractWithSigner()
    if (!contractWithSigner) return
    const { v, r, s } = signature
    return contractWithSigner['mintPrivateSale'](amount, { v, r, s }, { value: price.mul(amount) })
  }

  public async getSmartContractInstance() {
    const provider = this.getProvider()
    const smartContractInstance = new ethers.Contract(this.address!, this.abi as ethers.ContractInterface, provider)
    return smartContractInstance
  }

  private async getSmartContractWithSigner() {
    const signer = this.getSigner()
    const instance = await this.getSmartContractInstance()
    if (!instance) throw Error('Invalid instance')
    const contractWithSigner = instance.connect(signer)
    if (!contractWithSigner) throw Error('Invalid contract with signer')
    return instance.connect(signer)
  }

  private getProvider() {
    if (!process.env.REACT_APP_DEFAULT_CHAINID) throw Error('Invalid chainId')
    let provider
    switch (parseInt(process.env.REACT_APP_DEFAULT_CHAINID)) {
      case 56:
        provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_BSC_RPC_PROVIDER)
        break
      case 4:
        provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RINKEBY_RPC_PROVIDER)
        break
    }
    if (!provider) throw Error('Invalid provider')
    return provider
  }

  private getSigner() {
    const wallet = store.getState().wallet
    const signer = wallet?.web3?.getSigner()
    if (!signer) throw Error('Invalid signer')
    return signer
  }
}
