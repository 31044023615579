// prettier-ignore
import { TokenSupportEntity } from 'Entities'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { getIpfsLink, isUrl } from 'utils'
import { ISale } from '../SalesHistory.controller'
import { TableLineView } from './TableLine.view'

interface TableLineProps {
  sale: ISale
}

export const TableLine = ({ sale }: TableLineProps) => {
  const [image, setImage] = React.useState('/images/marketplace/cards/loading.png')
  const [loading, setLoading] = React.useState(false)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const [buyToken, setBuyToken] = React.useState<TokenSupportEntity>()
  const getIpfsInfos = React.useCallback(async () => {
    setLoading(false)
    if (sale.token.tokenUri) {
      // const ipfsData = await Webservices.Ipfs.get(null, { tokenUri: sale.token.tokenUri })
      if (!sale.token.docUri) return
      if (isUrl(sale.token.docUri)) {
        setImage(sale.token.docUri)
      } else {
        setImage(getIpfsLink(sale.token.docUri))
      }
    }
    setLoading(false)
  }, [sale])

  React.useEffect(() => {
    getIpfsInfos()
  }, [getIpfsInfos])

  React.useEffect(() => {
    if (sale.order.askAssetClass === AssetClass.ETH) {
      setBuyToken(nativeToken!)
      return
    }
    const erc20TokenAddress =
      sale.order.sellAssetClass === AssetClass.ERC721 ? sale.order.askTokenAddress : sale.order.sellTokenAddress

    const supportedToken = supportedTokens.filter(
      (token) => token.protocol === EProtocol.ERC20 && token.address === erc20TokenAddress,
    )
    if (!supportedToken.length) throw Error(`Unsupported token erc20 for order ${sale.order._id}`)
    setBuyToken(supportedToken[0])
  }, [sale, supportedTokens, nativeToken])

  return <TableLineView image={image} sale={sale} loading={loading} buyToken={buyToken} />
}
