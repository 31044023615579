import { ErrorPayload } from 'actions/Errors.actions'
import { Subject } from 'rxjs'

export interface ErrorState {
  eventBus: Subject<ErrorPayload>
}
const errorInitialState: ErrorState = {
  eventBus: new Subject<ErrorPayload>(),
}

export function error(state = errorInitialState, action: any): ErrorState {
  return state
}
