import { Button } from 'app/App.components/Button/Button.view'
import { Slider } from 'pages/Landing/Slider/Slider.view'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'
import { Box, Image } from 'styles'
import {
  AnimationPackOpeningBox,
  BackgroundImage,
  BandBox,
  BandText,
  BigNumber,
  BigNumberText,
  BigTitle1,
  BigTitle2,
  BoosterGif,
  BoxBigNumber,
  BoxBigNumberText,
  BoxTitle,
  BoxTitleMobile,
  Caption,
  CorneredBox,
  EightPartContainer,
  FifthPartContainer,
  FirstBox,
  FirstCard,
  FirstPartContainer,
  FirstPartMobileContainer,
  FourthCard,
  FourthPartContainer,
  FourthPartMobileContainer,
  FourthPartRow,
  FourthPartTextContainerMobile,
  HeadDescription,
  JumboCardFourthPart,
  LandingStyled,
  Line,
  LinearGradientBox,
  OnlyBox,
  ParticipateChallengesBox,
  PartnerBox,
  PartnerLink,
  PartnerWrapper,
  PartnerWrapper2,
  PoweredByBox,
  PoweredByText,
  PurpleSubtitle1,
  PurpleSubtitle2,
  RoadmapDesktop,
  RoadmapMobile,
  RoadmapTablet,
  RoadmapTitleBox,
  RulesBox,
  RulesFigureContainer,
  RulesFigureContainerResponsive,
  RulesRowsBox,
  RulesTextBox,
  RulesTextContainer,
  SecondBoxImage,
  SecondPartContainer,
  SeventhPartContainer,
  Shadow,
  SixthPartContainer,
  SocialLink,
  SocialLinkBox,
  SocialLinkBoxResponsive,
  SocialLinksBox,
  SocialLinksContainerResponsive,
  SubstractBox,
  SubstractImage,
  TextBody,
  TextBold,
  ThirdBoxImage,
  ThirdCard,
  ThirdPartBox,
  ThirdPartContainer,
  ThirdPartRow,
  ThirdPartTextContainer,
  Title,
  TitleImage,
  TradeBox,
  TradeImage,
  VerticalLineLinearGradient,
  VideoBackground,
  WhiteSubtitle1,
  WhiteSubtitle2,
} from './Landing.style'

export const LandingView = () => {
  const baseHomePageImageUrl = '/images/landing/'
  const history = useHistory()
  return (
    <LandingStyled>
      {/* <OpeningPackModal
        openingVideo={'/videos/opening_fan_series.mp4'}
        tokenAddress={'foo'}
        tokenId={1}
        tokenUri={
          'https://cdn.discordapp.com/attachments/981470137171476570/981897391051530261/generated_Image_1000_ed1c7dcb40.png'
        }
        onClose={() => {}}
      /> */}
      <FirstPartContainer>
        <VideoBackground>
          <ReactPlayer
            url="/videos/Home_Multisport_Boucle_Compressed.mp4"
            playing
            muted
            loop
            width="100%"
            height="100%"
          ></ReactPlayer>
          <LinearGradientBox />
        </VideoBackground>
        <HeadDescription>
          <FirstCard>
            <Shadow>
              <Image
                position="absolute"
                left={0}
                top={0}
                alt="come as fan, become a sponsor"
                src={`${baseHomePageImageUrl}/desktop/neon.svg`}
              />
              <BoxTitle>
                <BigTitle1>COME AS A FAN, </BigTitle1>
                <Box display="flex">
                  <BigTitle1>BECOME A</BigTitle1>
                  <Image
                    marginLeft={10}
                    width={207}
                    height={37}
                    alt="Sponsor"
                    src={`${baseHomePageImageUrl}/sponsor.svg`}
                  />
                </Box>
                <Box marginTop={34}>
                  <BigTitle2>COLLECT. PLAY. WATCH. SUPPORT.</BigTitle2>
                </Box>
                <Box display="flex">
                  <BigTitle2>
                    <TextBold>TO EARN</TextBold>
                  </BigTitle2>
                </Box>
              </BoxTitle>
              <Box marginTop={24} marginLeft={20}>
                <Button
                  appearance="primary"
                  clickCallback={() => history.push('/pack')}
                  width={'221px'}
                  fontSize={18}
                  lineHeight={27}
                  padding={18}
                >
                  BUY PACK NOW
                </Button>
              </Box>
            </Shadow>
          </FirstCard>
        </HeadDescription>
      </FirstPartContainer>
      <FirstPartMobileContainer>
        <VideoBackground>
          <BackgroundImage
            max-width={1023}
            alt="background"
            src={`${baseHomePageImageUrl}/mobile/large-background.png`}
          ></BackgroundImage>
          <LinearGradientBox></LinearGradientBox>
        </VideoBackground>
        <HeadDescription>
          <Shadow>
            <BoxTitleMobile>
              <BigTitle1>COME AS A FAN,</BigTitle1>
              <BigTitle1>BECOME A</BigTitle1>
              <Image width={207} height={37} alt="Sponsor" src={`${baseHomePageImageUrl}/sponsor.svg`} />
            </BoxTitleMobile>
          </Shadow>
          <Box marginTop={34}>
            <BigTitle2>COLLECT. PLAY. WATCH. SUPPORT.</BigTitle2>
            <BigTitle2>
              <TextBold>TO EARN</TextBold>
            </BigTitle2>
          </Box>
          <Box marginTop={24}>
            <Button appearance="primary" width={'325px'} clickCallback={() => history.push('/pack')}>
              BUY PACK NOW
            </Button>
          </Box>
        </HeadDescription>
      </FirstPartMobileContainer>
      <SecondPartContainer>
        <OnlyBox>
          <Title>For TRUE fans.&nbsp;</Title>
          <TitleImage alt="Only" src={`${baseHomePageImageUrl}/only.svg`} />
        </OnlyBox>
        <Slider />
      </SecondPartContainer>
      <ThirdPartContainer>
        <TradeBox>
          <Title>Buy. Open. SHIVER.&nbsp;</Title>
          <TitleImage alt="Trade" src={`${baseHomePageImageUrl}/trade.svg`} />
        </TradeBox>
        <ThirdPartBox>
          <BoxBigNumber>
            <BigNumber>01</BigNumber>
            <BoxBigNumberText>
              <BigNumberText>BUY</BigNumberText>
            </BoxBigNumberText>
          </BoxBigNumber>
          <ThirdPartRow>
            <ThirdPartTextContainer>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <WhiteSubtitle1>BUY CARD PACKS TO ENTER THE</WhiteSubtitle1>
                <PurpleSubtitle1>MYTVCHAIN UNIVERSE</PurpleSubtitle1>
              </Box>
            </ThirdPartTextContainer>
            <SubstractBox>
              <SubstractImage alt="Subtract" src={`${baseHomePageImageUrl}/desktop/subtract_1.svg`} />
            </SubstractBox>
          </ThirdPartRow>
        </ThirdPartBox>
      </ThirdPartContainer>
      <FourthPartContainer>
        <FirstBox>
          <ThirdCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Image alt="jumbo card" src={`${baseHomePageImageUrl}/desktop/jumbo-card_2.svg`} />
              </Box>
              <Box marginLeft={108} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box textAlign="start">
                  <WhiteSubtitle1>VIBRATE. SHIVER. LOOK FOR</WhiteSubtitle1>
                  <PurpleSubtitle1>ULTRA-RATE LEGENDARY CARDS</PurpleSubtitle1>
                </Box>
              </Box>
            </Box>
          </ThirdCard>
          <SecondBoxImage>
            <Image alt="Paint yellow" src={`${baseHomePageImageUrl}/desktop/paint_2.svg`} />
          </SecondBoxImage>
        </FirstBox>
        <FourthCard display="flex" justifyContent="center" alignItems="center">
          <BoxBigNumber>
            <BigNumber>02</BigNumber>
            <BoxBigNumberText>
              <BigNumberText>OPEN</BigNumberText>
            </BoxBigNumberText>
          </BoxBigNumber>
        </FourthCard>
      </FourthPartContainer>
      <FourthPartMobileContainer>
        <FourthPartRow>
          <JumboCardFourthPart alt="jumbo card" src={`${baseHomePageImageUrl}/mobile/jumbo-card.png`} />
          <BoxBigNumber>
            <BigNumber>02</BigNumber>
            <BoxBigNumberText>
              <BigNumberText>OPEN</BigNumberText>
            </BoxBigNumberText>
          </BoxBigNumber>
        </FourthPartRow>
        <FourthPartTextContainerMobile>
          <WhiteSubtitle1>VIBRATE. SHIVER. LOOK FOR</WhiteSubtitle1>
          <PurpleSubtitle1>ULTRA-RATE LEGENDARY CARDS</PurpleSubtitle1>
        </FourthPartTextContainerMobile>
      </FourthPartMobileContainer>
      <FifthPartContainer>
        <Box display="flex" flexDirection="column">
          <BoxBigNumber>
            <BigNumber>03</BigNumber>
            <BoxBigNumberText>
              <BigNumberText>SHIVER</BigNumberText>
            </BoxBigNumberText>
          </BoxBigNumber>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box textAlign="start" paddingLeft={32}>
              <WhiteSubtitle2>COLLECT. EARN TOKENS WHEN ATHLETES PERFORMS AND</WhiteSubtitle2>
              <PurpleSubtitle2>TRADE CARD TO MAKE MONEY</PurpleSubtitle2>
            </Box>
          </Box>
        </Box>
        <AnimationPackOpeningBox>
          <BoosterGif src={`${baseHomePageImageUrl}gif-booster-glitch.gif`} />
        </AnimationPackOpeningBox>
      </FifthPartContainer>
      <SixthPartContainer>
        <TradeImage alt="04" src={`${baseHomePageImageUrl}/04.svg`} />
        <Box margin={'0 10px'}>
          <TextBody>
            <TextBold>Earn tokens</TextBold> by supporting your athletes on our WEB TV platform <br />
            keep your cards & participate in our <TextBold>PLAY2EARN games (coming soon)</TextBold>
          </TextBody>
        </Box>
        <Box marginTop={30} marginBottom={30}>
          <TextBody>
            <TextBold>YOU DECIDE. YOU TAKE BACK CONTROL.</TextBold>
          </TextBody>
        </Box>
        <Button width={'221px'} appearance="tertiary" clickCallback={() => history.push('/pack')}>
          LET'S GO
        </Button>
      </SixthPartContainer>
      <ThirdBoxImage>
        <Image alt="Paint cyan" src={`${baseHomePageImageUrl}/desktop/paint_3.svg`} />
      </ThirdBoxImage>
      <SeventhPartContainer>
        <RulesTextContainer>
          <RulesTextBox>
            <Title>WE CHANGE THE &nbsp;</Title>
            <TitleImage alt="Trade" src={`${baseHomePageImageUrl}/rules.svg`} />
          </RulesTextBox>
          <Box>
            <Title>NOT THE GAMES</Title>
          </Box>
        </RulesTextContainer>
        <RulesBox>
          <Box display="flex" alignItems="strech">
            <VerticalLineLinearGradient />
            <RulesRowsBox>
              <CorneredBox marginBottom={25}>
                <Caption>SUPPORT YOUR FAVORITE ATHLETES AND CLUBS, BY BUYING THEIR NFTS ON THE MARKETPLACE.</Caption>
              </CorneredBox>
              <CorneredBox marginBottom={25}>
                <Caption>THEY EARN MONEY THANKS TO YOU, THEY STAY FOCUSED ON COMPETITIONS AND WIN MORE TITLES.</Caption>
              </CorneredBox>
              <CorneredBox marginBottom={25}>
                <Caption>YOUR CARD'S VALUE INCREASE WHEN THEY PERFORMS WELL. WHEN THEY WIN. YOU WIN TOO.</Caption>
              </CorneredBox>
              <CorneredBox>
                <Caption>REVENUES GENERATED BY CARD PACK SALES ARE FAIRLY REDISTRIBUTED AMONG EACH ATHLETE.</Caption>
              </CorneredBox>
            </RulesRowsBox>
          </Box>
          <RulesFigureContainer>
            <Image width={580} alt="Rules figure" src={`${baseHomePageImageUrl}/rules_fig.png`} />
          </RulesFigureContainer>
          <RulesFigureContainerResponsive>
            <Image alt="Rules figure" src={`${baseHomePageImageUrl}/full_rules_fig.png`} />
          </RulesFigureContainerResponsive>
        </RulesBox>
      </SeventhPartContainer>
      <Line />
      <BandBox>
        <BandText>YOU HELP ATHLETES TO GROW UP. YOU'RE A SPONSOR. YOU'RE PART OF THEIR CAREER SUCCESS.</BandText>
      </BandBox>
      <Line />
      <EightPartContainer>
        <Title>COMMUNITY</Title>
        <SocialLinksBox>
          <SocialLinkBox>
            <SocialLink target="_blank" href="https://discord.gg/M2GAQQ64Fa">
              <Image alt="Discord" src={`/images/discord.svg`} />
            </SocialLink>
          </SocialLinkBox>
          <SocialLinkBox>
            <SocialLink target="_blank" href="https://twitter.com/MyTVchain">
              <Image alt="Twitter" src={`/images/twitter.svg`} />
            </SocialLink>
          </SocialLinkBox>
          <SocialLinkBox>
            <SocialLink target="_blank" href="https://www.facebook.com/MyTVchain/">
              <Image alt="Facebook" src={`/images/facebook.svg`} />
            </SocialLink>
          </SocialLinkBox>
          <SocialLinkBox>
            <SocialLink target="_blank" href="https://fr.linkedin.com/company/mytvchain">
              <Image alt="Linkedin" src={`/images/linkedin.svg`} />
            </SocialLink>
          </SocialLinkBox>
          <SocialLinkBox>
            <SocialLink target="_blank" href="https://www.instagram.com/mytvchain/">
              <Image alt="Instagram" src={`/images/instagram.svg`} />
            </SocialLink>
          </SocialLinkBox>
        </SocialLinksBox>
        <SocialLinksContainerResponsive>
          <SocialLinkBoxResponsive target="_blank" href="https://www.instagram.com/mytvchain/">
            <Image alt="Instagram" src={`/images/instagram.svg`} />
          </SocialLinkBoxResponsive>
          <SocialLinkBoxResponsive target="_blank" href="https://twitter.com/MyTVchain">
            <Image alt="Twitter" src={`/images/twitter.svg`} />
          </SocialLinkBoxResponsive>
          <SocialLinkBoxResponsive target="_blank" href="https://www.facebook.com/MyTVchain/">
            <Image alt="Facebook" src={`/images/facebook.svg`} />
          </SocialLinkBoxResponsive>
          <SocialLinkBoxResponsive target="_blank" href="https://fr.linkedin.com/company/mytvchain">
            <Image alt="Linkedin" src={`/images/linkedin.svg`} />
          </SocialLinkBoxResponsive>
          <SocialLinkBoxResponsive isLast={true} target="_blank" href="https://discord.gg/M2GAQQ64Fa">
            <Image alt="Discord" src={`/images/discord.svg`} />
          </SocialLinkBoxResponsive>
        </SocialLinksContainerResponsive>
      </EightPartContainer>
      <ParticipateChallengesBox>
        <Button appearance="primary_empty" clickCallback={() => history.push('/challenge')} width={'300px'}>
          PARTICIPATE CHALLENGES
        </Button>
      </ParticipateChallengesBox>
      <RoadmapTitleBox>
        <Title>ROADMAP</Title>
      </RoadmapTitleBox>
      <RoadmapDesktop alt="Roadmap" src={`${baseHomePageImageUrl}/desktop/roadmap.png`} />
      <RoadmapMobile alt="Roadmap" src={`${baseHomePageImageUrl}/mobile/roadmap.png`} />
      <RoadmapTablet alt="Roadmap" src={`${baseHomePageImageUrl}/mobile/roadmap_tablet.png`} />
      <Box margin={'100px 0'}>
        <Line />
        <PoweredByBox>
          <PoweredByText>POWERED BY</PoweredByText>
          <a href="https://mytvchain.io/" target="_blank" rel="noreferrer">
            <Image margin={'20px'} alt="MyTvChain" src="/logo.svg" />
          </a>
          <a href="https://smart-chain.fr/" target="_blank" rel="noreferrer">
            <Image margin={'20px'} alt="SmartChain" src="/logo/smartchain.svg" />
          </a>
          <a href="https://www.theblox.co/" target="_blank" rel="noreferrer">
            <Image margin={'20px'} alt="TheBlox" src="/logo/theblox.svg" />
          </a>
        </PoweredByBox>
        <Line />
      </Box>
      <Box margin={'4vw'} textAlign="start">
        <Title>THEY ALREADY BELIEVE IN US</Title>
      </Box>
      <PartnerBox>
        <PartnerWrapper>
          <PartnerLink>
            <Image alt="JVC" src={`/logo/jvc.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Fuchs sports" src={`/logo/fuchs.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="AS Monaco" src={`/logo/asm.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Chimera" src={`/logo/chimera.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="CNOSF" src={`/logo/cnosf.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Cointribune" src={`/logo/cointribune.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Sport en France" src={`/logo/sport-en-france.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Binance Smart Chain" src={`/logo/bsc.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="GSR" src={`/logo/gsr.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="EIC Corporation" src={`/logo/eic.png`} />
          </PartnerLink>
        </PartnerWrapper>
        <PartnerWrapper2>
          <PartnerLink>
            <Image alt="JVC" src={`/logo/jvc.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Fuchs sports" src={`/logo/fuchs.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="AS Monaco" src={`/logo/asm.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Chimera" src={`/logo/chimera.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="CNOSF" src={`/logo/cnosf.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Cointribune" src={`/logo/cointribune.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Sport en France" src={`/logo/sport-en-france.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="Binance Smart Chain" src={`/logo/bsc.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="GSR" src={`/logo/gsr.png`} />
          </PartnerLink>
          <PartnerLink>
            <Image alt="EIC Corporation" src={`/logo/eic.png`} />
          </PartnerLink>
        </PartnerWrapper2>
      </PartnerBox>
    </LandingStyled>
  )
}
