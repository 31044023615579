import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

export const useErrorListener = () => {
  const { enqueueSnackbar } = useSnackbar()
  const eventBus = useSelector((state: State) => state.error.eventBus)

  useEffect(() => {
    const subscription = eventBus.subscribe({
      next: (e) => {
        enqueueSnackbar(e.payload.message, { variant: 'error' })
        console.warn(e)
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [eventBus, enqueueSnackbar])
}
