// prettier-ignore
import { TokenSupportEntity } from 'Entities'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { getIpfsLink, isUrl } from 'utils'
import { IPairOrderNftEntity } from '../Activities.controller'
import { ActivityAction, ActivityType } from '../Activities.enum'
import { TableLineView } from './TableLine.view'

interface TableLineProps {
  activity: IPairOrderNftEntity
  type: ActivityType
  deleteLine: (activity: IPairOrderNftEntity) => void
}

export const TableLine = ({ activity, type, deleteLine }: TableLineProps) => {
  // let state = type === ActivityType.AUCTIONS ? id % 3 : (id % 2) + 3

  // let now = new Date()
  // now.setDate(now.getDate() + 2)
  // let deadline = now.toString()

  // if (state === ActivityAction.FINALIZE) {
  //   deadline = 'Fri Jan 28 2022 11:00:00 UTC'

  const [image, setImage] = useState('/images/marketplace/cards/loading.png')
  const [loading, setLoading] = useState(false)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const [buyToken, setBuyToken] = useState<TokenSupportEntity>()
  const nativeToken = useSelector((state: State) => state.nativeToken)

  const getIpfsInfos = useCallback(async () => {
    setLoading(false)
    if (activity.token.tokenUri) {
      if (!activity.token.docUri) return
      if (isUrl(activity.token.docUri)) {
        setImage(activity.token.docUri)
      } else {
        setImage(getIpfsLink(activity.token.docUri))
      }
    }
    setLoading(false)
  }, [activity])

  useEffect(() => {
    getIpfsInfos()
  }, [getIpfsInfos])

  useEffect(() => {
    if (activity.order.askAssetClass === AssetClass.ETH) {
      setBuyToken(nativeToken!)
      return
    }

    const erc20TokenAddress =
      activity.order.sellAssetClass === AssetClass.ERC721
        ? activity.order.askTokenAddress
        : activity.order.sellTokenAddress

    const supportedToken = supportedTokens.filter(
      (token) => token.protocol === EProtocol.ERC20 && token.address === erc20TokenAddress,
    )
    if (!supportedToken.length) throw Error(`Unsupported token erc20 for order ${activity.order._id}`)
    setBuyToken(supportedToken[0])
  }, [activity, supportedTokens, type, nativeToken])

  return (
    <TableLineView
      type={type}
      activity={activity}
      image={image}
      loading={loading}
      buyToken={buyToken}
      state={type === ActivityType.OFFERS_MADE ? ActivityAction.CANCEL : ActivityAction.ACCEPT}
      deleteLine={deleteLine}
    />
  )
}
