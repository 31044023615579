import styled from 'styled-components/macro'

export const CardActionSaleButtonContainer = styled.div`
  margin: 56px auto 5px auto;
  width: 250px;
  display: flex;
  justify-content: center;
`

export const CardActionSaleContainer = styled.div`
  margin-top: 30px;
`
