import { TokenSupportEntity } from 'Entities'
import { Filter, Filters, FilterType, getOnOfferFilter, getTokenPriceFilter } from './Filter'
import { PriceSorts, Sort, Sorts, SortType } from './Sort'

export function getFilterAndSortFromSearchParams(searchParams: URLSearchParams, supportedTokens: TokenSupportEntity[]) {
  const filters: Filter[] = []
  let sort: Sort = Sorts[SortType.NO_SORT]
  let priceSort: Sort = Sorts[SortType.NO_SORT]
  let selectedToken: TokenSupportEntity | null = null
  searchParams.forEach((value, key) => {
    switch (key) {
      case 'filter':
        const filtersType = value.split(',')
        filtersType.forEach((type) => {
          switch (type) {
            case FilterType.PRICE:
              if (searchParams.has('token')) {
                let min: string | null = null
                let max: string | null = null
                if (searchParams.has('min')) {
                  min = searchParams.get('min')!
                }
                if (searchParams.has('max')) {
                  max = searchParams.get('max')!
                }
                if (searchParams.has('priceSort')) {
                  priceSort = PriceSorts[searchParams.get('priceSort')!]
                }

                const tokens = supportedTokens.filter((token) => token.symbol === searchParams.get('token'))
                if (tokens.length) {
                  selectedToken = tokens[0]
                  const tokenPriceFilter = getTokenPriceFilter(selectedToken, min, max)
                  filters.push(tokenPriceFilter)
                }
              }
              break
            case FilterType.ON_OFFER:
              if (searchParams.has('ownerAddress')) {
                const ownerAddress = searchParams.get('ownerAddress')!
                filters.push(getOnOfferFilter(ownerAddress))
                break
              }
              filters.push(getOnOfferFilter())
              break
            default:
              filters.push(Filters[type as FilterType])
              break
          }
        })
        break
      case 'sort':
        sort = Sorts[value]
        break
    }
  })
  return { filters, sort, selectedToken, priceSort }
}

export function addFiltersAndSortToUrl(url: string, history: any, filters?: Filter[], sort?: Sort, priceSort?: Sort) {
  if (filters?.length || sort) {
    url = url.concat('?')
  }
  if (filters?.length) {
    let args = ''
    url = url.concat('&filter=')
    const filtersType: string[] = []
    filters.forEach((filter) => {
      filtersType.push(filter.type)
      if (filter.args) {
        Object.keys(filter.args).forEach((key) => (args = args.concat(`&${key}=${filter.args![key]}`)))
      }
    })
    url = url.concat(filtersType.toString()).concat(args)
  }
  if (sort) {
    url = url.concat(`&sort=${sort.type}`)
  }
  if (priceSort) {
    url = url.concat(`&priceSort=${priceSort.type}`)
  }
  history.push(url)
}
