import useSendinblueContactApi from 'hooks/useSendinblue'
import { useCallback, useState } from 'react'

import { ButtonInside, ButtonSignUp, EmailInput, EmailInputSkew, EmailSentText, EmailStyled, InputsContainer } from './EmailComponent.style'

interface EmailComponentProps {
  buttonText: string
  backgroundColorInput?: string
  colorInput?: string
  skew: Boolean
  emailListIds: number[]
}
const SibApiV3Sdk = require('@sendinblue/client')

export const EmailComponent = ({ buttonText, backgroundColorInput, skew, emailListIds, colorInput }: EmailComponentProps) => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const sendinblueContactApi = useSendinblueContactApi()

  const addEmailToSendinBlue = useCallback(
    (email: string) => {
      let createContact = new SibApiV3Sdk.CreateContact()
      createContact.email = email
      createContact.listIds = emailListIds
      sendinblueContactApi.createContact(createContact).then(
        function (data: any) {
          setEmail('')
        },
        function (error: any) {
          console.error(error)
        },
      )
    },
    [sendinblueContactApi, emailListIds, setEmail],
  )

  return (
    <EmailStyled>
      {emailSent ? <EmailSentText>you successfully joined the waitlist!</EmailSentText> : <>
        <InputsContainer>
          <EmailInput
            colorInput={colorInput}
            backgroundColorInput={backgroundColorInput}
            value={email}
            placeholder="E-mail"
            type={'email'}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          ></EmailInput>
          {skew ? (
            <EmailInputSkew
              backgroundColorInput={backgroundColorInput}
              value={email}
              type={'email'}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            ></EmailInputSkew>
          ) : (
            <></>
          )}
        </InputsContainer>
        <ButtonSignUp margin={true} appearance="primary" disabled={false} onClick={() => { addEmailToSendinBlue(email); setEmailSent(true) }}>
          <ButtonInside>{buttonText}</ButtonInside>
        </ButtonSignUp>
      </>}
    </EmailStyled>
  )
}
