import { Arrow } from 'app/App.components/Arrow/Arrow.view'
import { ReactNode, useState } from 'react'

import { CardSubMenuStyled, CardSubMenuTitle, FirstLineContainer, SecondLineContainer } from './CardSubMenu.style'

type CardSubMenuProps = {
  title: string
  children: ReactNode
}

export const CardSubMenu = ({ title, children }: CardSubMenuProps) => {
  const [opened, setOpened] = useState(false)
  return (
    <CardSubMenuStyled>
      <FirstLineContainer
        onClick={() => {
          setOpened(!opened)
        }}
      >
        <CardSubMenuTitle>{title}</CardSubMenuTitle>
        <Arrow type={opened ? 'up' : 'down'}></Arrow>
      </FirstLineContainer>
      <SecondLineContainer opened={opened}>{children}</SecondLineContainer>
    </CardSubMenuStyled>
  )
}
