import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { Dispatch } from 'redux'
import * as Webservices from 'Webservices'

export const SET_NATIVE_TOKEN = 'SET_NATIVE_TOKEN'

export const setNativeToken =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!process.env.REACT_APP_DEFAULT_CHAINID) return
    const tokensSupports = await Webservices.TokensSupport.get(null, {
      q: { protocol: EProtocol.UNDEFINED, chainId: process.env.REACT_APP_DEFAULT_CHAINID },
    })
    if (tokensSupports?.length) {
      dispatch({
        type: SET_NATIVE_TOKEN,
        token: tokensSupports[0],
      })
    }
  }
