export type Sort = {
  type: string
  label: string
  sort: { [key: string]: any }
}

export enum SortType {
  NO_SORT = '',
  SORT_NEW_OLD = 'new_old',
  SORT_OLD_NEW = 'old_new',
  SORT_A_Z = 'a_z',
  SORT_Z_A = 'z_a',
  SORT_PRICE_LOW_HIGH = 'low,high',
  SORT_PRICE_HIGH_LOW = 'high,low',
}

export const Sorts = {
  [`${SortType.NO_SORT}`]: {
    type: SortType.NO_SORT,
    label: 'Sort by',
    sort: {},
  },
  [`${SortType.SORT_NEW_OLD}`]: {
    type: SortType.SORT_NEW_OLD,
    label: 'New to Old',
    sort: { 'sellOrder.createdAt': -1 },
  },
  [`${SortType.SORT_OLD_NEW}`]: {
    type: SortType.SORT_OLD_NEW,
    label: 'Old to New',
    sort: { 'sellOrder.createdAt': 1 },
  },
  [`${SortType.SORT_A_Z}`]: {
    type: SortType.SORT_A_Z,
    label: 'A to Z',
    sort: { name: 1 },
  },
  [`${SortType.SORT_Z_A}`]: {
    type: SortType.SORT_Z_A,
    label: 'Z to A',
    sort: { name: -1 },
  },
}

export const PriceSorts = {
  [`${SortType.SORT_PRICE_LOW_HIGH}`]: {
    type: SortType.SORT_PRICE_LOW_HIGH,
    label: 'Price Low to High',
    sort: { 'sellOrder.askTokenAmount': 1 },
  },
  [`${SortType.SORT_PRICE_HIGH_LOW}`]: {
    type: SortType.SORT_PRICE_HIGH_LOW,
    label: 'Price High to Low',
    sort: { 'sellOrder.askTokenAmount': -1 },
  },
}
