import { BigNumber, ethers } from 'ethers'
import Web3Modal from 'web3modal'

import {
  IWalletAction,
  LOGIN,
  LOGOUT,
  SET_ADDRESS,
  SET_BALANCE,
  SET_CHAINID,
  SET_MYTV_BALANCE,
  SET_PROVIDER,
  SET_WEB3,
} from '../actions/Wallet.actions'
import { SET_CONNECTED, SET_WEB3MODAL } from './../actions/Wallet.actions'

export interface WalletState {
  address?: string
  isConnected: boolean
  balance?: BigNumber
  myTvBalance?: BigNumber
  web3?: ethers.providers.Web3Provider
  chainId?: number
  provider?: any
  web3Modal?: Web3Modal
}

const walletDefaultState: WalletState = {
  isConnected: false,
}

export function wallet(state = walletDefaultState, action: IWalletAction): WalletState {
  switch (action.type) {
    case SET_ADDRESS:
      return {
        ...state,
        address: action.address,
      }
    case SET_BALANCE:
      return {
        ...state,
        balance: action.balance,
      }
    case SET_MYTV_BALANCE:
      return {
        ...state,
        myTvBalance: action.myTvBalance,
      }
    case SET_WEB3:
      return {
        ...state,
        web3: action.web3,
      }
    case SET_PROVIDER:
      return {
        ...state,
        provider: action.provider,
      }
    case SET_CHAINID:
      return {
        ...state,
        chainId: action.chainId,
      }
    case SET_WEB3MODAL:
      return {
        ...state,
        web3Modal: action.web3Modal,
      }
    case SET_CONNECTED:
      return {
        ...state,
        isConnected: true,
      }
    case LOGIN:
      return {
        address: action.address,
        myTvBalance: action.myTvBalance,
        balance: action.myTvBalance,
        web3: action.web3,
        provider: action.provider,
        isConnected: true,
        chainId: action.chainId,
        web3Modal: action.web3Modal,
      }
    case LOGOUT:
      return {
        ...state,
        isConnected: false,
        address: undefined,
        myTvBalance: undefined,
        balance: undefined,
        provider: undefined,
        web3Modal: undefined,
        web3: undefined,
      }

    default:
      return state
  }
}
