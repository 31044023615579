import TokenSupportEntity, { IPhase, PhaseStatus } from 'Entities/Marketplace/TokenSupportEntity'
import { BigNumber } from 'ethers'
import React, { useEffect, useState } from 'react'
import { LoadingBar } from '../LoadingBar/LoadingBar.view'
import {
  AmountSold,
  InfoContainer,
  LoadingBarContainer,
  PhasesContainer,
  PhaseTitle,
  SinglePhaseContainer,
  SubInfosContainer,
} from './InfoComponent.style'
import { SubInfo } from './SubInfo/SubInfo.view'

interface InfoComponentProps {
  tokenSupport?: TokenSupportEntity
  tokensSoldPublic: BigNumber | null
  tokensSoldPrivate: BigNumber | null
  privateSaleHardCap: BigNumber | null
  maxTotalSupply: BigNumber | null
  totalSupply: BigNumber | null
  publicActive: boolean | null
  privateActive: boolean | null
  privateSalePhaseId: number
}

export const InfoComponent = ({
  tokenSupport,
  tokensSoldPublic,
  tokensSoldPrivate,
  privateSaleHardCap,
  maxTotalSupply,
  publicActive,
  privateActive,
  privateSalePhaseId,
  totalSupply,
}: InfoComponentProps) => {
  const metadata = tokenSupport?.metadata?.front

  const [tokensSold, setTokensSold] = useState<BigNumber | null>(null)
  const [mainProgress, setMainProgress] = useState<number>(0)
  const [progressPrivate1, setProgressPrivate1] = useState<number>(0)
  const [progressPrivate2, setProgressPrivate2] = useState<number>(0)
  const [progressPublic, setProgressPublic] = useState<number>(0)
  const [hardcapPerUser, setHarcapPerUser] = useState('--')

  const [phase, setPhase] = useState<null | IPhase>(null)

  useEffect(() => {
    if (tokensSoldPrivate && tokensSoldPublic) {
      setTokensSold(tokensSoldPrivate.add(tokensSoldPublic))
    }
  }, [tokensSoldPublic, tokensSoldPrivate])

  useEffect(() => {
    if (tokensSoldPrivate && tokensSoldPublic && maxTotalSupply && tokensSold) {
      const mainProgress = (tokensSold.toNumber() * 100) / maxTotalSupply.toNumber()
      setMainProgress(mainProgress < 1 ? 1 : mainProgress)
    }
  }, [tokensSold, tokensSoldPrivate, tokensSoldPublic, maxTotalSupply])

  useEffect(() => {
    if (publicActive) {
      const publicPhase = metadata?.phases.filter((phase) => phase.phase === PhaseStatus.PUBLIC_SALE)
      if (publicPhase && publicPhase.length) {
        setPhase(publicPhase[0])
        return
      }
    }

    if (privateActive && privateSalePhaseId === PhaseStatus.PRIVATE_SALE_1) {
      const privatePhase = metadata?.phases.filter((phase) => phase.phase === PhaseStatus.PRIVATE_SALE_1)
      if (privatePhase && privatePhase.length) {
        setPhase(privatePhase[0])
        return
      }
    }

    if (privateActive && privateSalePhaseId === PhaseStatus.PRIVATE_SALE_2) {
      const privatePhase = metadata?.phases.filter((phase) => phase.phase === PhaseStatus.PRIVATE_SALE_2)
      if (privatePhase && privatePhase.length) {
        setPhase(privatePhase[0])
        return
      }
    }

    const filterNextPhase = metadata?.phases.filter((phase) => phase.phase === metadata?.nextPhase)
    if (!privateActive && !publicActive && filterNextPhase?.length) {
      setPhase(filterNextPhase[0])
      return
    }

    // if (metadata?.phases.length) {
    //   setPhase(metadata?.phases[metadata?.phases.length - 1])
    // }
  }, [metadata, publicActive, privateActive, privateSalePhaseId])

  useEffect(() => {
    if (privateActive && privateSalePhaseId === PhaseStatus.PRIVATE_SALE_1) {
      setProgressPrivate1(mainProgress)
      // setProgressPrivate1(0)
      if (privateSaleHardCap) setHarcapPerUser(privateSaleHardCap?.toString() + ' NFTs')
      return
    }

    if (privateActive && privateSalePhaseId === PhaseStatus.PRIVATE_SALE_2) {
      setProgressPrivate1(100)
      setProgressPrivate2(mainProgress)
      // setProgressPrivate2(0)
      if (privateSaleHardCap) setHarcapPerUser(privateSaleHardCap?.toString() + ' NFTs')
      return
    }

    if (publicActive) {
      setProgressPrivate1(100)
      setProgressPrivate2(100)
      setProgressPublic(mainProgress)
      // setProgressPublic(0)
      setHarcapPerUser('NO LIMIT')
      return
    }

    switch (metadata?.nextPhase) {
      case PhaseStatus.PRIVATE_SALE_1:
        if (privateSaleHardCap) setHarcapPerUser(privateSaleHardCap?.toString() + ' NFTs')
        break
      case PhaseStatus.PRIVATE_SALE_2:
        if (privateSaleHardCap) setHarcapPerUser(privateSaleHardCap?.toString() + ' NFTs')
        setProgressPrivate1(100)
        break
      case PhaseStatus.PUBLIC_SALE:
        if (tokensSoldPrivate?.isZero()) {
          return
        }
        setHarcapPerUser('NO LIMIT')
        setProgressPrivate1(100)
        setProgressPrivate2(100)
        break
      default:
        setProgressPrivate1(100)
        setProgressPrivate2(100)
        setProgressPublic(100)
    }
  }, [
    mainProgress,
    metadata,
    privateActive,
    privateSaleHardCap,
    privateSalePhaseId,
    publicActive,
    tokenSupport,
    tokensSoldPrivate,
  ])

  return (
    <InfoContainer>
      <PhasesContainer>
        {metadata?.phases.map((phase: IPhase, i) => {
          return (
            <SinglePhaseContainer key={i}>
              <PhaseTitle>{phase.phaseName}</PhaseTitle>
              {phase.phase === PhaseStatus.PRIVATE_SALE_1 && <LoadingBar loadingPercent={progressPrivate1} />}
              {phase.phase === PhaseStatus.PRIVATE_SALE_2 && <LoadingBar loadingPercent={progressPrivate2} />}
              {phase.phase === PhaseStatus.PUBLIC_SALE && <LoadingBar loadingPercent={progressPublic} />}
            </SinglePhaseContainer>
          )
        })}
      </PhasesContainer>
      <SubInfosContainer>
        <SubInfo title={'Unique NFTs'} value={maxTotalSupply ? maxTotalSupply.toString() : '--'} />
        <SubInfo title={'MyTVchain'} value={phase ? phase.myTvchain : '--'} />
        <SubInfo title={'Starring'} value={phase ? phase.staring : '--'} />
        <SubInfo title={'Mint date'} value={phase ? phase.mintDate : '--'} />
        <SubInfo title={'Max amount per user'} value={hardcapPerUser} />
      </SubInfosContainer>
      <LoadingBarContainer>
        <LoadingBar loadingPercent={mainProgress} />
        <AmountSold>
          {totalSupply ? totalSupply.toString() : '--'}/{maxTotalSupply ? maxTotalSupply.toString() : '--'} sold
        </AmountSold>
      </LoadingBarContainer>
    </InfoContainer>
  )
}
