import {
  AcceptStyled,
  AcceptSubtotal,
  AcceptFees,
  AcceptInputContainer,
  AcceptInput,
  AcceptInputSkewLeft,
  AcceptInputSkewRight,
  AcceptInputRight,
  AcceptFeesContainer,
  AcceptFeesLeft,
  AcceptFeesRight,
  AcceptSeparator,
  AcceptEarnings,
  AcceptButtonContainer,
} from './Accept.style'

import { Button } from 'app/App.components/Button/Button.view'
interface AcceptViewProps {
  subTotal: number
  earnings: number
}
export const AcceptView = ({ subTotal, earnings }: AcceptViewProps) => {
  return (
    <AcceptStyled>
      <AcceptSubtotal>Subtotal</AcceptSubtotal>
      <AcceptInputContainer disabled={true}>
        <AcceptInput value={subTotal} disabled />
        <AcceptInputSkewLeft />
        <AcceptInputRight>MYTV</AcceptInputRight>
        <AcceptInputSkewRight />
      </AcceptInputContainer>
      <AcceptFees>Fees</AcceptFees>
      <AcceptFeesContainer>
        <AcceptFeesLeft>Service fees</AcceptFeesLeft>
        <AcceptFeesRight>2.5%</AcceptFeesRight>
        <AcceptFeesLeft>Creator royalties</AcceptFeesLeft>
        <AcceptFeesRight>10%</AcceptFeesRight>
      </AcceptFeesContainer>
      <AcceptSeparator />
      <AcceptEarnings>Total earnings</AcceptEarnings>
      <AcceptInputContainer>
        <AcceptInput value={earnings} disabled />
        <AcceptInputSkewLeft />
        <AcceptInputRight>MYTV</AcceptInputRight>
        <AcceptInputSkewRight />
      </AcceptInputContainer>
      <AcceptButtonContainer>
        <Button appearance={'primary'}>ACCEPT OFFER</Button>
      </AcceptButtonContainer>
    </AcceptStyled>
  )
}
