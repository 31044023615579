// prettier-ignore
import * as React from 'react'
import { PackView } from './Pack.view'
import { State } from '../../reducers/index'
import { useDispatch, useSelector } from 'react-redux'
import { showModal } from 'app/App.components/Modal/Modal.actions'

export const Pack = () => {
  const dispatch = useDispatch()
  let showModalCb = (title: string, children: JSX.Element) => {
    dispatch(showModal(title, children))
  }

  const version = useSelector((state: State) => state.version.number)
  return <PackView version={version} showModal={showModalCb} />
}
