import { Bar } from './LoadingBar.style'

interface LoadingBarProps {
  loadingPercent: number
}

export const LoadingBar = ({ loadingPercent }: LoadingBarProps) => {
  return (
    <Bar>
      <Bar loadingPercent={loadingPercent} />
    </Bar>
  )
}
