import { APPLICATION_ERROR, enqueueError, SMARTCONTRACT_ERROR } from 'actions/Errors.actions'
import TokenSupportEntity, { PhaseStatus } from 'Entities/Marketplace/TokenSupportEntity'
import { BigNumber } from 'ethers'
import { store } from 'index'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { MaxScreenWidth } from 'styles'
import * as Webservices from 'Webservices'
import {
  BigPurpleText,
  BuyNftContainer,
  BuyNftLink,
  CollectionStyled,
  Elm,
  FanSeriesTitle,
  GrayImage,
  GuideTitle,
  HowToBuyContainer,
  HowToBuyNftLink,
  HowToBuyNftsContainer,
  LinearGradientBox,
  Neon,
  NeonMobile,
  NewComerGuidContainer,
  PaySecuredby,
  PremiumSeriesTitle,
  PreviewImageMobile,
  RowInfoContainer,
  SeriesContainer,
  SeriesContainerBackground,
  Shadow,
  VideoBackground,
  VideoContainer,
  VideoPreviewContainerMobile,
  VioletText,
  WaitListContainer,
  WaitListContainerMobile,
  YellowImage,
} from './Collection.style'
import { CollectionStory } from './CollectionStory/CollectionStory.view'
import { InfoComponent } from './InfoComponent/InfoComponent.view'
import { PackComponent } from './PackComponent/PackComponent.view'

interface CollectionViewProps {
  version: number
  tokenSupports: TokenSupportEntity[]
}

interface SerieContainerComponentProps {
  tokenSupport: TokenSupportEntity
  index: number
}

export const CollectionView = ({ version, tokenSupports }: CollectionViewProps) => {
  const defaultMetadata = tokenSupports?.[0]?.metadata?.front
  let myRef = useRef<HTMLDivElement>(null)
  const executeScroll = () => {
    if (myRef.current != null) {
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop - 140 })
    }
  }
  return (
    <CollectionStyled>
      <VideoContainer>
        <VideoBackground>
          <ReactPlayer
            url={'/videos/' + defaultMetadata?.banner?.video}
            playing
            playsinline
            muted
            loop
            width="100%"
            height="100%"
            style={{ bottom: '100px', position: 'relative' }}
          />
          <LinearGradientBox></LinearGradientBox>
          <WaitListContainer>
            <Shadow>
              <BigPurpleText>PRIVATE SALE IS CLOSED</BigPurpleText>
              {/*<Box >join the waitlist now!</Box> */}
            </Shadow>
            {/* <ButtonGoTo margin={true} appearance="primary" disabled={false} onClick={executeScroll}>
              <ButtonGoToInside>BUY NOW</ButtonGoToInside>
            </ButtonGoTo> */}
          </WaitListContainer>
        </VideoBackground>
      </VideoContainer>
      <VideoPreviewContainerMobile>
        {/* <ReactPlayer
          url={'/videos/' + defaultMetadata?.banner?.video}
          playing
          playsinline
          muted
          loop
          width="100%"
          height="100%"
          style={{ position: 'relative' }}
        /> */}
        <PreviewImageMobile src="/images/collection/preview_video_mobile.png" alt="" />
        <LinearGradientBox></LinearGradientBox>
      </VideoPreviewContainerMobile>
      <WaitListContainerMobile>
        <BigPurpleText>PRIVATE SALE IS CLOSED</BigPurpleText>
        {/* <ButtonGoTo margin={true} appearance="primary" disabled={false} onClick={executeScroll}>
          <ButtonGoToInside>BUY NOW</ButtonGoToInside>
        </ButtonGoTo> */}
      </WaitListContainerMobile>
      <HowToBuyNftsContainer>
        <MaxScreenWidth style={{ marginTop: '90px' }}>
          <NewComerGuidContainer>
            <HowToBuyContainer>
              <GuideTitle>HOW DO I BUY NFTs IN CRYPTO? </GuideTitle>
              <HowToBuyNftLink
                href="https://docs.mytvchain.io/mytvchain-marketplace/primary-market/buy-your-first-nft-s.-loeb"
                target="_blank"
                rel="noreferrer"
              >
                a&nbsp;<VioletText>crypto</VioletText>&nbsp;step by step guide{' '}
                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.08579 15.5858L0.671573 17L3.5 19.8284L4.91421 18.4142L2.08579 15.5858ZM10.5 10L11.9142 11.4142C12.2893 11.0391 12.5 10.5304 12.5 10C12.5 9.46957 12.2893 8.96086 11.9142 8.58579L10.5 10ZM4.91421 1.58579L3.5 0.171572L0.671572 3L2.08579 4.41421L4.91421 1.58579ZM4.91421 18.4142L11.9142 11.4142L9.08579 8.58579L2.08579 15.5858L4.91421 18.4142ZM11.9142 8.58579L4.91421 1.58579L2.08579 4.41421L9.08579 11.4142L11.9142 8.58579Z"
                    fill="#F8FAFC"
                  />
                </svg>
              </HowToBuyNftLink>
            </HowToBuyContainer>
            <Neon src="/images/collection/piece_neon.png" />
            <NeonMobile src="/images/collection/how_to_buy_mobile.png" />
            <BuyNftContainer>
              <BuyNftLink href="https://pay.mytvchain.io" target="_blank" rel="noreferrer">
                Click here to buy NFTs in €/$
                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.08579 15.5858L0.671573 17L3.5 19.8284L4.91421 18.4142L2.08579 15.5858ZM10.5 10L11.9142 11.4142C12.2893 11.0391 12.5 10.5304 12.5 10C12.5 9.46957 12.2893 8.96086 11.9142 8.58579L10.5 10ZM4.91421 1.58579L3.5 0.171572L0.671572 3L2.08579 4.41421L4.91421 1.58579ZM4.91421 18.4142L11.9142 11.4142L9.08579 8.58579L2.08579 15.5858L4.91421 18.4142ZM11.9142 8.58579L4.91421 1.58579L2.08579 4.41421L9.08579 11.4142L11.9142 8.58579Z"
                    fill="#F8FAFC"
                  />
                </svg>
              </BuyNftLink>
              <PaySecuredby src="/images/collection/sloeb_securedby.png" />
            </BuyNftContainer>
          </NewComerGuidContainer>
        </MaxScreenWidth>
      </HowToBuyNftsContainer>
      <Elm ref={myRef}></Elm>
      {tokenSupports.map((tokenSupport, i) => (
        <SerieContainerComponent tokenSupport={tokenSupport} index={i} key={i} />
      ))}
      <CollectionStory />
    </CollectionStyled>
  )
}

const SerieContainerComponent: React.FC<SerieContainerComponentProps> = ({ tokenSupport, index }) => {
  const metadata = tokenSupport?.metadata?.front
  const dispatch = useDispatch()
  const wallet = useSelector((state: State) => state.wallet)
  const [price, setPrice] = useState<BigNumber | null>(null)
  const [tokensSoldPublic, setSoldPublic] = useState<BigNumber | null>(null)
  const [tokensSoldPrivate, setSoldPrivate] = useState<BigNumber | null>(null)
  const [privateSaleHardCap, setPrivateSaleHarCap] = useState<BigNumber | null>(null)
  const [maxMintablePerTx, setMaxMintablePerTx] = useState<BigNumber | null>(null)
  const [maxTotalSupply, setMaxTotalSupply] = useState<BigNumber | null>(null)
  const [totalSupply, setTotalSupply] = useState<BigNumber | null>(null)
  const [userPrivateSaleHardcap, setUserPrivateSaleHardcap] = useState<BigNumber>(BigNumber.from(0))
  const [publicActive, setPublicActive] = useState<boolean | null>(false)
  const [privateActive, setPrivateActive] = useState<boolean | null>(false)
  const [privateSalePhaseId, setPrivateSalePhaseId] = useState<number>(0)
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const abi = tokenSupport?.metadata?.abi
    if (!abi) {
      dispatch(
        enqueueError({
          type: APPLICATION_ERROR,
          payload: { message: `Missing ABI for token ${tokenSupport.address}`, errors: tokenSupport },
        }),
      )
      return
    }
    const publicActive = await SmartContractApi.getPublicSaleStatus(tokenSupport.address, abi)
    setPublicActive(publicActive)
    const privateActive = await SmartContractApi.getPrivateSaleStatus(tokenSupport.address, abi)
    setPrivateActive(privateActive)

    if (publicActive) {
      setPrice(await SmartContractApi.getPublicSalePrice(tokenSupport.address, abi))
      setMaxMintablePerTx(await SmartContractApi.getMaxPublicSaleMintPerTx(tokenSupport.address, abi))
      setIsWhitelisted(true)
    }

    if (privateActive) {
      const phaseId = await SmartContractApi.getPrivateSalePhaseId(tokenSupport.address, abi)
      const privateSaleHardCap = await SmartContractApi.getPrivateSaleHardcap(tokenSupport.address, abi)
      setPrice(await SmartContractApi.getPrivateSalePrice(tokenSupport.address, abi))
      setPrivateSaleHarCap(privateSaleHardCap)
      setMaxMintablePerTx(await SmartContractApi.getMaxPrivateSaleMintPerTx(tokenSupport.address, abi))

      if (tokenSupport.slug && wallet.address && phaseId !== null) {
        setPrivateSalePhaseId(phaseId)
        setIsWhitelisted(
          await Webservices.Whitelist.isWhitelisted({
            slug: tokenSupport.slug,
            phaseId: phaseId,
            userAddress: wallet.address,
          }),
        )
        setUserPrivateSaleHardcap(
          privateSaleHardCap?.sub(
            await SmartContractApi.getUserPrivateSaleMintedAmountByPhaseId(
              tokenSupport.address,
              wallet.address,
              phaseId,
              abi,
            ),
          ) ?? BigNumber.from(0),
        )
      }
    }

    if (!privateActive && !publicActive) {
      switch (metadata?.nextPhase) {
        case PhaseStatus.PRIVATE_SALE_1:
        case PhaseStatus.PRIVATE_SALE_2:
          setPrice(await SmartContractApi.getPrivateSalePrice(tokenSupport.address, abi))
          setPrivateSaleHarCap(await SmartContractApi.getPrivateSaleHardcap(tokenSupport.address, abi))
          break
        case PhaseStatus.PUBLIC_SALE:
        default:
          setPrice(await SmartContractApi.getPublicSalePrice(tokenSupport.address, abi))
          break
      }
    }

    const tokensSoldPublic = await SmartContractApi.getTotalSoldInPublicSale(tokenSupport.address, abi)
    const tokensSoldPrivate = await SmartContractApi.getTotalSoldInPrivateSale(tokenSupport.address, abi)
    setTotalSupply(await SmartContractApi.getTotalSupply(tokenSupport.address, abi))
    setSoldPublic(tokensSoldPublic)
    setSoldPrivate(tokensSoldPrivate)
    setMaxTotalSupply((await SmartContractApi.getMaxTotalSupply(tokenSupport.address, abi)) ?? null)
  }, [dispatch, metadata?.nextPhase, tokenSupport, wallet.address])

  const mintPublic = useCallback(
    async (numberOfNftToMint: BigNumber) => {
      const abi = tokenSupport?.metadata?.abi
      if (!abi) {
        dispatch(
          enqueueError({
            type: APPLICATION_ERROR,
            payload: { message: `Missing ABI for token ${tokenSupport.address}`, errors: tokenSupport },
          }),
        )
        return
      }
      if (!price) return

      const tx = await SmartContractApi.mintPublicSale(tokenSupport.address, abi, numberOfNftToMint, price!)
      if (!tx) return
      await tx.wait()
      await Webservices.Erc721.Tokens.synchronize(null, { tokenAddress: tokenSupport.address })
      fetchData()
    },
    [dispatch, fetchData, price, tokenSupport],
  )

  const mintPrivate = useCallback(
    async (numberOfNftToMint: BigNumber) => {
      const abi = tokenSupport?.metadata?.abi
      if (!abi) {
        dispatch(
          enqueueError({
            type: APPLICATION_ERROR,
            payload: { message: `Missing ABI for token ${tokenSupport.address}`, errors: tokenSupport },
          }),
        )
        return
      }
      if (!price || !tokenSupport.slug || !wallet.address || !wallet.balance) return
      if (price.mul(numberOfNftToMint).gte(wallet.balance!)) {
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: 'Insufficient balance' },
          }),
        )
        return
      }
      const mintEntity = await Webservices.Whitelist.signMessage(tokenSupport.slug!, {
        address: wallet.address!,
        phaseId: privateSalePhaseId,
      })
      const { signature } = mintEntity
      const tx = await SmartContractApi.mintPrivateSale(tokenSupport.address, abi, signature, numberOfNftToMint, price!)
      if (!tx) return
      await tx.wait()
      await Webservices.Erc721.Tokens.synchronize(null, { tokenAddress: tokenSupport.address })
      fetchData()
    },
    [dispatch, fetchData, price, privateSalePhaseId, tokenSupport, wallet.address, wallet.balance],
  )

  useEffect(() => {
    fetchData()
  }, [dispatch, fetchData])

  return (
    <SeriesContainer>
      <SeriesContainerBackground
        // background={index % 2 === 0 ? 'rgba(239, 203, 74, 0.3)' : 'rgba(196, 196, 196, 0.4)'}
        background={'rgba(196, 196, 196, 0.4)'}
      />
      <MaxScreenWidth>
        {/* {index % 2 === 0 ? (
          <>
            <YellowImage src="/images/yellow-paint.svg" />
            <PremiumSeriesTitle>{metadata?.serieName}</PremiumSeriesTitle>
          </>
        ) : (
          <>
            <GrayImage src="/images/gray-paint.svg" />
            <FanSeriesTitle>{metadata?.serieName}</FanSeriesTitle>
          </>
        )} */}
        <>
          <GrayImage src="/images/gray-paint.svg" />
          <FanSeriesTitle>{metadata?.serieName}</FanSeriesTitle>
        </>
        <RowInfoContainer>
          <InfoComponent
            tokenSupport={tokenSupport}
            tokensSoldPublic={tokensSoldPublic}
            tokensSoldPrivate={tokensSoldPrivate}
            privateSaleHardCap={privateSaleHardCap}
            maxTotalSupply={maxTotalSupply}
            totalSupply={totalSupply}
            publicActive={publicActive}
            privateActive={privateActive}
            privateSalePhaseId={privateSalePhaseId}
          />
          <PackComponent
            tokensSoldPrivate={tokensSoldPrivate}
            publicActive={publicActive}
            privateActive={privateActive}
            tokenSupport={tokenSupport}
            videoName={metadata?.pack?.video}
            price={price}
            privateSaleHardCap={privateSaleHardCap}
            maxMintablePerTx={maxMintablePerTx}
            mintPublic={mintPublic}
            mintPrivate={mintPrivate}
            userPrivateSaleHardcap={userPrivateSaleHardcap}
            isWhitelisted={isWhitelisted}
          />
        </RowInfoContainer>
      </MaxScreenWidth>
    </SeriesContainer>
  )
}
