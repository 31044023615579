// prettier-ignore
import { updateModal } from 'app/App.components/Modal/Modal.actions'
import { TransactionModal } from 'app/App.components/TransactionModal/TransactionModal.controller'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { ethers } from 'ethers'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { isNativeToken } from 'utils'
import { IParamsPost as OrderParam } from 'Webservices/Marketplace/Orders'
import { SaleView } from './Sale.view'

interface SaleProps {
  owner: string
  collection: string
  item: NftEntity
  callBack?: () => void
}

export const Sale = ({ owner, collection, item, callBack }: SaleProps) => {
  const dispatch = useDispatch()

  const wallet = useSelector((state: State) => state.wallet)
  const orderSellToken = React.useCallback(
    async (price: string, token: TokenSupportEntity, expirationTimeDays: number) => {
      if (wallet.isConnected) {
        dispatch(updateModal('Transaction running', <TransactionModal finished={false} />))
        const expirationTime = new Date()
        expirationTime.setDate(expirationTime.getDate() + expirationTimeDays)
        const order: OrderParam = {
          sellerAddress: wallet.address!,
          sellTokenAddress: item.tokenAddress,
          sellTokenId: item.tokenId,
          sellTokenAmount: '1',
          askTokenAddress: token.address,
          askTokenId: 0,
          askTokenAmount: ethers.utils.parseUnits(price, token.decimals!).toString(),
          startTime: 604411200,
          expirationTime: Math.floor(expirationTime.getTime() / 1000),
          salt: 0,
          sellAssetClass: AssetClass.ERC721,
          askAssetClass: isNativeToken(token.address) ? AssetClass.ETH : AssetClass.ERC20,
          orderHash: '',
          orderSignature: '',
        }
        const response = await SmartContractApi.createOrder(order)
        if (response) {
          if (callBack) callBack()
          dispatch(updateModal('Transaction finished', <TransactionModal finished />))
        } else {
          dispatch(updateModal('Transaction failed', <TransactionModal finished failed />))
        }
      }
    },
    [wallet, item, dispatch, callBack],
  )

  return <SaleView orderSellToken={orderSellToken} />
}
