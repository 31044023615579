import styled from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const ProfileStyled = styled(Page)`
  padding: 80px 0 0 0 !important;
`

export const ProfileContainer = styled(Page)<{ marginTop?: number; marginBottom?: number }>`
  position: relative;
  width: 100%;
  text-align: center;
  max-width: 1440px;
  text-align: center;
  padding: 0 100px;
  min-height: unset;

  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px` : '')};
  ${(props) => (props.marginBottom ? `margin-bottom: ${props.marginBottom}px` : '')};
  @media (max-width: 1023px) {
    padding: 0 32px;
  }
`

export const ProfileFirstLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
`
export const ProfilePicture = styled.div`
  width: 96px;
  height: 96px;
  background: linear-gradient(147.74deg, #483bc6 5.54%, #9542ff 61.23%, #56c3f1 93.95%);
  border-radius: 50px;

  @media (max-width: 1023px) {
    width: 64px;
    height: 64px;
  }
`

export const ProfileAddress = styled.p`
  margin: 0;
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;

  color: #f8fafc;
  margin-left: 40px;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    margin-left: 16px;
  }
`

export const ProfileClipboardAddress = styled.div`
  width: 36px;
  height: 36px;
  margin-left: 48px;
  cursor: pointer;

  > svg {
    width: 36px;
    height: 36px;
  }

  @media (max-width: 1023px) {
    margin-left: 24px;
  }
`

export const ProfileBlueBar = styled.div`
  margin-left: 32px;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const ProfileSecondLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
`

export const ProfileResultsNumber = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #f8fafc;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const ProfileFilterSelectContainer = styled.div`
  position: relative;
  width: fit-content;

  @media (max-width: 1023px) {
    width: 50%;
  }
`

export const ProfileFilterSelect = styled.select`
  background: #232327;
  border-radius: 5px;
  padding: 12px 58px 12px 24px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: rgba(248, 250, 252, 0.5);
  border: none;
  margin-left: 24px;

  &:disabled {
    opacity: 1;
  }

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  @media (max-width: 1023px) {
    width: calc(100% - 8px);
    margin-left: 0px;
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
`

export const ProfileSeparator = styled.div`
  height: 1px;
  background: #232327;
  width: 100%;
  margin-top: 24px;
`

export const ProfileCardsContainer = styled.div`
  > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px;

    @media (max-width: ${Breakpoints.xl}) {
      grid-gap: 30px;
    }

    @media (max-width: ${Breakpoints.md}) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 48px;
    }

    @media (max-width: ${Breakpoints.sm}) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
  }
`
export const ProfileNoNftsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
`
export const ProfileNoNftsButtonContainer = styled.div`
  width: 220px;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 32px;
`
export const ProfileNoNfts = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;
`
