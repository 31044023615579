import { ReactNode, useState } from 'react'
import { Image } from 'styles'

import {
  SliderArrowWrapper,
  SliderCard,
  SliderContainer,
  SliderContentContainer,
  SliderContentWrapper,
  SliderRadioButton,
  SliderRadioButtonsContainer,
  SliderWrapper,
} from './Slider.style'

interface SliderProps {
  children?: ReactNode
}

export const Slider = ({ children }: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const length = 3
  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1)
    } else {
      setCurrentIndex(0)
    }
  }

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1)
    } else {
      setCurrentIndex(length - 1)
    }
  }

  return (
    <SliderContainer>
      <SliderWrapper>
        <SliderArrowWrapper onClick={prev} show={true}>
          <Image alt="Chevron Left" src="/images/chevron-left.svg" />
        </SliderArrowWrapper>
        <SliderContentContainer>
          <SliderContentWrapper currentIndex={currentIndex}>
            <SliderCard alt="Card blurred" src="/images/blurred_card_1.svg" selected={currentIndex === 0} />
            <SliderCard alt="Card blurred" src="/images/blurred_card_2.svg" selected={currentIndex === 1} />
            <SliderCard alt="Card blurred" src="/images/blurred_card_3.svg" selected={currentIndex === 2} />
          </SliderContentWrapper>
        </SliderContentContainer>
        <SliderArrowWrapper onClick={next} show={true}>
          <Image alt="Chevron Right" src="/images/chevron-right.svg" />
        </SliderArrowWrapper>
      </SliderWrapper>
      <SliderRadioButtonsContainer>
        <SliderRadioButton selected={currentIndex === 0} onClick={() => setCurrentIndex(0)} />
        <SliderRadioButton selected={currentIndex === 1} onClick={() => setCurrentIndex(1)} />
        <SliderRadioButton selected={currentIndex === 2} onClick={() => setCurrentIndex(2)} />
      </SliderRadioButtonsContainer>
    </SliderContainer>
  )
}
