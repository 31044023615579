import { APPLICATION_ERROR, enqueueError } from 'actions/Errors.actions'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import { getIpfsLink, isUrl } from 'utils'
import * as Webservices from 'Webservices'
import { MarketplaceCardView } from './MarketplaceCard.view'

type MarketplaceCardProps = {
  erc721Prop: NftEntity
  grayed?: boolean
  saleOnHover: boolean
}

export const MarketplaceCard = ({ erc721Prop, grayed = false, saleOnHover }: MarketplaceCardProps) => {
  const [image, setImage] = useState('/images/marketplace/cards/loading.png')
  const [loading, setLoading] = useState(false)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const [buyToken, setBuyToken] = useState<TokenSupportEntity | null>(null)
  const [price, setPrice] = useState<string | undefined>()
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const [erc721, setErc721] = useState<NftEntity>(erc721Prop)
  const dispatch = useDispatch()
  let priceUsd = 100

  const getErc721 = React.useCallback(async () => {
    // Getting a single card
    const responseData = await Webservices.Erc721.Tokens.get(null, {
      q: { tokenAddress: erc721Prop.tokenAddress, tokenId: ~~erc721Prop.tokenId },
    })
    if (!responseData || !responseData[0]) return

    // If the card exists
    // Setting the card infos
    setErc721(responseData[0])
    const tokenInfos = responseData[0]

    // Getting the IPFS infos of the card
    // const ipfsData = await Webservices.Ipfs.get(null, { tokenUri: tokenInfos.tokenUri })
    // if (!ipfsData) return

    // If the image is a URL, setting the URL else format with the IPFS gateway
    if (!tokenInfos.docUri) return
    if (isUrl(tokenInfos.docUri)) {
      setImage(tokenInfos.docUri)
    } else {
      setImage(getIpfsLink(tokenInfos.docUri))
    }
  }, [erc721Prop])

  const getIpfsInfos = React.useCallback(async () => {
    setLoading(false)
    if (erc721.tokenUri) {
      try {
        if (!erc721.docUri) return
        if (isUrl(erc721.docUri)) {
          setImage(erc721.docUri)
        } else {
          setImage(getIpfsLink(erc721.docUri))
        }
      } catch (error) {
        console.error(error)
      }
    }
    setLoading(false)
  }, [erc721])

  useEffect(() => {
    getIpfsInfos()
  }, [getIpfsInfos])

  useEffect(() => {
    if (!erc721.sellOrder?.askTokenAmount) return
    const order = erc721.sellOrder
    if (order.askAssetClass === AssetClass.ETH) {
      setBuyToken(nativeToken)
      return
    }
    const supportedToken = supportedTokens.filter(
      (token) => token.protocol === EProtocol.ERC20 && token.address === erc721.sellOrder?.askTokenAddress,
    )
    if (!supportedToken.length) {
      dispatch(
        enqueueError({
          type: APPLICATION_ERROR,
          payload: { message: `Unsupported token erc20 for order ${erc721.sellOrder._id}` },
        }),
      )
    }
    setBuyToken(supportedToken[0])
  }, [erc721, supportedTokens, nativeToken, dispatch])

  const event = React.useMemo(() => {
    if (!erc721.tokenSupport?.metadata?.event) return
    return erc721.tokenSupport?.metadata?.event
  }, [erc721])

  useEffect(() => {
    if (erc721.sellOrder?.askTokenAmount) setPrice(erc721.sellOrder.askTokenAmount)
  }, [erc721])

  return (
    <>
      {erc721 && (
        <MarketplaceCardView
          image={image}
          event={event}
          priceUsd={priceUsd}
          grayed={grayed}
          erc721={erc721}
          detailsOnHover={false}
          loading={loading}
          saleOnHover={saleOnHover}
          buyToken={buyToken}
          price={price}
          callBack={getErc721}
        />
      )}
    </>
  )
}
