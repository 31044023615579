import * as PropTypes from 'prop-types'

import { ArrowStyled } from './Arrow.style'

type ArrowProps = {
  type: 'up' | 'down'
}

export const Arrow = ({ type }: ArrowProps) => {
  return (
    <ArrowStyled>
      <svg>
        <use xlinkHref={`/images/sprites.svg#arrow-${type}`} />
      </svg>
    </ArrowStyled>
  )
}

Arrow.propTypes = {
  type: PropTypes.string,
}

Arrow.defaultProps = {
  type: 'up',
}