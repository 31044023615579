import { configureStore } from 'app/App.store'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import * as Sentry from '@sentry/browser'
// import ReactGA from 'react-ga'
import { App } from './app/App.controller'
import reportWebVitals from './reportWebVitals'
import { unregister } from './serviceWorker'
import { GlobalStyle } from './styles'
import './styles/fonts.css'

// Sentry.init({ dsn: 'XXX' })
// ReactGA.initialize('XXX')
export const store = configureStore({})

export const Root = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider>
        <GlobalStyle />
        <App />
      </SnackbarProvider>
    </Provider>
  )
}

ReactDOM.render(<Root />, document.body)

unregister()
reportWebVitals()
