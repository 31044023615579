import { TokenSupportEntity } from 'Entities'
import { IMyTvTokenAction, SET_TOKEN } from '../actions/MyTvToken.actions'

export type MyTvTokenState = Partial<TokenSupportEntity>

export function myTvToken(state: MyTvTokenState = {}, action: IMyTvTokenAction): MyTvTokenState {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...action.token,
      }
    default:
      return state
  }
}
