import { setMyTvToken } from 'actions/MyTvToken.actions'
import { setNativeToken } from 'actions/NativeToken.actions'
import { setSupportedTokens } from 'actions/SupportedTokens.actions'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'

export function SupportedTokensHandler() {
  const dispatch = useDispatch()
  const supportedTokens = useSelector((state: State) => state.supportedTokens)

  const getTokens = useCallback(async () => {
    dispatch(await setSupportedTokens())
    dispatch(await setNativeToken())
  }, [dispatch])

  useEffect(() => {
    getTokens()
  }, [getTokens])

  useEffect(() => {
    if (!supportedTokens.length) return
    dispatch(setMyTvToken(supportedTokens))
  }, [supportedTokens, dispatch])

  return null
}
