import { Button } from 'app/App.components/Button/Button.view'
import { TokenSupportEntity } from 'Entities'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { ethers } from 'ethers'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { State } from 'reducers'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { formatAddress } from 'utils'
import { IPairOrderNftEntity } from '../Activities.controller'
import { ActivityAction, ActivityType } from '../Activities.enum'
import {
  TableLineDescriptive,
  TableLineImg,
  TableLineLogo,
  TableLineSeparator,
  TableLineStyled,
  TableLineTxt,
} from './TableLine.style'

interface TableLineViewProps {
  activity: IPairOrderNftEntity
  type: ActivityType
  state?: ActivityAction
  deadline?: string
  loading: boolean
  buyToken?: TokenSupportEntity
  image: string
  deleteLine: (activity: IPairOrderNftEntity) => void
}

export const TableLineView = ({ activity, state, type, deadline, image, buyToken, deleteLine }: TableLineViewProps) => {
  let button
  const history = useHistory()
  const wallet = useSelector((state: State) => state.wallet)

  const day = useMemo(() => {
    const date = new Date(activity.order.createdAt!)
    return date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  }, [activity])
  const month = useMemo(() => {
    const date = new Date(activity.order.createdAt!)
    return date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }, [activity])
  const year = useMemo(() => {
    const date = new Date(activity.order.createdAt!)
    return date.getFullYear() % 1000
  }, [activity])

  switch (state) {
    case ActivityAction.BID:
      button = (
        <Button appearance={'primary_empty'} width={type === ActivityType.AUCTIONS ? '100%' : '175px'}>
          BID
        </Button>
      )
      break

    case ActivityAction.ACCEPT:
      button = (
        <Button
          appearance={'primary_empty'}
          width={type === ActivityType.AUCTIONS ? '100%' : '175px'}
          clickCallback={async () => {
            const order = activity.token.askOrders![0]
            order.buyerAddress = wallet.address
            const tx = await SmartContractApi.acceptOrder(order)
            if (!tx) return
            deleteLine(activity)
          }}
        >
          ACCEPT
        </Button>
      )
      break
    case ActivityAction.CANCEL:
      button = (
        <Button
          appearance={'orange_empty'}
          width={type === ActivityType.AUCTIONS ? '100%' : '175px'}
          clickCallback={async () => {
            const order = activity.order
            order.buyerAddress = wallet.address
            const tx = await SmartContractApi.cancelOrder(order)
            if (!tx) return
            await tx.wait()
            deleteLine(activity)
          }}
        >
          CANCEL
        </Button>
      )
      break
    case ActivityAction.VIEW:
      button = (
        <Button
          appearance={'gray_empty'}
          width={type === ActivityType.AUCTIONS ? '100%' : '175px'}
          clickCallback={() => history.push(`/assets/${activity.token.tokenAddress}/${activity.token.tokenId}`)}
        >
          VIEW
        </Button>
      )
      break
    case ActivityAction.FINALIZE:
      button = (
        <Button appearance={'primary_empty'} width={type === ActivityType.AUCTIONS ? '100%' : '175px'}>
          FINALIZE
        </Button>
      )
      break
    default:
  }

  return (
    <TableLineStyled>
      <TableLineImg
        src={image}
        alt={activity.token.name}
        onClick={() => history.push(`/assets/${activity.token.tokenAddress}/${activity.token.tokenId}`)}
      />
      <TableLineDescriptive>{type === ActivityType.AUCTIONS ? 'Latest bid price' : 'Price'}</TableLineDescriptive>
      <TableLineTxt>
        {ethers.utils.formatUnits(
          activity.order.sellAssetClass === AssetClass.ERC721
            ? activity.order.askTokenAmount
            : activity.order.sellTokenAmount,
          buyToken?.decimals,
        )}{' '}
        {buyToken?.symbol}
      </TableLineTxt>
      <TableLineDescriptive>{type === ActivityType.AUCTIONS ? 'Time left' : 'Date'}</TableLineDescriptive>
      {/* <TableLineTxt>
        {type === ActivityType.AUCTIONS ? <Timer deadline={deadline} /> : `${day}/${month}/${year}`}
      </TableLineTxt> */}
      <TableLineTxt>{`${month}/${day}/${year}`}</TableLineTxt>
      <TableLineDescriptive>Name</TableLineDescriptive>
      <TableLineTxt>{activity.token.name}</TableLineTxt>
      <TableLineDescriptive>Token ID</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(activity.token.tokenId.toString())
        }}
      >
        {activity.token.tokenId}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive>Contract address</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(activity.token.tokenAddress)
        }}
      >
        {formatAddress(activity.token.tokenAddress)}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive gridColumn={'1'}>{type === ActivityType.AUCTIONS ? 'State' : ''}</TableLineDescriptive>
      <TableLineTxt type={type}>{button}</TableLineTxt>
      <TableLineSeparator className="separator" />
    </TableLineStyled>
  )
}
