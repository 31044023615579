export enum ActivityType {
  AUCTIONS = 0,
  OFFERS_MADE = 1,
  OFFERS_RECEIVED = 2,
}

export enum ActivityAction {
  BID = 0,
  VIEW = 1,
  FINALIZE = 2,
  ACCEPT = 3,
  CANCEL = 4,
}
