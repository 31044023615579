import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(12, 12, 12, 0.82);
  border: 1px solid #61616b;
  backdrop-filter: blur(44px);
  width: 600px;
  height: 633px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  padding: 32px;
  /* justify-content: space-around; */
  @media (max-width: ${Breakpoints.md}) {
    margin: 0;
    padding: 24px;
    width: auto;
    height: auto;
  }
`

export const PhasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;

  @media (max-width: ${Breakpoints.md}) {
    gap: 16px;
  }
`

export const PhaseTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 4px 0;
  }
`

export const AmountSold = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 8px 0 0 0;
  text-align: center;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 12px;
    line-height: 16px;
  }
`

export const InfoContainerMobile = styled.div`
  background: rgba(12, 12, 12, 0.82);
  border-top: 1px solid #61616b;
  border-bottom: 1px solid #61616b;
  backdrop-filter: blur(44px);

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const SinglePhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const LoadingBarContainer = styled.div`
  bottom: 32px;
  position: absolute;
  width: calc(100% - 64px);

  @media (max-width: ${Breakpoints.md}) {
    position: static;
    width: auto;
    bottom: unset;
  }
`
export const SubInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 32px 0;
`
