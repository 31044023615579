import { BigPurpleText } from 'pages/Collection/Collection.style'

import {
  CelebrateContainer,
  CelebrateText,
  CelebrateTitle,
  DropdownStoryStyled,
  EmotionContainer,
  EmotionImg,
  EmotionText,
  LegendContainer,
  LegendStripesLeft,
  LegendStripesRight,
  LegendText,
  LegendTitle,
  OnceContainer,
  OnceImg,
  OnceImgMobile,
  OnceLeft,
  OnceLeftA,
  OnceLine,
  OnceLineSecond,
  OnceRight,
  OnceText,
  OnceTextC,
  OnceTitleDesktop,
  OnceTitleMobile,
  RacingContainer,
  RacingFirstTitle,
  RacingText,
  RacingTitle,
  TheFirstContainer,
  TheFirstSubTitle,
  TheFirstText,
  WantedContainer,
  WantedText,
  WantedTitle,
} from './DropdownStory.style'

export const DropdownStory = () => {
  return (
    <DropdownStoryStyled>
      <TheFirstContainer>
        <BigPurpleText>The first</BigPurpleText>
        <TheFirstSubTitle>Why our collection is special?</TheFirstSubTitle>
        <TheFirstText>Because that’s the first MyTVchain collection.</TheFirstText>
      </TheFirstContainer>
      <CelebrateContainer>
        <CelebrateTitle>We celebrate Sébastien Loeb’s 2004 first title, </CelebrateTitle>
        <CelebrateText>
          the first consecration and the beginning of an amazing journey to write automobile sports history.It will
          always be MYTV's first collection, and it will definitely be the most significant one.
        </CelebrateText>
      </CelebrateContainer>
      <WantedContainer>
        <WantedTitle>Because we wanted to write history just as Sébastien Loeb,</WantedTitle>
        <WantedText>
          we have created a collection that takes you back to a historic moment in sport with authentic videos from his
          first title in 2004, and highlights of all the rallies won by Sébastien Loeb that year. Drift in Sweden, Full
          Speed in Monte Carlo, jump in Chypre, you will relive the emotion of his first crowning.
        </WantedText>
      </WantedContainer>
      <EmotionContainer>
        <EmotionImg src="/images/collection/Loeb_Xsara_Homepage_vente_01_Black_BG.png" alt="Sebastien loeb" />
        <EmotionText>
          The emotion of vintage highlights of the year 2004, combined with the excitement of a 3D top-of-the edge
          generative collection. Our collection is unique in the world.
          <br />
          <br />
          This collection celebrates our passion for sport and our start in the next step of the Sports Ecosystem.
        </EmotionText>
      </EmotionContainer>
      <OnceTitleMobile>Once upon a time…</OnceTitleMobile>
      <OnceContainer>
        <OnceLine>
          <OnceLeftA>
            <OnceTitleDesktop>Once upon a time…</OnceTitleDesktop>
            <OnceTextC>
              For our first collection ever, we have decided to travel back in time to celebrate the year 2004, the
              first World Champion title of Sébastien Loeb.
            </OnceTextC>
          </OnceLeftA>
        </OnceLine>
        <OnceLineSecond>
          <OnceLeft>
            <OnceImg src="/images/photo1_voiture.png" alt="" />
            <OnceImgMobile src="/images/photo1_voiture.png" alt="" />
          </OnceLeft>
          <OnceRight>
            <OnceText>
              In this collection, we tell you the story of a young boy with incredible talent and unfailing
              determination to win and establish a legacy.
            </OnceText>
          </OnceRight>
        </OnceLineSecond>
      </OnceContainer>
      <LegendContainer>
        <LegendStripesLeft src="/images/thatyear_stripes_left.png" alt="" />
        <LegendStripesRight src="/images/thatyear_stripes_right.png" alt="" />
        <LegendTitle>That year, a legend was born.</LegendTitle>
        <LegendText>
          From the chicanes of Monte Carlo to the frozen roads of Sweden, he has won 6 Rallies, 70 stage events, and has
          established himself as one of the greatest drivers of all time.
          <br />
          <br />
          Drifts, full speed, crazy acceleration, unbelievable jumps. We immerse you in the world of rallying, where
          every inch counts, every minute, every second.
        </LegendText>
      </LegendContainer>
      <RacingContainer>
        <RacingFirstTitle>Sébastien Loeb is a legend of racing.</RacingFirstTitle>
        <RacingTitle>He is</RacingTitle>
        <RacingText> fast, precise, and determined</RacingText>
        <RacingTitle>He has</RacingTitle>
        <RacingText>a work ethic like no one before and he doesn’t hesitate to put his life on the line.</RacingText>
        <RacingTitle>He holds</RacingTitle>
        <RacingText>
          several WRC records, including most event wins, most podium finishes and most stage wins. He is the most
          successful driver in WRC history.
        </RacingText>
      </RacingContainer>
    </DropdownStoryStyled>
  )
}
