import styled from 'styled-components/macro'

export const BidYourBid = styled.h3`
  margin: 0;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #9542ff;
  margin-top: 24px;
`
