import { BaseEntity } from './BaseEntity'

export enum OrderStatus {
  Pending = 0,
  Executed = 1,
  Canceled = 2,
}

export enum AssetClass {
  ETH = 0,
  ERC20 = 1,
  ERC1155 = 2,
  ERC721 = 3,
}

class StatusReason {
  property!: string
  messages: string[] = []
}

class Block {
  number!: number
  hash!: string
}

export default class OrderEntity extends BaseEntity {
  public sellerAddress!: string

  public sellTokenAddress!: string

  public sellTokenId!: number

  public sellTokenAmount!: string;

  public askTokenAddress!: string

  public askTokenId!: number

  public askTokenAmount!: string;

  public startTime!: number

  public expirationTime!: number

  public salt!: number

  public sellAssetClass!: AssetClass

  public askAssetClass!: AssetClass

  public orderHash!: string

  public orderSignature!: string

  public orderStatus?: OrderStatus

  public buyerAddress?: string

  public transactionHash?: string

  public statusReasons?: StatusReason[]

  public block?: Block;

}
