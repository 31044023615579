import { AttributeBlockStyled, Title, SubTitle, Percentage } from './AttributeBlock.style'

type AttributeBlockProps = {
  title: string
  subTitle: string
  percentage?: number
}

export const AttributeBlock = ({ title, subTitle, percentage = -1 }: AttributeBlockProps) => {
  return (
    <AttributeBlockStyled>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {percentage !== -1 && <Percentage>{percentage}% have this trait</Percentage>}
    </AttributeBlockStyled>
  )
}
