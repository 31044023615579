import { OrderEntity } from 'Entities'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { Transaction } from 'ethers'
import * as Webservices from 'Webservices'
import { OrderProperties } from 'Webservices/Marketplace/Orders'
import BaseContract from './BaseContract'

interface IOrderPayload {
  sellerAddress: string
  sellTokenAddress: string
  sellTokenId: number
  sellTokenAmount: string
  askTokenAddress: string
  askTokenId: number
  askTokenAmount: string
  startTime: number
  expirationTime: number
  salt: number
  sellAssetClass: AssetClass
  askAssetClass: AssetClass
}

export default class Marketplace extends BaseContract {
  protected protocol: EProtocol = EProtocol.MARKETPLACE
  protected static ctx: Marketplace
  
  constructor() {
    super()
    if (Marketplace.ctx) return Marketplace.ctx
    Marketplace.ctx = this
  }
  protected properties: OrderProperties | null = null

  public async executeOrder(order: OrderEntity, overrides: Partial<Transaction>): Promise<any> {
    const payload = this.convertOrderToPayload(order)
    const signature = order.orderSignature
    const contractWithSigner = await this.getSmartContractWithSigner();
    return await contractWithSigner.executeOrder(payload, signature, overrides)
  }

  public async cancelOrder(order: OrderEntity): Promise<any> {
    const payload = this.convertOrderToPayload(order)
    const contractWithSigner = await this.getSmartContractWithSigner();
    return await contractWithSigner['cancelOrder'](payload)
  }

  private convertOrderToPayload(order: OrderEntity): IOrderPayload {
    return {
      sellerAddress: order.sellerAddress,
      sellTokenAddress: order.sellTokenAddress,
      sellTokenId: order.sellTokenId,
      sellTokenAmount: order.sellTokenAmount,
      askTokenAddress: order.askTokenAddress,
      askTokenId: order.askTokenId,
      askTokenAmount: order.askTokenAmount,
      startTime: order.startTime,
      expirationTime: order.expirationTime,
      sellAssetClass: order.sellAssetClass,
      askAssetClass: order.askAssetClass,
      salt: order.salt,
    }
  }

  public async getOrderProperties() : Promise<OrderProperties> {
    if(this.properties) return this.properties
    this.properties = await Webservices.Orders.properties(null, null)
    return this.properties;
  }
}
