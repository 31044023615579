import { Button } from 'app/App.components/Button/Button.view'
import { MarketplaceCardLoading } from 'app/App.components/Marketplace/MarketplaceCardLoading/MarketplaceCardLoading.view'
import { NftEntity } from 'Entities'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { State } from 'reducers'
import { formatAddress } from 'utils'
import { Filter, Filters, FilterType } from 'utils/Filter'
import { Sort, Sorts, SortType } from 'utils/Sort'
import { Image } from '../../styles'
import {
  ProfileAddress,
  ProfileBlueBar,
  ProfileCardsContainer,
  ProfileClipboardAddress,
  ProfileContainer,
  ProfileFilterSelect,
  ProfileFilterSelectContainer,
  ProfileFirstLine,
  ProfileNoNfts,
  ProfileNoNftsButtonContainer,
  ProfileNoNftsContainer,
  ProfilePicture,
  ProfileResultsNumber,
  ProfileSecondLine,
  ProfileSeparator,
  ProfileStyled,
} from './Profile.style'
import { ProfileCard } from './ProfileCard/ProfileCard.controller'

interface ProfileViewProps {
  version: number
  tokens: NftEntity[]
  filters: Filter[]
  changeFilter: (filter: Filter) => void
  changeSort: (sort: SortType) => void
  initialLoad: boolean
  loadMore: (page: number) => void
  totalItems: number
  sort?: Sort
  loading: boolean
}

export const ProfileView = ({
  version,
  tokens,
  filters,
  changeFilter,
  changeSort,
  initialLoad,
  loadMore,
  totalItems,
  sort,
  loading,
}: ProfileViewProps) => {
  const history = useHistory()
  const wallet = useSelector((state: State) => state.wallet)

  // const tokensOnlyUniq: { [key: number]: boolean } = {}
  // const tokensUniq = tokens.filter((token) => {
  //   if (tokensOnlyUniq[token.tokenId]) return false
  //   tokensOnlyUniq[token.tokenId] = true
  //   return true
  // })

  // tokens = tokensUniq
  return (
    <ProfileStyled>
      <ProfileContainer>
        <ProfileFirstLine>
          <ProfilePicture />
          <ProfileAddress>{formatAddress(wallet.address!)}</ProfileAddress>
          <ProfileClipboardAddress
            onClick={() => {
              navigator.clipboard.writeText(wallet.address!)
            }}
          >
            <svg>
              <use xlinkHref="/images/sprites.svg#clipboard-icon" />
            </svg>
          </ProfileClipboardAddress>
          <ProfileBlueBar>
            <img src="/images/blue-bar.svg" alt="" />
          </ProfileBlueBar>
        </ProfileFirstLine>
        <ProfileSecondLine>
          <ProfileResultsNumber>{totalItems} results</ProfileResultsNumber>
          <ProfileFilterSelectContainer>
            <ProfileFilterSelect
              value={sort?.type}
              onChange={(e) => {
                const sortType = e.target.value as SortType
                changeSort(sortType)
              }}
            >
              {Object.keys(Sorts).map((key, index) => {
                if (key === SortType.SORT_PRICE_HIGH_LOW || key === SortType.SORT_PRICE_LOW_HIGH)
                  return <React.Fragment key={index}></React.Fragment>
                return (
                  <option key={index} value={Sorts[key as SortType].type}>
                    {Sorts[key as SortType].label}
                  </option>
                )
              })}
            </ProfileFilterSelect>
            <Image
              position={'absolute'}
              top={17}
              right={24}
              alt="search icon"
              src={`/images/marketplace/arrow-down.svg`}
            />
          </ProfileFilterSelectContainer>

          <ProfileFilterSelectContainer>
            <ProfileFilterSelect
              defaultValue={filters.length}
              onChange={(e) => {
                changeFilter(Filters[e.target.value as FilterType])
              }}
            >
              {Object.keys(Filters).map((key, index) => {
                if (key === FilterType.PRICE) return <React.Fragment key={index}></React.Fragment>
                return (
                  <option key={index} value={Filters[key as FilterType].type}>
                    {Filters[key as FilterType].label}
                  </option>
                )
              })}
            </ProfileFilterSelect>
            <Image
              position={'absolute'}
              top={17}
              right={24}
              alt="search icon"
              src={`/images/marketplace/arrow-down.svg`}
            />
          </ProfileFilterSelectContainer>
        </ProfileSecondLine>
      </ProfileContainer>
      <ProfileSeparator />
      <ProfileContainer marginTop={40} marginBottom={130}>
        {initialLoad && (
          <ProfileCardsContainer>
            <div>
              {Array.from({ length: 10 }, (_: any, index: number) => (
                <MarketplaceCardLoading key={index} />
              ))}
            </div>
          </ProfileCardsContainer>
        )}
        {!initialLoad && (
          <>
            <ProfileCardsContainer>
              <InfiniteScroll
                pageStart={0}
                loadMore={(e) => {
                  loadMore(e)
                }}
                hasMore={!loading && totalItems > tokens.length}
                loader={<MarketplaceCardLoading key={0} />}
              >
                {tokens.map((item: NftEntity) => {
                  return <ProfileCard erc721Prop={item} key={item.tokenAddress + item.tokenId} />
                })}
              </InfiniteScroll>
            </ProfileCardsContainer>
            {totalItems === 0 && (
              <ProfileNoNftsContainer>
                <ProfileNoNfts>You don't own any NFT... for now</ProfileNoNfts>
                <ProfileNoNftsButtonContainer>
                  <Button
                    appearance="primary"
                    clickCallback={() => {
                      history.push('/marketplace')
                    }}
                  >
                    BUY ONE !
                  </Button>
                </ProfileNoNftsButtonContainer>
              </ProfileNoNftsContainer>
            )}
          </>
        )}
      </ProfileContainer>
    </ProfileStyled>
  )
}
