import { reducers } from 'reducers'

export const API_ERROR = 'API_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const BROWSER_ERROR = 'BROWSER_ERROR'
export const SMARTCONTRACT_ERROR = 'SMARTCONTRACT_ERROR'
export const APPLICATION_ERROR = 'APPLICATION_ERROR'
export type EventTypes =
  | typeof API_ERROR
  | typeof VALIDATION_ERROR
  | typeof BROWSER_ERROR
  | typeof SMARTCONTRACT_ERROR
  | typeof APPLICATION_ERROR

export interface ErrorPayload {
  type: EventTypes
  payload: {
    message: string
    errors?: any
  }
}

export type RootState = ReturnType<typeof reducers>

export const enqueueError =
  (error: ErrorPayload): any =>
  async (_: any, getState: any) => {
    const { eventBus } = getState().error

    eventBus.next(error)
  }
