import styled from 'styled-components/macro'
import { Image, Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'
import { ButtonStyled } from 'app/App.components/Button/Button.style'

export const CollectionStyled = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 80px 0 0 0;
`

export const VideoContainer = styled.div`
  position: relative;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const VideoBackground = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 0;

  overflow: hidden;
`

export const LinearGradientBox = styled.div`
  position: absolute;
  background: linear-gradient(0deg, #101010 20.17%, rgba(16, 16, 16, 0) 100%);
  height: 36%;
  width: 100%;
  bottom: 100px;

  @media (max-width: ${Breakpoints.md}) {
    bottom: 0;
  }
`

export const WaitListContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;

  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;

  text-align: center;
  text-transform: uppercase;
  align-items: center;
  bottom: 0;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    bottom: 270px;
  }
`

export const BigPurpleText = styled.div`
  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;

  background: -webkit-linear-gradient(to right, #9542ff 0%, #3ad8ed 100%);
  background: -moz-linear-gradient(to right, #9542ff 0%, #3ad8ed 100%);
  background: linear-gradient(to right, #9542ff 0%, #3ad8ed 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  width: fit-content;
  margin: auto;
`

export const GuideTitle = styled.p`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-transform: normal;
  color: #ffffff;
  margin-top: -10px;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 20px;
  }
`

export const HowToBuyNftLink = styled.a`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-decoration-line: underline;
  text-transform: uppercase;

  color: #f8fafc;

  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    margin-left: 15px;
  }

  @media (max-width: ${Breakpoints.md}) {
    font-size: 14px;
  }
`

export const SeriesContainer = styled.div`
  position: relative;
  overflow: hidden;

  @media (max-width: ${Breakpoints.md}) {
    border: none;
  }
`

export const SeriesContainerBackground = styled.div<{ background: string }>`
  background: ${(props) => `${props.background}`};
  filter: blur(200px);
  transform: rotate(-180deg);

  position: absolute;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
`

export const PremiumSeriesTitle = styled.p`
  position: relative;
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 41px;

  text-align: center;
  text-transform: uppercase;

  background: linear-gradient(90.65deg, #be9b4b 7.3%, #fbe999 27.18%, #ffffff 35.63%, #ffffc0 46.07%, #d0b463 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 0px 10px rgba(239, 203, 74, 0.71);

  margin: 80px 0 64px 0;

  z-index: 1;

  @media (max-width: ${Breakpoints.md}) {
    margin: 32px 0;

    font-size: 24px;
    line-height: 32px;
  }
`

export const FanSeriesTitle = styled(PremiumSeriesTitle)`
  background: linear-gradient(90.65deg, #a2a2a2 7.3%, #e7e7e7 27.18%, #ffffff 35.63%, #b8b8b8 46.07%, #b8b8b8 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 10px rgba(187, 187, 187, 0.55);

  @media (max-width: ${Breakpoints.md}) {
    padding-top: 66px;
  }
`

export const UniqueNftsTitle = styled(FanSeriesTitle)`
  background: linear-gradient(90.65deg, #be9b4b 7.3%, #fbe999 13.18%, #ffffff 18.63%, #ffffc0 22.07%, #d0b463 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media (max-width: ${Breakpoints.md}) {
    padding-top: 0px;
  }
`

export const YellowImage = styled(Image)`
  position: absolute;
  top: -70px;
  left: -300px;
  transform: rotate(-2deg);
  z-index: 1;

  @media (max-width: ${Breakpoints.md}) {
    top: -40px;
    left: -315px;

    max-width: unset;
  }
`

export const RowInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  margin-bottom: 72px;
  margin-left: 80px;
  margin-right: 80px;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column-reverse;
    margin: 0 20px;
    gap: 24px;
    padding-bottom: 2px;
  }
`

export const GrayImage = styled(Image)`
  position: absolute;
  bottom: -50px;
  right: 0px;
  transform: rotate(-2deg);
  z-index: 1;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const Shadow = styled.div`
  position: relative;
  background: radial-gradient(56.4% 56.4% at 40.19% 44.4%, rgba(16, 16, 16, 0.7) 0%, rgba(16, 16, 16, 0) 68.23%);
`

export const VideoPreviewContainerMobile = styled.div`
  position: relative;
  @media (min-width: ${Breakpoints.md}) {
    display: none;
    position: auto;
  }
`

export const WaitListContainerMobile = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;

  text-align: center;
  text-transform: uppercase;
  align-items: center;

  margin-bottom: 88px;
  margin-top: 200px;

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 510px;
  }
`

export const SeriesContainerMobile = styled.div<{ borderTop?: boolean }>`
  position: relative;
  overflow: hidden;

  margin-bottom: 64px;

  border-top: ${(props) => (props.borderTop ? `1px solid #61616B;` : `none`)};

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const TitleSeriesContainer = styled.div`
  height: 129px;
  position: relative;
  overflow: hidden;
`

export const Neon = styled(Image)`
  @media (max-width: 1200px) {
    display: none;
  }
`

export const NeonMobile = styled(Neon)`
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
    width: 100%;
  }
`

export const PreviewImage = styled(Image)<{ showing: boolean }>`
  width: 300px;
  height: 300px;
  display: ${(props) => (props.showing ? '' : 'none')};
`

export const PreviewImageV1 = styled(Image)`
  width: 100%;
  bottom: 100px;
  position: relative;
`
export const PaySecuredby = styled(Image)`
  width: 100%;
  position: relative;
  maxwidth: 500px;
`
export const PreviewImageMobile = styled.img`
  width: 100%;
  position: absolute;
  right: 0;
`

export const WaitListJoinContainer = styled.div`
  display: grid;
  width: 90%;
  gap: 20px;
  grid-template-columns: 3fr 1fr;
  font-family: 'Circe';
`
export const WaitListJoinEmailContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`
export const WaitListJoinEmailSkew = styled.div`
  width: 20%;
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  background-color: white;
  transform: skew(-20deg);
`
export const WaitListJoinEmail = styled.input`
  border-radius: 0px;
  padding: 13px 10px;
  border: none;
  width: 90%;
  height: 100%;
  &::placeholder {
    color: black;
    font-family: 'Circe';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`

export const WaitListButtonContainer = styled.div`
  > div {
    min-width: unset;
    margin: 0;
  }
`

export const HowToBuyNftsContainer = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    > div {
      margin-top: 0 !important;
    }
  }
`

export const NewComerGuidContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -70px;
  background: linear-gradient(102.77deg, #101010 0%, #19191b 45.7%, #232327 64.23%, #101010 79.13%);

  @media (max-width: 1200px) {
    padding: 30px 0;
  }

  @media (max-width: ${Breakpoints.lg}) {
    gap: 20px;
  }

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 50px;
    flex-direction: column;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    justify-content: space-evenly;
  }
`

export const HowToBuyContainer = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    padding: 0 30px;
  }
`
export const BuyNftContainer = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    padding: 0 30px;
  }
`
export const BuyNftLink = styled.a`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  text-transform: uppercase;
  text-decoration-line: underline;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
    > svg {
      display: none;
    }
  }
`

export const ButtonGoTo = styled(ButtonStyled)`
  width: 160px;
  min-width: 0px;
  height: 48px;

  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.xl}) {
    width: 120px;
    font-size: 14px;
  }

  @media (max-width: 950px) {
    width: 77px;
    font-size: 11px;
  }

  @media (max-width: ${Breakpoints.md}) {
    width: 300px;
    font-size: 16px;
    margin-bottom: 48px;
  }
`
export const Elm = styled.div`
  width: 100%;
`
export const ButtonGoToInside = styled.div`
  width: 160px;
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(21deg);
`

export const VioletText = styled.span`
  color: #9542ff;
  text-decoration-line: underline;
`
