import { combineReducers } from 'redux'
import { loading, LoadingState } from './loading'
import { modal, ModalState } from './modal'
import { myTvToken, MyTvTokenState } from './myTvToken'
import { progressBar, ProgressBarState } from './progressBar'
import { supportedTokens, SupportedTokensState } from './supportedTokens'
import { toaster, ToasterState } from './toaster'
import { version, VersionState } from './version'
import { wallet, WalletState } from './wallet'
import { nativeToken, NativeTokenState } from './nativeToken'
import { error, ErrorState } from './error'

export const reducers = combineReducers({
  loading,
  progressBar,
  toaster,
  modal,
  wallet,
  version,
  supportedTokens,
  myTvToken,
  nativeToken,
  error,
})

export interface State {
  loading: LoadingState
  progressBar: ProgressBarState
  toaster: ToasterState
  modal: ModalState
  wallet: WalletState
  version: VersionState
  supportedTokens: SupportedTokensState
  myTvToken: MyTvTokenState
  nativeToken: NativeTokenState
  error: ErrorState
}
