import { ReactNode, useState } from 'react'

import { Arrow, ArrowTitle, Content, DropdownLineArrowContainer, DropdownLines, DropdownStyled, Title } from './Dropdown.style'

interface DropdownProps {
  title: string
  children?: ReactNode
  number: number
}

export const Dropdown = ({ title, children, number }: DropdownProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <DropdownStyled
        onClick={() => {
          setOpen((state) => !state)
        }}
      >
        <Title>{title}</Title>
        <DropdownLineArrowContainer>
          <DropdownLines src={`/images/lines-collection/${number}.png`} alt="" />
          
          <Arrow open={open}>
            <ArrowTitle open={open}>{open ? "CLOSE" : "OPEN"}</ArrowTitle>
            <img alt="arrow-up" src="/images/collection/arrow-up.svg" />
          </Arrow>
        </DropdownLineArrowContainer>
      </DropdownStyled>
      <Content open={open}>{children}</Content>
    </>
  )
}
