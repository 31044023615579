import { NftEntity, OrderEntity } from 'Entities'
import { AssetClass, OrderStatus } from 'Entities/Marketplace/OrderEntity'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Webservices from 'Webservices'
import { State } from '../../reducers/index'
import { ActivityType } from './Activities.enum'
import { ActivitiesView } from './Activities.view'

export type IPairOrderNftEntity = {
  order: OrderEntity
  token: NftEntity
}
export const Activities = () => {
  const version = useSelector((state: State) => state.version.number)
  const [activities, setActivities] = useState<IPairOrderNftEntity[]>([])
  const wallet = useSelector((state: State) => state.wallet)
  const [type, setType] = useState(ActivityType.OFFERS_MADE)

  const loadOrders = useCallback(async () => {
    switch (type) {
      case ActivityType.OFFERS_MADE:
        const responseData = await Webservices.Orders.get(null, {
          q: { sellerAddress: wallet.address, orderStatus: OrderStatus.Pending },
          sort: { createdAt: -1 },
        })
        if (!responseData) return
        const sellOrders = responseData.data
        const pairOrdersNftOffersMade: IPairOrderNftEntity[] = []
        sellOrders.forEach(async (order) => {
          const tokenAddress =
            order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenAddress : order.askTokenAddress
          const tokenId = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenId : order.askTokenId
          const tokens = await Webservices.Erc721.Tokens.get(null, {
            q: { tokenAddress: tokenAddress, tokenId: ~~tokenId },
          })
          pairOrdersNftOffersMade.push({
            order: order,
            token: tokens[0],
          })
        })

        setActivities(pairOrdersNftOffersMade)
        break
      case ActivityType.OFFERS_RECEIVED:
        const pairOrdersNftOffersReceived: IPairOrderNftEntity[] = []
        const nftOwnedWithAskOrders = await Webservices.Erc721.Tokens.get(null, {
          q: { hasAskOrders: true, ownerAddress: wallet.address },
        })
        if (!pairOrdersNftOffersReceived) return
        nftOwnedWithAskOrders.forEach((nft) => {
          nft.askOrders.forEach((order) =>
            pairOrdersNftOffersReceived.push({
              order: order,
              token: nft,
            }),
          )
        })

        pairOrdersNftOffersReceived.sort((a: IPairOrderNftEntity, b: IPairOrderNftEntity) => {
          if (!a.order.createdAt) return 1
          if (!b.order.createdAt) return -1
          return new Date(b.order.createdAt)?.getTime() - new Date(a.order.createdAt)?.getTime()
        })
        setActivities(pairOrdersNftOffersReceived)
        break
      default:
        setActivities([])
        break
    }
  }, [type, wallet.address])

  useEffect(() => {
    if (!wallet.address) return
    loadOrders()
  }, [loadOrders, wallet.address])

  const deleteLine = useCallback((activity: IPairOrderNftEntity) => {
    setActivities((state) =>
      state.filter((stateActivity) => stateActivity.order.orderHash !== activity.order.orderHash),
    )
  }, [])

  return (
    <ActivitiesView activities={activities} type={type} setType={setType} deleteLine={deleteLine} version={version} />
  )
}
