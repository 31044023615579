import { Image } from '../../styles'
import { ISale } from './SalesHistory.controller'
import {
  SaleHistoryGridFirstCase,
  SalesHistoryContainer,
  SalesHistoryFirstLine,
  SalesHistoryGrid,
  SalesHistoryResults,
  SalesHistorySecondLine,
  SalesHistorySelect,
  SalesHistorySelectContainer,
  SalesHistoryStyled,
  SalesHistoryTh,
  SalesHistoryTitle,
} from './SalesHistory.style'
import { TableLine } from './TableLine/TableLine.controller'

interface SalesHistoryViewProps {
  version: number
  sales: ISale[]
}

export const SalesHistoryView = ({ version, sales }: SalesHistoryViewProps) => {
  return (
    <SalesHistoryStyled>
      <SalesHistoryFirstLine>
        <SalesHistoryTitle>Sales History</SalesHistoryTitle>
      </SalesHistoryFirstLine>
      <SalesHistoryContainer>
        <SalesHistorySecondLine>
          <SalesHistoryResults>{sales.length} results</SalesHistoryResults>
          <SalesHistorySelectContainer>
            {version === 2 && (
              <SalesHistorySelect defaultValue={0}>
                <option disabled value={0}>
                  Filter by
                </option>
                <option>...</option>
                <option>...</option>
                <option>...</option>
              </SalesHistorySelect>
            )}
            <Image
              position={'absolute'}
              top={34}
              right={24}
              alt="search icon"
              src={`/images/marketplace/arrow-down.svg`}
            />
          </SalesHistorySelectContainer>
        </SalesHistorySecondLine>
        <SalesHistoryGrid>
          <SaleHistoryGridFirstCase />
          <SalesHistoryTh>Price</SalesHistoryTh>
          <SalesHistoryTh>Date</SalesHistoryTh>
          <SalesHistoryTh>Name</SalesHistoryTh>
          <SalesHistoryTh>Token ID</SalesHistoryTh>
          <SalesHistoryTh>Contract address</SalesHistoryTh>
          <SalesHistoryTh>Sale type</SalesHistoryTh>
          <SalesHistoryTh>Tx hash</SalesHistoryTh>
          {sales.map((sale) => (
            <TableLine key={sale.order.orderHash} sale={sale} />
          ))}
        </SalesHistoryGrid>
      </SalesHistoryContainer>
    </SalesHistoryStyled>
  )
}
