import { connectWallet, setMyTvBalance } from 'actions/Wallet.actions'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import { getWeb3Modal } from 'utils/web3modal'

export const WalletHandler = () => {
  const dispatch = useDispatch()
  const wallet = useSelector((state: State) => state.wallet)
  const myTvToken = useSelector((state: State) => state.myTvToken)

  const loadWallet = React.useCallback(async () => {
    const web3Modal = getWeb3Modal()
    if (web3Modal.cachedProvider) {
      dispatch(connectWallet())
    }
  }, [dispatch])

  useEffect(() => {
    if (!wallet.isConnected) {
      loadWallet()
    }
  }, [loadWallet, wallet])

  useEffect(() => {
    if (wallet.address && myTvToken.address) {
      if (wallet.chainId === myTvToken.chainId) {
        dispatch(setMyTvBalance(wallet.address))
      }
    }
  }, [dispatch, myTvToken.address, myTvToken.chainId, wallet.address, wallet.chainId, wallet.myTvBalance])

  return <></>
}
