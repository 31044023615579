import { TokenSupportEntity } from 'Entities'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { State } from 'reducers'
import * as Webservices from 'Webservices'
import { CollectionView } from './Collection.view'

export const Collection = () => {
  const version = useSelector((state: State) => state.version.number)
  const [tokensSupports, setTokensSupport] = useState<TokenSupportEntity[]>([])
  const { slug } = useParams<{ slug: string }>()

  const getTokensSupport = React.useCallback(async (slug: string, chainId: number) => {
    const tokenSupports = await Webservices.TokensSupport.get(null, {
      q: { slug: slug, chainId: chainId },
      sort: { 'metadata.front.order': 1 },
    })
    setTokensSupport(tokenSupports)
  }, [])

  useEffect(() => {
    if (!slug || !process.env.REACT_APP_DEFAULT_CHAINID) return
    getTokensSupport(slug, parseInt(process.env.REACT_APP_DEFAULT_CHAINID))
  }, [slug, getTokensSupport])

  return <CollectionView version={version} tokenSupports={tokensSupports} />
}
