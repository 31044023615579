import styled, { css } from 'styled-components/macro'

export const CardActionStyled = styled.div`
  margin-top: 16px;
`

export const CardActionContainer = styled.div``

export const CardActionFirstLine = styled.p`
  margin: 0;
  font-family: Circe;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const CardActionBold = styled.span`
  font-weight: bold;
`

export const CardActionSubTitle = styled.h3`
  margin: 0;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #f8fafc;
  margin-top: 24px;
`

export const CardActionInputContainer = styled.div`
  display: flex;
  margin-top: 8px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  position: relative;
`

export const CardActionInputSkew = styled.div`
  position: absolute;
  background: #171719;
  transform: skew(-21deg);
  width: 50px;
  height: 100%;
  top: 0;
  z-index: 1;
`
export const CardActionInputSkewLeft = styled(CardActionInputSkew)`
  right: calc(86.5px + 17px);
`

export const CardActionInputSkewRight = styled(CardActionInputSkew)`
  right: calc(86.5px - 40px);
`

export const CardActionInput = styled.input`
  background: #171719;
  border: none;
  padding: 15px 24px;
  width: calc(100% - 105px);
  color: rgba(248, 250, 252, 0.5);
  border-right: 1px solid #36373e;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  @media (max-width: 767px) {
    width: calc(100% - 120px);
  }

  z-index: 2;

  ::placeholder {
    color: rgba(248, 250, 252, 0.5);

    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`

export const CardActionInputRight = styled.option`
  margin: 0;
  padding: 14px 20px;
  width: 86.5px;
  color: white;
  background: #171719;
  z-index: 2;
`

export const CardActionBalanceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`

export const CardActionBalanceTitle = styled.p`
  margin: 0;
  margin-right: 24px;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`
export const CardActionBalance = styled.p`
  margin: 0;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  text-align: right;
  text-decoration-line: underline;

  color: rgba(248, 250, 252, 0.5);
`
export const CardActionBlueBar = styled.img`
  margin: 24px 0;
`

export const CardActionLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  position: relative;
`

export const CardActionLineLeftGrid = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
`

export const CardActionLineLeftSubtext = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  color: #9a9a9a;
`

export const CardActionSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: #61616b;
  margin: 16px 0;
`

export const CardActionButtonContainer = styled.div`
  margin: 30px auto 0 auto;
  width: 250px;
  display: flex;
  justify-content: center;
`

export const CardActionDisabledInputContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  position: relative;

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.3;
      `
    }
    return
  }}
`

export const CardActionDisabledInputSkew = styled.div`
  position: absolute;
  background: #171719;
  transform: skew(-21deg);
  width: 50px;
  height: 100%;
  top: 0;
  z-index: 1;
`
export const CardActionDisabledInputSkewLeft = styled(CardActionDisabledInputSkew)`
  right: calc(86.5px + 17px);
`

export const CardActionDisabledInputSkewRight = styled(CardActionDisabledInputSkew)`
  right: calc(86.5px - 40px);
`

export const CardActionDisabledInput = styled.input`
  background: #171719;
  border: none;
  padding: 15px 24px;
  width: calc(100% - 130px);
  color: rgba(248, 250, 252, 0.5);

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  @media (max-width: 767px) {
    width: calc(100% - 120px);
  }

  z-index: 2;

  ::placeholder {
    color: rgba(248, 250, 252, 0.5);

    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`

export const CardActionDisabledInputRight = styled.p`
  margin: 0;
  padding: 14px 20px;
  width: 86.5px;
  color: white;
  background: #171719;
  z-index: 2;
`

export const TokensSelector = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #f8fafc;

  padding-right: 20px;

  background: #171719;
  z-index: 2;
  border: none;
`

export const ExpirationTimeContainer = styled.span`
  display: flex;
  align-items: center;
`

export const ExpirationTimeText = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #f8fafc;

  margin-left: 12px;
`

export const CardActionLineRight = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: right;

  color: rgba(248, 250, 252, 0.5);
`

export const CardActionLineLeft = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const CardActionFees = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`
