import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const SubInfoStyled = styled.div<{ marginBottom?: number; marginLeft?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`

export const Title = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #f8fafc;
  margin: 0;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 14px;
  }
`

export const Content = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #f8fafc;
  margin: 0;
  padding-left: 36px;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const ArrowImage = styled.img`
  width: fit-content;
  height: fit-content;
`
export const FirstLine = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const SecondLine = styled.div``
