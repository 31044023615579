import * as React from 'react'
import { OpeningPackModalView } from './OpeningPackModal.view'

interface IProps {
  openingVideo: string
  tokenId: number
  tokenAddress: string
  tokenUri: string
  onClose: () => void
  onVideoEnded?: () => void
}
export const OpeningPackModal = ({ openingVideo, onClose, tokenId, tokenAddress, tokenUri, onVideoEnded }: IProps) => {
  return (
    <OpeningPackModalView
      openingVideo={openingVideo}
      tokenId={tokenId}
      tokenAddress={tokenAddress}
      tokenUri={tokenUri}
      onClose={onClose}
      onVideoEnded={onVideoEnded}
    />
  )
}
