import { NftEntity } from 'Entities'
// prettier-ignore
import * as React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../reducers/index'
import { ModalType } from './CardAction.enum'
import { CardActionView } from './CardAction.view'

interface CardActionProps {
  owner: string
  collection: string
  type: ModalType
  item: NftEntity | null
  callBack?: () => void
}

export const CardAction = ({ owner, collection, type, item, callBack }: CardActionProps) => {
  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)
  if (!item) return <></>
  return (
    <CardActionView
      version={version}
      owner={owner}
      collection={collection}
      wallet={wallet}
      type={type}
      item={item}
      callBack={callBack}
    />
  )
}
