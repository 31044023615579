import styled from 'styled-components/macro'
import { backgroundColor, borderColor, Box, Image, Page, Text } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const LandingStyled = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 80px 0 0 0;
`

export const FirstPartContainer = styled.div`
  position: relative;
  margin-bottom: 135px;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const FirstPartMobileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
  overflow: clip;
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.md}) {
    overflow: hidden;
  }
`

export const BoxTitleMobile = styled(Box)`
  margin-top: 38px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OnlyBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${Breakpoints.md}) {
    justify-content: flex-start;
    text-align: start;
  }
`

const LandingCard = styled(Box)`
  background: ${backgroundColor};
  border: 1px solid ${borderColor};
  border-right: 0px;
  border-top: 0px;
`

export const FirstCard = styled(LandingCard)`
  height: 600px;
  border-left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;

  > div > a > span > div {
    margin: 30px 0;
  }
  background: none;
  border: 0px;
`

export const SecondCard = styled(LandingCard)`
  width: 40%;
  height: 800px;
  text-align: right;
  padding-left: 30px;
  > img {
    margin-top: 70px;
  }
`

export const HeadDescription = styled.div`
  max-height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  bottom: 0;

  @media (max-width: ${Breakpoints.md}) {
    align-items: center;
    width: 100%;
    padding-top: 404px;
    margin-bottom: 30px;
  }
`

export const FirstBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
`

export const SecondPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4vw;
  z-index: 1;
`

export const BlockTitle = styled.div`
  margin-bottom: 48px;
`

export const SecondBlock = styled.div`
  display: flex;
`

export const ThirdPartContainer = styled.div`
  margin: 4vw 0 4vw 4vw;
  text-align: start;
`

export const Title = styled(Text)`
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const WhiteSubtitle1 = styled(Text)`
  font-family: Fira Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  color: #f8fafc;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const PurpleSubtitle1 = styled(WhiteSubtitle1)`
  color: #9542ff;
  text-shadow: 0px 0px 24px #9542ff;
`

export const WhiteSubtitle2 = styled(Text)`
  font-family: Fira Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #f8fafc;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const PurpleSubtitle2 = styled(WhiteSubtitle2)`
  color: #9542ff;
  text-shadow: 0px 0px 24px #9542ff;
`

export const FourthPartContainer = styled.div`
  display: flex;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const FourthPartMobileContainer = styled.div`
  margin: 0 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const FourthPartRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.sm}) {
    align-items: center;
  }
`

export const JumboCardFourthPart = styled(Image)`
  min-width: 35vw;
  margin-right: 5vw;
  margin-top: 220px;
  width: 135px;
  margin-left: 32px;
  margin-bottom: 32px;
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 100px;
  }
`

export const SecondBoxImage = styled(Box)`
  height: 240px;
  > img {
    display: block;
    position: relative;
    top: -4vw;
  }
`

export const FirstGreyBox = styled(Box)`
  min-width: 194px;
  height: 194px;
  position: relative;
  background-color: #36373e;
  top: 50px;
  left: -120px;
`

export const FifthPartContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: -4vw 4vw 0 4vw;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: ${Breakpoints.lg}) {
    margin: 0 4vw;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    justify-content: space-around;
  }
`

export const SecondGreyBox = styled(Box)`
  max-width: 546px;
  width: 92vw;
  height: 388px;
  background-color: #36373e;
  @media (max-width: ${Breakpoints.sm}) {
    max-width: 228px;
    width: 92vw;
    height: 162px;
  }
`

export const SixthPartContainer = styled.div`
  margin: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #9542ff;
  box-shadow: 0px 0px 44px 7px #9542ff;
  padding: 0 0 30px 0;
  position: relative;
  z-index: 1;
  padding-bottom: 48px;
  @media (max-width: ${Breakpoints.xl}) {
    margin: 58px 4vw;
  }
`

export const TextBody = styled(Text)`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const TextBold = styled.span`
  font-weight: 800;
`

export const ThirdBoxImage = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: -250px;
  > img {
    display: block;
  }
  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`
export const SeventhPartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 0 4vw 4vw;
  position: relative;
`

export const ThirdGreyBox = styled(Box)`
  min-width: 537px;
  height: 363px;
  background-color: #36373e;
  margin: 30px;
`

export const Line = styled.div`
  border-top: 1px solid #36373e;
`

export const Title2 = styled(Text)`
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const TextBody2 = styled(Text)`
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #f8fafc;
`

export const EightPartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 120px 4vw 0 4vw;
  position: relative;
  text-align: start;
  @media (max-width: ${Breakpoints.sm}) {
    margin: 80px 4vw 0 4vw;
  }
`
export const SocialLinksBox = styled(Box)`
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`

export const SocialLinkBox = styled(Box)`
  margin: 36px 36px 0 36px;
  @media (max-width: ${Breakpoints.sm}) {
    margin: 18px 18px 0 18x;
  }
`

export const SocialLink = styled.a`
  background-color: #232327;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 190px;
  width: 190px;
  @media (max-width: ${Breakpoints.sm}) {
    height: 147px;
    width: 147px;
  }
`

export const SocialLinksContainerResponsive = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    padding-top: 40px;
  }
`

export const SocialLinkBoxResponsive = styled.a<{ isLast?: boolean }>`
  background: #232327;
  display: flex;
  height: fit-content;
  justify-content: center;
  padding: 50px;
  margin: 0;
  ${(props) => (props.isLast ? 'grid-column : 1/3' : '')};
`

export const NinthPartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 4vw 0 4vw 4vw;
  position: relative;
  text-align: start;
`

export const ThirdCard = styled(LandingCard)`
  height: 600px;
  border-left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  > div > a > span > div {
    margin: 30px 0;
  }
  background: none;
  border: 0px;
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  height: 500px;
`

export const FourthCard = styled(SecondCard)`
  height: 640px;
`

export const PartnerBox = styled(Box)`
  display: flex;
  margin: 30px 0;
  overflow: hidden;
`

export const PartnerWrapper = styled.div`
  justify-content: flex-start;
  display: flex;
  @media (max-width: ${Breakpoints.md}) {
    flex-flow: row wrap;
    justify-content: center;
  }

  @media (min-width: ${Breakpoints.md}) {
    animation: scrolling-global 20s linear infinite;
    animation-delay: 0s;
  }

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
  }
`

export const PartnerWrapper2 = styled(PartnerWrapper)`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const PartnerLink = styled.a`
  margin: 0 12px 32px 12px;
  padding: 25px;
  border: 1px solid #36373e;
  height: 105px;
  min-width: 180px;
  align-items: center;
  justify-content: center;
  transform: skew(-10deg);

  > img {
    transform: skew(10deg);
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    max-width: calc(50% - 32px);
    min-width: calc(50% - 32px);
  }
`

export const RulesBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1255px) {
    flex-direction: column;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    justify-content: space-around;
  }
`

export const PoweredByBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  margin: 25px 4vw;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.xl}) {
    flex-direction: column;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    justify-content: space-around;
  }
`

export const ThirdPartBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const BigTitle1 = styled(Text)`
  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  text-align: left;
  text-transform: uppercase;
  color: #f8fafc;

  @media (min-width: ${Breakpoints.md}) {
    font-size: 31px;
    line-height: 37px;
    text-align: center;
  }
`

export const BigTitleGradient = styled(Text)`
  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 31px;
  line-height: 37px;
  text-align: center;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, #9542ff, #3ad8ed);

  -webkit-text-fill-color: transparent;
`

export const BigTitle2 = styled(Text)`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #f8fafc;

  @media (min-width: ${Breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`

export const CardSlider = styled(Image)`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const CardSliderMobile = styled(Image)`
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const TradeBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 80px;
  }
`

export const ThirdPartRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
`

export const ThirdPartTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-left: 50px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 400px;
  @media (max-width: ${Breakpoints.xl}) {
    flex-basis: 0;
    align-items: flex-start;
    margin-left: 0;
    min-width: 60vw;
  }
`

export const SubstractBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`

export const BigNumber = styled.p`
  font-family: Fira Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 288px;
  line-height: 346px;
  text-align: center;
  color: #36373e;
  margin: 0;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: 128px;
    line-height: 154px;
  }
`

export const BigNumberText = styled.p`
  margin: 0;
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: #3ad8ed;
  border-bottom: 3px solid #3ad8ed;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`

export const SubstractImage = styled(Image)`
  height: 116px;
  @media (max-width: ${Breakpoints.sm}) {
    height: 54px;
  }
`

export const TitleImage = styled(Image)`
  height: 32px;
  margin-bottom: 3px;
  @media (max-width: ${Breakpoints.sm}) {
    height: 25px;
  }
`

export const FourthPartTextContainerMobile = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-right: 4vw;
`

export const AnimationPackOpeningBox = styled(Box)`
  display: flex;
  justify-content: center;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
    margin-top: 30px;
  }
`

export const TradeImage = styled(Image)`
  @media (max-width: ${Breakpoints.sm}) {
    height: 173px;
  }
`

export const VerticalLineLinearGradient = styled(Box)`
  background: linear-gradient(#9543ff 0%, #3ad8ed 103.14%);
  width: 2px;
`

export const CorneredBox = styled(Box)`
  border: 1px solid #36373e;
  padding: 20px;
  min-height: 56px;
  text-align: start;
  position: relative;
  ::after {
    top: 0;
    right: 0;
    position: absolute;
    background: white;
    content: '';
    width: 34px;
    height: 34px;
    margin: -17px;
    transform: rotate(45deg);
    background: #101010;
    border-bottom: 1px solid #36373e;
  }
`

export const RulesRowsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  margin-right: 24px;
  flex: 1;
`

export const Caption = styled(Text)`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #f8fafc;

  @media (max-width: 1450px) {
    font-size: 14px;
    line-height: 17px;
  }
`

export const RulesContainer = styled(Box)`
  display: flex;
  align-items: stretch;
  flex: 1;
`

export const RulesFigureContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1450px) {
    width: 40%;
  }
  @media (max-width: 1255px) {
    display: none;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    display: none;
  }
`

export const RulesFigureContainerResponsive = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 500px;
  }

  @media (min-width: 1255px) {
    display: none;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    display: block;
    justify-content: space-around;

    > img {
      width: 580px;
    }
  }
`

export const BandBox = styled(Box)`
  margin: 30px;
  white-space: nowrap;
  @media (max-width: ${Breakpoints.xxl}) {
    margin: 30px 0;
    overflow: hidden;
  }
`

export const RoadmapDesktopBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px 0;
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const RoadmapDesktop = styled(Image)`
  width: 100%;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const RoadmapMobile = styled(Image)`
  padding: 0 32px;
  display: none;
  @media (max-width: ${Breakpoints.sm}) {
    display: block;
  }
`

export const RoadmapTablet = styled(Image)`
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
    padding: 0 32px;
  }
  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`

export const PoweredByText = styled(Text)`
  margin: 20px;
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #f8fafc;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const RulesTextBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const RulesTextContainer = styled(Box)`
  text-align: start;
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 80px;
  }
`

export const RoadmapTitleBox = styled(Box)`
  margin: 120px 4vw 48px 4vw;
  text-align: start;
  @media (max-width: ${Breakpoints.sm}) {
    margin: 80px 4vw 48px 4vw;
  }
`

export const ParticipateChallengesBox = styled(Box)`
  margin-top: 72px;
  display: flex;
  justify-content: center;
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 30px;
  }
`

export const BandText = styled(Title2)`
  display: inline-block;
  @keyframes scrolling {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @media (max-width: ${Breakpoints.xxl}) {
    animation: scrolling 10s linear infinite;
  }
`
export const VideoBackground = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  z-index: 0;

  @media (max-width: ${Breakpoints.md}) {
    right: 450px;
    max-width: 100%;
  }
`

export const LinearGradientBox = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.97) 100%);
  height: 114px;
  width: 100%;
  bottom: 0;

  @media (max-width: ${Breakpoints.md}) {
    left: 450px;
  }
`

export const BackgroundImage = styled(Image)`
  max-width: none;
`

export const Shadow = styled.div`
  position: relative;
  background: radial-gradient(56.4% 56.4% at 40.19% 44.4%, rgba(16, 16, 16, 0.7) 0%, rgba(16, 16, 16, 0) 68.23%);
`
export const BoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 60px;
  padding-left: 60px;
`

export const BoxBigNumber = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 350px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 150px;
    height: 150px;
  }
`

export const BoxBigNumberText = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const BoosterGif = styled(Image)`
  width: 314px;
  height: 522px;
  margin-right: 191px;

  @media (max-width: ${Breakpoints.xxl}) {
    margin-right: 90px;
  }

  @media (max-width: ${Breakpoints.xl}) {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: ${Breakpoints.sm}) {
    width: 165px;
    height: 274px;
    margin: auto;
  }
`
