import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const DropdownPremiumStyled = styled.div``
export const ImgDesktop = styled.img`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const ImgMobile = styled.img`
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`
export const FirstPartContainer = styled.div``
export const DropdownPremiumSubtext = styled.p`
  font-family: Circe;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
  margin-top: -16px;
`

export const DropdownPremiumSubtextBold = styled.span`
  font-weight: 700;
`
export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-top: 86px;
  align-items: center;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }
`

export const DropdownPremiumCard = styled.div`
  width: calc(100% / 3);
  background: rgba(12, 12, 12, 0.82);
  border: 2px solid #36373e;
  backdrop-filter: blur(24px);
  padding: 48px 24px;
  height: 270px;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    height: fit-content;
    padding: 24px;
  }
`

export const DropdownPremiumCardGold = styled(DropdownPremiumCard)`
  border: none;
  background: linear-gradient(
    90.65deg,
    rgba(190, 155, 75, 0.8) 7.3%,
    rgba(251, 233, 153, 0.8) 27.18%,
    rgba(255, 255, 255, 0.8) 35.63%,
    rgba(255, 255, 192, 0.8) 46.07%,
    rgba(208, 180, 99, 0.8) 102.74%
  );
  padding: 4px;
  @media (max-width: ${Breakpoints.md}) {
    height: 100%;
    order: -1;
  }
`

export const DropdownPremiumCardGoldBg = styled.div`
  background: #101010;
  padding: 48px 32px;

  height: 100%;
  @media (max-width: ${Breakpoints.md}) {
    padding: 24px;
  }
`

export const CardImage = styled.img`
  width: 100%;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const CardImageGold = styled(CardImage)`
  padding: 0px 73px;
`

export const CardSmallTitle = styled.div`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;
  text-transform: uppercase;
  color: #f8fafc;

  text-shadow: 0px 0px 10px rgba(187, 187, 187, 0.55);

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 0px;
  }
`
export const CardTitle = styled(CardSmallTitle)`
  font-size: 24px;
  line-height: 32px;
  margin-top: 4px;
`

export const CardSmallTitleGold = styled(CardSmallTitle)`
  position: relative;
  background: linear-gradient(90.65deg, #be9b4b 7.3%, #fbe999 27.18%, #ffffff 35.63%, #ffffc0 46.07%, #d0b463 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 10px rgba(239, 203, 74, 0.71);
  z-index: 1;
`
export const CardTitleGold = styled(CardSmallTitleGold)`
  font-size: 24px;
  line-height: 32px;
  margin-top: 4px;
`

export const CardText = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  color: #f8fafc;
  margin-top: 24px;
`

export const UniqueContainer = styled.div`
  margin-top: var(--collection-margin);
  margin-bottom: var(--collection-margin);
  display: flex;
  gap: 140px;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    margin-top: 64px;
    gap: 64px;
    margin-bottom: 0;
  }
`
export const UniqueContainerLeft = styled.div`
  height: 420px;
`
export const UniqueContainerRight = styled.div`
  > p {
    text-align: left !important;
    margin: 0;

    @media (max-width: ${Breakpoints.md}) {
      text-align: center !important;
    }
  }
`
export const GenerativeGif = styled.img`
  height: 100%;
`
export const UniqueContainerRightTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  color: #f8fafc;
  margin: 24px 0 0 0 !important;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`
export const UniqueContainerRightSubTitle = styled(UniqueContainerRightTitle)`
  font-weight: 300;
  margin: 24px 0 0 0 !important;
`

export const AttributesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  @media (max-width: ${Breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin: 64px 0;
  }
`
export const AttributeCard = styled.div``
export const AttributeImg = styled.img`
  height: 80px;
`
export const AttributeTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;
  margin: 4px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`
export const AttributeText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  text-transform: uppercase;

  color: #a3a3a3;
  margin: 16px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 12px;
    line-height: 16px;
  }
`
export const AttributeLevel = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  text-transform: uppercase;

  color: #3ad8ed;
  margin: 4px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 14px;
  }
`

export const LoebStandingContainer = styled.div`
  margin-top: 64px;
  > img {
    width: 100%;
  }
`
