import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const DropdownStoryStyled = styled.div``
export const TheFirstContainer = styled.div`
  margin-top: var(--collection-margin);
  @media (max-width: ${Breakpoints.md}) {
    margin-top: 64px;
  }
`
export const TheFirstSubTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  color: #f8fafc;
  margin: 4px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const TheFirstText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #f8fafc;
  margin: 24px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    margin: 16px 0 0 0;
    font-size: 16px;
  }
`

export const CelebrateContainer = styled.div`
  background-image: url('/images/celebrate_background.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: var(--collection-margin);
  padding: 48px 100px;

  @media (max-width: ${Breakpoints.md}) {
    padding: 24px 16px;
    margin: 64px -16px 0 -16px;
  }
`

export const CelebrateTitle = styled.p`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const CelebrateText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;

  color: #f8fafc;
  margin: 24px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const WantedContainer = styled.div`
  margin-top: var(--collection-margin);
  padding: 0 100px;

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 64px;
    padding: 0;
  }
`
export const WantedTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;
  margin: 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const WantedText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;

  color: #f8fafc;
  margin: 24px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const EmotionContainer = styled.div`
  display: flex;
  margin-top: 204px;
  align-items: center;
  gap: 40px;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    margin-top: 100px;
    gap: 32px;
  }
`
export const EmotionImg = styled.img`
  width: 25vw;
  max-width: 460px;
  /* transform: scaleX(-1); */

  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    max-width: none;
  }
`
export const EmotionText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  color: #f8fafc;
  text-align: left;
  margin: 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
    text-align: center;
  }
`
export const OnceContainer = styled.div`
  margin-top: var(--collection-margin);
  flex-wrap: wrap;
  display: flex;

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`

export const OnceLine = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 30px;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    gap: 32px;
  }
`

export const OnceLineSecond = styled(OnceLine)`
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column-reverse;
  }
`

export const OnceLeftA = styled.div`
  width: 50%;
  flex: 0 0 calc(50% - 10px);
  flex-grow: 1;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    display: flex;
    gap: 32px;
  }
`

export const OnceLeft = styled.div`
  width: 50%;
  flex: 0 0 calc(50% - 10px);
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    display: flex;
    gap: 32px;
  }
`
export const OnceRight = styled.div`
  width: 50%;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    display: flex;
    gap: 32px;
  }
`
export const OnceTitleDesktop = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  color: #f8fafc;
  text-align: center;
  padding: 0 100px;
  margin: 0 0 24px 0;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const OnceTitleMobile = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  color: #f8fafc;
  text-align: center;
  margin: 64px 0 24px 0;
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const OnceTextC = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */

  color: #f8fafc;
  margin: 0;
  text-align: center;
  padding: 0 30%;

  @media (max-width: ${Breakpoints.md}) {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }
`

export const OnceText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */

  color: #f8fafc;
  margin: 0;
  text-align: left;
  padding: 0 100px;

  @media (max-width: ${Breakpoints.md}) {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }
`
export const OnceImg = styled.img`
  width: 100%;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const OnceImgMobile = styled.img`
  width: 100vw;
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
    margin: 0 -16px;
  }
`

export const LegendContainer = styled.div`
  padding: 0 300px;
  height: 650px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${Breakpoints.md}) {
    height: fit-content;
    padding: 0;
    margin-top: 64px;
  }
`
export const LegendTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  color: #f8fafc;
  margin: 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const LegendText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;

  color: #f8fafc;
  margin: 24px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const LegendStripesLeft = styled.img`
  position: absolute;
  top: 100px;
  left: 0;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const LegendStripesRight = styled.img`
  position: absolute;
  bottom: 100px;
  right: 0;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const RacingContainer = styled.div`
  margin-top: var(--collection-margin);
  padding: 0 300px;

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 64px;
    padding: 0;
  }
`
export const RacingFirstTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const RacingTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;
  margin: 40px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const RacingText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #f8fafc;
  margin: 4px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`
