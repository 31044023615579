import styled, { css } from 'styled-components/macro'
import { BadgeType } from './Badge.view'

export const BadgeStyled = styled.span<{ type: BadgeType }>`
  ${(props) => {
    let backgroundColor = ''
    let display = 'block'

    switch (props.type) {
      case BadgeType.CORE:
        backgroundColor = 'linear-gradient(94.18deg, #5A3E2A 0%, #7A583F 100%)'
        break
      // case 'other':
      //   backgroundColor = 'linear-gradient(97.27deg, #AC7035 0%, #E2964A 100%)'
      //   break
      case BadgeType.RARE:
        backgroundColor = 'linear-gradient(98.41deg, #8F8F8F 0%, #C8C6C6 100%)'
        break
      case BadgeType.LEGENDARY:
        backgroundColor = 'linear-gradient(94.18deg, #DB9F2B 0%, #EFCB4A 100%)'
        break
      case BadgeType.MYTHICAL:
        backgroundColor = 'linear-gradient(94.18deg, #4BB5CD 0%, #8FEAFF 100%)'
        break
      default:
        display = 'none'
        break
    }

    return css`
      background: ${backgroundColor};
      display: ${display};
    `
  }}
  width: fit-content;
  height: fit-content;
  border-radius: 50px;
  padding: 8px 16px 8px 16px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  text-transform: uppercase;

  color: #f8fafc;
`
