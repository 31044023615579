import styled from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const PackTopContainer = styled.div`
  @media (max-width: ${Breakpoints.lg}) {
    padding-top: 112px;
  }
`

export const PackStyled = styled(Page)`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 100px;
  width: 100%;
  max-width: 1440px;
  text-align: center;

  @media (min-width: 1440px) {
    max-width: 100%;
    padding: 0 10vw;
  }

  @media (max-width: ${Breakpoints.md}) {
    padding: 0 32px;
  }

  @media (max-width: ${Breakpoints.sm}) {
    padding: 0 16px;
  }
`

export const PackGrid = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    padding-top: 24px;
  }
`
export const ContainerDesktop = styled.div`
  display: block;
  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }

  display: flex;
  margin: 110px auto 0 auto;
  width: 100%;
  backdrop-filter: blur(20px);
  justify-content: space-between;

  @media (max-width: ${Breakpoints.md}) {
    margin: 0 auto 0 auto;
  }
`
export const ContainerResponsive = styled.div`
  display: none;
  padding-bottom: 100px;
  @media (max-width: ${Breakpoints.sm}) {
    display: block;
  }
`
export const CardPrice = styled.div`
  display: block;

  > p:nth-child(1) {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: rgba(248, 250, 252, 0.5);
    margin: 5px auto;
  }

  > p:nth-child(2) {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin: 5px auto;
  }

  &::before {
    content: '';
    display: block;
    width: 140px;
    height: 1px;
    background-color: #61616b;
    margin: 8px auto;
  }

  &::after {
    content: '';
    display: block;
    width: 140px;
    height: 1px;
    background-color: #61616b;
    margin: 8px auto;
  }
`

export const BackgroundShapeLeft = styled.div`
  position: absolute;
  width: 500px;
  height: 250px;
  z-index: 0;

  top: 150px;
  left: -200px;
  background-image: url('/images/packs/left-shape-full.png');
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1439px) {
    width: 487px;
    height: 538px;
    background-image: url('/images/packs/left-shape.png');
    left: 0;
    top: 50px;
  }

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`
export const BackgroundShapeRight = styled.div`
  position: absolute;
  width: 455px;
  height: 350px;
  z-index: 0;

  top: 400px;
  right: 0px;
  background-image: url('/images/packs/right-shape-full.png');
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1439px) {
    background-image: url('/images/packs/right-shape.png');
    width: 395px;
    height: 400px;
    right: 0;
    top: 200px;
  }

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const PackShadow = styled.img<{ selected: boolean }>`
  width: 100%;
  position: absolute;
  right: 0;
  z-index: -1;
  opacity: ${(props) => (props.selected ? '1' : '0')};
  transition: opacity 500ms ease-in-out;

  @media (max-width: ${Breakpoints.lg}) {
    opacity: 0;
  }
`

export const SkewContainer = styled.div<{ selected: boolean }>`
  width: 80%;
  height: 100%;
  position: absolute;
  z-index: -1;
  transform: skew(-5deg, 0);
  border-top: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};
  border-bottom: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const LeftSkewContainer = styled(SkewContainer)<{ selected: boolean }>`
  border-right: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};
  right: 0;
`

export const RightSkewContainer = styled(SkewContainer)<{ selected: boolean }>`
  border-left: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};
  left: 0;
`

export const MissingBorder = styled.div<{ selected: boolean }>`
  position: absolute;
  z-index: -1;
  width: 80%;
  height: 100%;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const MissingBorderTop = styled(MissingBorder)<{ selected: boolean }>`
  border-top: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};
  right: 0px;
`

export const MissingBorderBottom = styled(MissingBorder)<{ selected: boolean }>`
  border-bottom: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};
  left: 0px;
`

export const CardContainer = styled.div`
  width: 48.5%;
  position: relative;

  @media (max-width: ${Breakpoints.sm}) {
    min-width: 100%;
    margin-bottom: 32px;
  }
`
export const FirstCardContainer = styled(CardContainer)<{ selected: boolean }>``
export const SecondCardContainer = styled(CardContainer)<{ selected: boolean }>``

export const DefCard = styled.div<{ selected: boolean }>`
  > h1 {
    margin: 31px 0 16px 0;
    font-family: Termina;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #ffffff;

    font-size: 34px;
    line-height: 40.8px;

    @media (max-width: ${Breakpoints.lg}) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  > span {
    margin: 20px 0 0 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 43px 0 32px 0;
  border: 1px solid ${(props) => (props.selected ? '#9542FF' : '#61616b')};
`

export const FirstCard = styled(DefCard)`
  border-right: none;
  border-bottom: 1px solid transparent;

  @media (max-width: 1023px) {
    border: 1px solid #9542ff;
  }
`

export const SecondCard = styled(DefCard)`
  border-left: none;
  border-top: 1px solid transparent;

  @media (max-width: 1023px) {
    border: 1px solid #9542ff;
  }
`

export const CardsContainer = styled.div`
  display: block;
  position: relative;
  height: 341px;
  width: 188px;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 340px;
    z-index: 1;
  }
`

export const FirstCardFan = styled.div<{ selected: boolean }>`
  > img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 300px;
    z-index: 0;

    opacity: ${(props) => (props.selected ? 1 : 0)};
    transform: ${(props) => (props.selected ? 'rotate(-25deg)' : 'rotate(0deg)')};
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    transform-origin: bottom left;

    @media (max-width: ${Breakpoints.md}) {
      opacity: 0;
    }
  }
`

export const SecondCardFan = styled.div<{ selected: boolean }>`
  > img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 300px;
    z-index: 0;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    transform: ${(props) => (props.selected ? 'rotate(-35deg)' : 'rotate(0deg)')};
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    transform-origin: bottom left;

    @media (max-width: ${Breakpoints.md}) {
      opacity: 0;
    }
  }

  > img:nth-child(2) {
    transform: ${(props) => (props.selected ? 'rotate(-25deg)' : 'rotate(0deg)')};
  }

  > img:nth-child(3) {
    transform: ${(props) => (props.selected ? 'rotate(-15deg)' : 'rotate(0deg)')};
  }
`

export const Image = styled.img<{ selected: boolean }>`
  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.selected ? 1 : 0)};

  @media (max-width: ${Breakpoints.md}) {
    opacity: 1;
  }
`

export const ComingSoon = styled.img<{ width: number; bottom: number; left: number }>`
  position: absolute;
  width: ${(props) => props.width}%;
  left: ${(props) => props.left}px;
  bottom: ${(props) => props.bottom}px;
  z-index: 4;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`
export const ComingSoonTablet = styled.img`
  position: absolute;
  left: 0;
  bottom: 250px;
  width: 100%;
  z-index: 3;

  display: none;
  @media (max-width: ${Breakpoints.lg}) {
    display: block;
  }

  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`
export const ComingSoonResponsive = styled.img`
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  z-index: 4;

  display: none;
  @media (max-width: ${Breakpoints.sm}) {
    display: block;
  }
`

export const PackSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(102.62deg, #101010 0%, #19191b 54.69%, #232327 76.86%, #101010 100%);
  height: 71px;
  width: 382px;
  margin-bottom: 16px;

  @media (max-width: ${Breakpoints.lg}) {
    width: calc(100% - 18px);
    justify-content: space-between;
  }
`

export const PackNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  height: 30px;
`

export const PackNumber = styled.div`
  width: 39px;
  height: 30px;
  background: #36373e;
  margin-left: 21px;
  margin-right: 21px;
`

export const PackPrice = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: right;
  text-transform: uppercase;
  color: #f8fafc;
  margin: auto;
`

export const TextNumber = styled.input`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  background: transparent;
  width: 100%;
  border: 0;
  text-align: center;
  color: #f8fafc;

  -moz-appearance: textfield;
  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Border = styled.div`
  border: 1px solid #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  margin-bottom: 18px;
`

export const ImgLessOrMore = styled.img`
  cursor: pointer;
`
