import styled from 'styled-components/macro'

export const CheckoutModalStyled = styled.div``
export const CheckoutModalContainer = styled.div``

export const CheckoutModalFirstLine = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #f8fafc;
  margin-top: 24px;
`
export const CheckoutModalSeparator = styled.div`
  height: 1px;
  background: #61616b;
  width: 100%;
  margin-top: 8px;
`
export const CheckoutModalSecondLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`
export const CheckoutModalLeft = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`
export const CheckoutModalRight = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;
`

export const CheckoutModalUsd = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: rgba(248, 250, 252, 0.5);
  text-align: right;
`
export const CheckoutModalHowTo = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  color: rgba(248, 250, 252, 0.5);
  margin-top: 24px;
  text-align: right;
`
export const CheckoutModalButtonsContainer = styled.div`
  width: 97%;
  margin: 8px auto auto auto;
`

export const CheckoutModalButtonLogo = styled.img``
