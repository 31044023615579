import { useIsWrongChain } from 'hooks/useIsWrongChain'
import * as React from 'react'
import { WrongChainView } from './WrongChain.view'

export const WrongChain = () => {
  const isWrongChain = useIsWrongChain()

  if (isWrongChain) return <WrongChainView />
  return <></>
}
