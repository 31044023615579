import { connectWallet, logout } from 'actions/Wallet.actions'
import { ethers } from 'ethers'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { State } from 'reducers'
import { Image } from 'styles'
import { printPartialAddress } from 'utils'
import { Button } from '../Button/Button.view'
import { Tooltip } from '../Tooltip/Tooltip.view'
import { Hamburger } from './Hamburger/Hamburger.view'
import {
  AddressImage,
  Arrow,
  CollectionLink,
  ConnectedButtonsContainer,
  CustomNavbarButton,
  DesktopContainer,
  DisableBody,
  MenuBarFlex,
  MenuLogo,
  MenuLogoSimple,
  MenuStyled,
  ProfileContentMobile,
  ProfileImage,
  ProfileMobile,
  SubMenu,
  SubMenuContainer,
  SubMenuElement,
  SubMenuElementMobile,
  WalletAddress,
  WalletBalance,
} from './Menu.style'

type MenuViewProps = {
  version: number
}

export const MenuView = ({ version }: MenuViewProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const wallet = useSelector((state: State) => state.wallet)
  const dispatch = useDispatch()

  const history = useHistory()
  const [showing, setShowing] = useState(false)
  const [mobileProfileOpen, setMobileProfileOpen] = useState(false)

  const [subMenuProfile, setSubMenuProfile] = useState(false)
  const myTvToken = useSelector((state: State) => state.myTvToken)

  const connectedComponents = useMemo(() => {
    if (wallet.address) {
      return (
        <ConnectedButtonsContainer
          className="menu-item menu-connected-component"
        >
          <CustomNavbarButton
            onClick={() => {
              setSubMenuProfile(!subMenuProfile)
            }}>
            <ProfileImage src="/images/profile.svg" alt="profile" />
          </CustomNavbarButton>
          <SubMenuContainer
            showing={subMenuProfile}
            onClick={() => {
              setSubMenuProfile((state) => !state)
            }}
            onMouseLeave={() => {
              window.setTimeout(() => {
                setSubMenuProfile(false)
              }, 200)
            }}
          >
            <SubMenuElement
              onClick={() => {
                history.push('/profile')
                setSubMenuProfile(false)
              }}
            >
              Profile
            </SubMenuElement>
            <Image
              alt="Line separator navbar"
              src="/images/line-separator.svg"
              style={version < 1 ? { display: 'none' } : {}}
            ></Image>
            <SubMenuElement
              onClick={() => {
                history.push('/sales-history')
                setSubMenuProfile(false)
              }}
              style={version < 1 ? { display: 'none' } : {}}
            >
              Sales history
            </SubMenuElement>
            <Image
              alt="Line separator navbar"
              src="/images/line-separator.svg"
              style={version < 2 ? { display: 'none' } : {}}
            ></Image>
            <SubMenuElement
              onClick={() => {
                history.push('/settings')
                setSubMenuProfile(false)
              }}
              style={version < 2 ? { display: 'none' } : {}}
            >
              Settings
            </SubMenuElement>
            <Image alt="Line separator navbar" src="/images/line-separator.svg"></Image>
            <SubMenuElement
              onClick={() => {
                setSubMenuProfile(false)
                dispatch(logout())
              }}
            >
              Logout
            </SubMenuElement>
          </SubMenuContainer>
        </ConnectedButtonsContainer>
      )
    } else {
      return (
        <DesktopContainer>
          <div className="menu-item menu-button-connect">
            <Button
              appearance="primary"
              width="190px"
              clickCallback={() => {
                dispatch(connectWallet())
              }}
            >
              CONNECT WALLET{' '}
            </Button>
          </div>
        </DesktopContainer>
      )
    }
  }, [wallet, history, subMenuProfile, version, dispatch])

  const walletBalanceComponent = useMemo(() => {
    if (!(wallet.myTvBalance && myTvToken)) return null
    const myTvBalance: string = ethers.utils.formatUnits(wallet.myTvBalance!, myTvToken.decimals);
    const myTvBalanceReformat: string = myTvBalance.length > 9 ? myTvBalance.substring(0, 9) + '...' : myTvBalance;
    return (
      <Tooltip text={myTvBalance + ' MYTV'}>
        <WalletBalance className="menu-item menu-wallet-balance" style={{ pointerEvents: 'none' }} to="">
          {myTvBalanceReformat}&nbsp;MYTV
        </WalletBalance>
      </Tooltip>
    )
  }, [wallet, myTvToken])

  let walletAddressComponent = useMemo(() => {
    if (!wallet.address) return null
    return (
      <WalletAddress
        className="menu-item menu-wallet-address"
        onClick={() => {
          navigator.clipboard.writeText(wallet.address!)
          enqueueSnackbar('copied', {
            variant: 'success',
            autoHideDuration: 700,
            hideIconVariant: false,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          })
        }}
      >
        {printPartialAddress(wallet.address)}
        <AddressImage alt="Address Logo" src="/images/address.svg" />
      </WalletAddress>
    )
  }, [enqueueSnackbar, wallet.address])

  return (
    <MenuStyled>
      {showing && <DisableBody />}
      <Hamburger showing={showing} setShowing={() => setShowing(!showing)} />
      <MenuBarFlex showing={showing} logged={wallet.address !== ''}>
        <Link className="menu-logo" to="/" onClick={() => setShowing(false)}>
          <MenuLogo alt="logo" src="/logo.svg" />
          <MenuLogoSimple alt="logo" src="/logo-simple.svg" />
        </Link>
      </MenuBarFlex>
      <SubMenu showing={showing}>
        <Link className="menu-item menu-pack" to="/pack" onClick={() => setShowing(false)}>
          PACK
        </Link>
        <Link className="menu-item menu-marketplace" to="/marketplace" onClick={() => setShowing(false)}>
          MARKETPLACE
        </Link>
        <Link className="menu-item menu-challenge" to="/challenge" onClick={() => setShowing(false)}>
          CHALLENGE
        </Link>
        <Link className="menu-item menu-activities" to="/activities" onClick={() => setShowing(false)}>
          ACTIVITIES
        </Link>
        <a
          className="menu-item menu-blog"
          href="https://mytvchain.medium.com/"
          onClick={() => setShowing(false)}
          target="_blank"
          rel="noreferrer"
        >
          BLOG
        </a>
        <Link className="menu-item menu-tutorials" to="/tutorials" onClick={() => setShowing(false)}>
          TUTORIALS
        </Link>
        <CollectionLink className="menu-item" to="/collection/sloeb" onClick={() => setShowing(false)}>
          <div>s.loeb</div>
        </CollectionLink>
        {walletBalanceComponent}
        {walletAddressComponent}
        {!showing && connectedComponents}
        {wallet.address ? (
          <>
            <ProfileMobile className="menu-item" onClick={() => setMobileProfileOpen(!mobileProfileOpen)}>
              <span>Profile</span>
              <Arrow opened={mobileProfileOpen}>
                <img alt="arrow-up" src="/images/collection/arrow-up.svg" width="18" />
              </Arrow>
            </ProfileMobile>
            <ProfileContentMobile profileOpen={mobileProfileOpen}>
              <SubMenuElementMobile
                onClick={() => {
                  history.push('/profile')
                  setMobileProfileOpen(false)
                  setShowing(false)
                }}
              >
                Profile
              </SubMenuElementMobile>
              <img src="/images/submenu_separator.svg" alt="" style={version < 1 ? { display: 'none' } : {}} />
              <SubMenuElementMobile
                onClick={() => {
                  history.push('/sales-history')
                  setMobileProfileOpen(false)
                  setShowing(false)
                }}
                style={version < 1 ? { display: 'none' } : {}}
              >
                Sales history
              </SubMenuElementMobile>
              <img src="/images/submenu_separator.svg" alt="" style={version < 2 ? { display: 'none' } : {}} />
              <SubMenuElementMobile
                onClick={() => {
                  history.push('/settings')
                  setMobileProfileOpen(false)
                  setShowing(false)
                }}
                style={version < 2 ? { display: 'none' } : {}}
              >
                Settings
              </SubMenuElementMobile>
              <img src="/images/submenu_separator.svg" alt="" />
              <SubMenuElementMobile
                onClick={() => {
                  setSubMenuProfile(false)
                  setShowing(false)
                  dispatch(logout())
                }}
              >
                Logout
              </SubMenuElementMobile>
            </ProfileContentMobile>
          </>
        ) : (
          <ProfileMobile>
            <div className="menu-item menu-button-connect">
              <Button
                appearance="primary"
                width="190px"
                clickCallback={() => {
                  dispatch(connectWallet())
                }}
              >
                CONNECT WALLET{' '}
              </Button>
            </div>
          </ProfileMobile>
        )}
      </SubMenu>
    </MenuStyled>
  )
}
