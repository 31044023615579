import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

import { ButtonStyled } from '../Button/Button.style'

export const FooterStyled = styled.div`
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  background: #232327;
  backdrop-filter: blur(24px);
`

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 120px;
  margin-left: 120px;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    margin-right: 32px;
    margin-left: 32px;
  }
`

export const RightPart = styled.div`
  margin-bottom: 24px;
  margin-top: 30px;

  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 32px;
    margin-top: 0px;
  }
`

export const LeftPart = styled.div`
  margin-bottom: 24px;
  margin-top: 54px;

  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 16px;
    margin-top: 0px;
  }
`

export const FooterLogo = styled.img`
  height: 40px;
  margin: 5px 0px 5px 0px;
  width: 215px;
  display: block;

  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
`

export const FooterLogoBar = styled.img`
  height: 40px;
  margin: 5px 0px 5px 0px;
  width: 215px;
  display: block;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const FooterLogoBarResponsive = styled.img`
  height: 40px;
  margin: 5px 0px 5px 0px;
  width: 215px;
  display: block;

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 56px;
`

export const EmailContainer = styled.div`
  display: flex;
  justify-content: left;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 85px;
`

export const Title = styled.div`
  margin-bottom: 18px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #f8fafc;
`

export const TextLink = styled.a`
  font-family: Circe;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
`

export const Copyright = styled.div`
  margin-top: 50px;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(248, 250, 252, 0.5);

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const CopyrightResponsive = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(248, 250, 252, 0.5);

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const EmailInput = styled.input`
  position: relative;
  z-index: 2;
  background: #17171a;
  width: 480px;
  height: 48px;
  padding: 0px 15px 0px 15px;
  max-width: 100%;
  border: 0px;
  margin-bottom: 24px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  ::placeholder {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(248, 250, 252, 0.5);
  }

  @media (max-width: ${Breakpoints.xl}) {
    width: 330px;
    font-size: 14px;
  }

  @media (max-width: 950px) {
    width: 200px;
  }

  @media (max-width: ${Breakpoints.md}) {
    width: 326px;
    font-size: 16px;
    margin-bottom: 16px;
  }
`

export const ButtonInside = styled.div`
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(21deg);
`

export const RowContainerSocialLink = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 56px;
  height: 15%;
`

export const SocialLink = styled.a`
  height: 100%;
  background-color: #232327;
  margin-right: 32px;
`

export const TextLink2 = styled.a`
  font-family: Circe;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 21px;
  margin-right: 24px;
`

export const ButtonSignUp = styled(ButtonStyled)`
  width: 160px;
  min-width: 0px;
  height: 48px;

  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.xl}) {
    width: 120px;
    font-size: 14px;
  }

  @media (max-width: 950px) {
    width: 77px;
    font-size: 11px;
  }

  @media (max-width: ${Breakpoints.md}) {
    width: 300px;
    font-size: 16px;
    margin-bottom: 48px;
  }
`

export const PolicyContainer = styled.div`
  display: flex;
  justify-content: left;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const PolicyContainerResponsive = styled.div`
  display: flex;
  justify-content: left;

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const InputsContainer = styled.div`
  position: relative;
`

export const EmailInputSkew = styled(EmailInput)`
  position: absolute;
  z-index: 1;
  right: -11px;
  pointer-events: none;
  transform: skew(-21deg, 0);
  width: 40px;
`
