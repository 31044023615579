import { Button } from '../Button/Button.view'
import {
  CheckoutModalButtonsContainer,
  CheckoutModalContainer,
  CheckoutModalFirstLine,
  CheckoutModalHowTo,
  CheckoutModalLeft,
  CheckoutModalRight,
  CheckoutModalSecondLine,
  CheckoutModalSeparator,
  CheckoutModalStyled,
  CheckoutModalUsd,
} from './CheckoutModal.style'

interface CheckoutModalViewProps {
  version: number
  type: 'booster' | 'card'
  number: number
}

export const CheckoutModalView = ({ version, type, number }: CheckoutModalViewProps) => {
  //let usdPrice = 10
  return (
    <CheckoutModalStyled>
      <CheckoutModalContainer>
        <CheckoutModalFirstLine>Total</CheckoutModalFirstLine>
        <CheckoutModalSeparator />
        <CheckoutModalSecondLine>
          <CheckoutModalLeft>
            {type === 'booster' ? 'BOOSTER - 3 CARDS' : 'PACK - 1 CARD'} x {number}
          </CheckoutModalLeft>
          <CheckoutModalRight>{type === 'booster' ? '...' : '...'} MYTV</CheckoutModalRight>
        </CheckoutModalSecondLine>
        <CheckoutModalUsd>~ ... USD</CheckoutModalUsd>
        <CheckoutModalHowTo>How to use metamask ?</CheckoutModalHowTo>
        <CheckoutModalButtonsContainer>
          <Button appearance={'primary_empty_gray'} width={'100%'} margin={false}>
            BUY
          </Button>
        </CheckoutModalButtonsContainer>
      </CheckoutModalContainer>
    </CheckoutModalStyled>
  )
}
