import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const DropdownStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 122px;

  padding-left: 100px;
  padding-right: 100px;

  background: linear-gradient(102.62deg, #101010 0%, #19191b 54.69%, #232327 76.86%, #101010 100%);

  cursor: pointer;

  @media (max-width: ${Breakpoints.md}) {
    padding: 0 16px;
    margin: 0 -16px;
  }
`

export const DropdownLineArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 300px;
`
export const ArrowTitle = styled.div<{ open: boolean }>`
font-family: 'Termina';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
text-transform: uppercase;
color: #ffffff;
align-self: center;
margin-bottom:10px;
transform: rotate(${(props) => (props.open ? '0deg' : '180deg')});
`

export const Arrow = styled.div<{ open: boolean }>`
  align-self: center;
  cursor: pointer;
  user-select: none;

  transform: rotate(${(props) => (props.open ? '0deg' : '180deg')});
  transition: transform 300ms ease-in-out;
`

export const Title = styled.div`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #ffffff;
  align-self: center;
  text-align: left;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Content = styled.div<{ open: boolean }>`
  display: ${(props) => !props.open && 'none'};
`

export const DropdownLines = styled.img`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
