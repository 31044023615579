import styled from 'styled-components/macro'

export const TooltipStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  &:hover .tooltiptext{
    visibility: visible;
    opacity: 1;
  }
`
export const Tooltiptext = styled.span`
  visibility: hidden;
  background-color: #101010;
  font-family: Circe;
  color: #9542ff;
  text-align: center;
  white-space: nowrap;
  padding: 7px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;

  &:after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #101010 transparent;
  }
`