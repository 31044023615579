import { ComingSoonImg } from 'app/App.components/ComingSoonImg/ComingSoonImg.view'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { Image } from 'styles'

import { IPairOrderNftEntity } from './Activities.controller'
import { ActivityType } from './Activities.enum'
import {
  ActivitiesButton,
  ActivitiesButtonBackground,
  ActivitiesButtonsContainer,
  ActivitiesContainer,
  ActivitiesContainerV0,
  ActivitiesFirstLine,
  ActivitiesGrid,
  ActivitiesGridFirstCase,
  ActivitiesResults,
  ActivitiesSecondLine,
  ActivitiesSelect,
  ActivitiesSelectArrow,
  ActivitiesSelectContainer,
  ActivitiesStyled,
  ActivitiesTh,
  ActivitiesTitle,
  ActivitiesTitleV0,
  NothingToDisplayBlock,
} from './Activities.style'
import { TableLine } from './TableLine/TableLine.controller'

interface IActivitiesViewProps {
  activities: IPairOrderNftEntity[]
  type: ActivityType
  setType: React.Dispatch<React.SetStateAction<ActivityType>>
  deleteLine: (activity: IPairOrderNftEntity) => void
  version: number
}

export const ActivitiesView = ({ activities, type, setType, deleteLine, version }: IActivitiesViewProps) => {
  const wallet = useSelector((state: State) => state.wallet)

  return (
    <ActivitiesStyled>
      {version >= 1 && (
        <>
          <ActivitiesFirstLine>
            <ActivitiesTitle>Activities</ActivitiesTitle>

            <ActivitiesButtonsContainer>
              {version === 2 && (
                <ActivitiesButtonBackground
                  selected={type === ActivityType.AUCTIONS}
                  onClick={() => setType(ActivityType.AUCTIONS)}
                >
                  <ActivitiesButton>Auctions</ActivitiesButton>
                </ActivitiesButtonBackground>
              )}
              <ActivitiesButtonBackground
                selected={type === ActivityType.OFFERS_MADE}
                onClick={() => setType(ActivityType.OFFERS_MADE)}
              >
                <ActivitiesButton>Offers you made</ActivitiesButton>
              </ActivitiesButtonBackground>
              <ActivitiesButtonBackground
                selected={type === ActivityType.OFFERS_RECEIVED}
                onClick={() => setType(ActivityType.OFFERS_RECEIVED)}
              >
                <ActivitiesButton>Offers received</ActivitiesButton>
              </ActivitiesButtonBackground>
            </ActivitiesButtonsContainer>
          </ActivitiesFirstLine>
          {wallet.address && (
            <ActivitiesContainer>
              <ActivitiesSecondLine>
                <ActivitiesResults>{activities.length} results</ActivitiesResults>
                {version === 2 && (
                  <ActivitiesSelectContainer>
                    <ActivitiesSelect defaultValue={0}>
                      <option value={0} disabled>
                        Filter by
                      </option>
                      <option>...</option>
                      <option>...</option>
                      <option>...</option>
                    </ActivitiesSelect>
                    <ActivitiesSelectArrow alt="search icon" src={`/images/marketplace/arrow-down.svg`} />
                    <Image position={'absolute'} top={34} right={24} />
                  </ActivitiesSelectContainer>
                )}
              </ActivitiesSecondLine>
              <ActivitiesGrid>
                <ActivitiesGridFirstCase />
                <ActivitiesTh>{type === ActivityType.AUCTIONS ? 'Latest bid price' : 'Price'}</ActivitiesTh>
                <ActivitiesTh>{type === ActivityType.AUCTIONS ? 'Time left' : 'Date'}</ActivitiesTh>
                <ActivitiesTh>Name</ActivitiesTh>
                <ActivitiesTh>Token ID</ActivitiesTh>
                <ActivitiesTh>Contract address</ActivitiesTh>
                <ActivitiesTh>{type === ActivityType.AUCTIONS ? 'State' : ''}</ActivitiesTh>
                {activities.map((activity) => (
                  <TableLine
                    key={activity.order.orderHash}
                    activity={activity}
                    type={type}
                    deleteLine={deleteLine}
                  ></TableLine>
                ))}
              </ActivitiesGrid>
              {!activities.length && <NothingToDisplayBlock>Nothing to display</NothingToDisplayBlock>}
            </ActivitiesContainer>
          )}
        </>
      )}
      {version === 0 && (
        <>
          <ActivitiesContainerV0>
            <ActivitiesTitleV0>Activities</ActivitiesTitleV0>
          </ActivitiesContainerV0>
          <ComingSoonImg margin={[100, 85]} />
        </>
      )}
    </ActivitiesStyled>
  )
}
