import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { Image } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const DisableBody = createGlobalStyle`
  body{
    overflow: hidden;
  }
`

export const MenuStyled = styled.div`
  font-size: 14px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;

  display: flex;
  width: 100%;
  background: #212125;
  justify-content: space-between;
  padding: 0 96px;
  @media (max-width: ${Breakpoints.xl}) {
    flex-direction: column;
    background: none;
    padding: 0;
  }
`

export const MenuBarFlex = styled.div<{ showing: boolean; logged: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 80px;
  background: #212125;
`

export const SubMenu = styled.div<{ showing: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  @media (max-width: ${Breakpoints.xl}) {
    padding: ${(props) => (props.showing ? '40px 0' : '0')};
    flex-direction: column;
    gap: 40px;
    width: 100%;
    height: ${(props) => (props.showing ? '530px' : '0px')};
    max-height: calc(100vh - 80px);
    transition: all 500ms ease-in-out;
    overflow: ${(props) => (props.showing ? 'scroll' : 'hidden')};
    background: rgba(35, 35, 39, 0.8);
    backdrop-filter: blur(10px);
  }
`

export const ProfileMobile = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f8fafc;

  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: ${Breakpoints.xl}) {
    display: none;
  }
`

export const ProfileContentMobile = styled.div<{ profileOpen: boolean }>`
  display: ${(props) => (props.profileOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  gap: 26px;
`

export const SubMenuElementMobile = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  color: #f8fafc;
`
export const Arrow = styled.div<{ opened: boolean }>`
  width: 24px;
  transform: ${(props) => (props.opened ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: all 300ms ease-in-out;
`

export const MenuLink = styled(Link)`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f8fafc;
`

export const MenuLogo = styled.img`
  height: 40px;
  margin: 5px 0px 5px 10px;
  width: 215px;

  @media (max-width: ${Breakpoints.xxl}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.xl}) {
    display: block;
    height: 40px;
    margin: 5px 0px 5px 10px;
    width: 150px;
  }
`

export const MenuLogoSimple = styled.img`
  height: 40px;
  margin: 5px 20px 5px 10px;

  @media (min-width: ${Breakpoints.xxl}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.xl}) {
    display: none;
  }
`

export const ConnectButton = styled.button`
  font-family: Circe;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;

  border: 0;
  transform: skew(-21deg);
  background: linear-gradient(95.19deg, #4934c3 5.74%, #3ad8ed 100%);

  align-self: center;
  height: 48px;
  cursor: pointer;

  width: 100%;
  margin: auto;

  @media (max-width: 767px) {
    display: none;
  }
`

export const ConnectButtonText = styled.div`
  transform: skew(21deg);
`

export const ConnectedButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 37px;

  @media (max-width: 767px) {
    display: none;
  }
`

export const CustomNavbarButton = styled.div`
  cursor: pointer;
  transition: transform .1s cubic-bezier(1, 0.25, 0.25, 1.2);
  filter: contrast(0.8);

  &:hover {
    filter: contrast(1) drop-shadow(0px 6px 6px rgba(20, 20, 20, .2));
    transform-origin: center center;
    transform: scale(1.05);
  }
`

export const SubMenuContainer = styled.div<{ showing: boolean }>`
  position: absolute;
  width: 135px;
  background: #232327;
  top: 80px;
  display: ${(props) => (props.showing ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translatex(-90px);
  padding: 20px 16px;

  @media (max-width: 1023px) {
    top: 160px;
    transform: translateX(-30px);
  }

  @media (max-width: 767px) {
    top: 80px;
  }
`

export const SubMenuElement = styled.div`
  width: 100%;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;

  color: #f8fafc;

  padding: 8px 0px;

  cursor: pointer;
`

export const WalletBalance = styled(Link)`
  font-family: Circe !important;
  color: #9542ff !important;
  white-space: nowrap;
`

export const WalletAddress = styled.a`
  display: flex;
  cursor: pointer;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px !important;
  color: #f8fafc;
  user-select: none;

  @media (max-width: 767px) {
    display: none;
  }
`

export const AddressImage = styled(Image)`
  margin: auto !important;
  padding-left: 10px;
`

export const ProfileImage = styled(Image)`
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }
`

export const CollectionLink = styled(Link)`
  background: #79f593;
  border-radius: 40px;
  padding: 8px 16px;
  > div {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #294d42;
  }
  @media (max-width: ${Breakpoints.xl}) {
    margin-right: 20px;
    margin-left: 20px;
  }
  @media (max-width: ${Breakpoints.xl}) {
    margin-right: 20px;
    margin-left: 20px;
  }

  @media (max-width: ${Breakpoints.md}) {
    > div {
      font-size: 11px;
    }
  }
`

export const DesktopContainer = styled.div`
  @media (max-width: ${Breakpoints.xl}) {
    display: none;
  }
`

export const MobileContainer = styled.div`
  @media (min-width: ${Breakpoints.xl}) {
    display: none;
  }
`
