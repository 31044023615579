import { SET_VERSION } from "actions/Version.actions"

export interface VersionState {
  number: number
  debug: boolean
}
const versionInitialState: VersionState = {
  number: 0,
  debug: false
}

export function version(state = versionInitialState, action: any): VersionState {
  switch (action.type) {
    case SET_VERSION:
      return {
        ...state,
        number: action.number,
      }
    default:
      return state
  }
}
