import styled from 'styled-components/macro'

export const CardActionUpAuctionLineRight = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: right;

  color: rgba(248, 250, 252, 0.5);
`

export const CardActionUpAuctionLineLeft = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const CardActionUpAuctionFees = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const CardActionUpAuctionButtonContainer = styled.div`
  margin: 56px auto 5px auto;
  width: 250px;
  display: flex;
  justify-content: center;
`

export const CardActionUpAuctionContainer = styled.div`
  margin-top: 30px;
`
