import { API_ERROR, enqueueError } from 'actions/Errors.actions'
import { store } from 'index'

export default abstract class BaseService {
  public static async get(...args: any[]): Promise<any> {
    throw new Error('Method not implemented')
  }
  public async post(): Promise<any> {
    throw new Error('Method not implemented')
  }
  public async put(): Promise<any> {
    throw new Error('Method not implemented')
  }

  protected static async httpGet(url: URL): Promise<any> {
    const response = await BaseService.callHandler(() => fetch(`${url.toString()}`, { method: 'GET' }))
    if (!response) return
    return response.json()
  }
  protected static async httpGetPagination(url: URL): Promise<any> {
    const response = await BaseService.callHandler(() => fetch(`${url.toString()}`, { method: 'GET' }))
    if (!response) return
    let data: any[] = []
    let totalItems = 0

    await response.json().then(async (jsonData: any[]) => {
      totalItems = parseInt(response.headers.get('Pagination-Total-Items') as string) ?? data.length ?? 0
      data = jsonData ?? []
    })
    return {
      totalItems,
      data,
    }
  }
  protected static async httpPost(url: URL, params: object): Promise<any> {
    const response = await BaseService.callHandler(() =>
      fetch(`${url.toString()}`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
    if (!response) return
    return response.json()
  }
  protected static async httpPut(url: URL, params: object): Promise<any> {
    const response = await BaseService.callHandler(() =>
      fetch(`${url.toString()}`, {
        method: 'PUT',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
    if (!response) return
    return response.json()
  }

  private static async callHandler(fetchCall: () => Promise<Response>): Promise<Response | undefined> {
    try {
      const response = await fetchCall()
      // if (!response.ok) {
      //   await response.clone().text().then((text) =>
      //     store.dispatch(
      //       enqueueError({
      //         type: API_ERROR,
      //         payload: { message: response.status + ' ' + response.statusText, errors: response },
      //       }),
      //     ),
      //   )
      // }
      return response
    } catch (e) {
      store.dispatch(enqueueError({ type: API_ERROR, payload: { message: "Can't connect to api", errors: e } }))
      return
    }
  }
}
