import { TypedDataDomain } from '@ethersproject/abstract-signer'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'

import { ethers } from 'ethers'
import { store } from 'index'
import * as Webservices from 'Webservices'

export type IProviders =
  | ethers.providers.InfuraProvider
  | ethers.providers.JsonRpcProvider
  | ethers.providers.WebSocketProvider
  | ethers.providers.Web3Provider
export default abstract class BaseContract {
  protected abstract readonly protocol: EProtocol
  protected contractConfig: any | null = null
  protected domain: TypedDataDomain | null = null
  protected address?: string

  public async getContractConfig() {
    if (!process.env.REACT_APP_DEFAULT_CHAINID) throw Error('Missing chainId')
    if (this.contractConfig) return this.contractConfig
    const contractConfig = await Webservices.Contract.get(null, {
      protocol: this.protocol,
      chainId: process.env.REACT_APP_DEFAULT_CHAINID!,
    })
    this.contractConfig = await contractConfig
    if (!this.address && !this.contractConfig.address) throw Error(`Invalid contract address for ${this.protocol}`)
    return this.contractConfig
  }

  public async getSmartContractInstance() {
    const contractConfig = await this.getContractConfig()
    const smartContractAddress = this.address ?? contractConfig.address
    if (!smartContractAddress) throw Error(`Smart contract protocol : ${this.protocol} has no address`)
    const provider = this.getProvider()
    if (!contractConfig) return null
    const smartContractInstance = new ethers.Contract(
      smartContractAddress!,
      contractConfig.abi as ethers.ContractInterface,
      provider,
    )
    return smartContractInstance
  }

  public async getDomain(): Promise<TypedDataDomain> {
    const contractConfig = await this.getContractConfig()
    this.domain = {
      name: contractConfig.name,
      version: contractConfig.version,
      chainId: contractConfig.chainId,
      verifyingContract: contractConfig.address,
    }
    return this.domain
  }

  public getSigner() {
    const wallet = store.getState().wallet
    const signer = wallet?.web3?.getSigner()
    if (!signer) throw Error('Invalid signer')
    return signer
  }

  protected async getSmartContractWithSigner() {
    const signer = this.getSigner()
    const instance = await this.getSmartContractInstance()
    if (!instance) throw Error('Invalid instance')
    const contractWithSigner = instance.connect(signer)
    if (!contractWithSigner) throw Error('Invalid contract with signer')
    return instance.connect(signer)
  }

  protected getProvider() {
    const wallet = store.getState().wallet
    let provider: IProviders | null = wallet?.web3 ?? null
    if (!provider) {
      if (!process.env.REACT_APP_DEFAULT_CHAINID) throw Error('Invalid chainId')
      switch (parseInt(process.env.REACT_APP_DEFAULT_CHAINID)) {
        case 56:
          provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_BSC_RPC_PROVIDER)
          break
        case 4:
          provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RINKEBY_RPC_PROVIDER)
          break
      }
      if (!provider) throw Error('Invalid provider')
    }
    return provider
  }
}
