import { enqueueError, SMARTCONTRACT_ERROR } from 'actions/Errors.actions'
import { OrderEntity } from 'Entities'
import { Abi } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { ISignature } from 'Entities/Whitelist/SignatureEntity'

import { BigNumber, ethers, Transaction } from 'ethers'
import { store } from 'index'
import * as Webservices from 'Webservices'

import Erc20 from './Contracts/Erc20'
import Erc721 from './Contracts/Erc721'
import Marketplace from './Contracts/Marketplace'
import NftCollectionStandard from './Contracts/NftCollectionStandardContract'

type ProviderRpcError = {
  message: string
  code: number
  data?: unknown
}

export default class SmartContractApi {
  public static async getErc20Balance(tokenAddress: string, userAddress: string): Promise<BigNumber | null> {
    try {
      return await new Erc20(tokenAddress).balanceOf(userAddress)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getErc721OwnerOf(tokenAddress: string, tokenId: number) {
    try {
      return await new Erc721(tokenAddress).getErc721OwnerOf(tokenId)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  public static async createOrder(order: OrderEntity) {
    try {
      const marketPlaceContract = new Marketplace()
      const properties = await marketPlaceContract.getOrderProperties()
      const domain = await marketPlaceContract.getDomain()
      const signer = marketPlaceContract.getSigner()
      let approveTx;

      if (!(await marketPlaceContract.getContractConfig()).address) throw Error('Marketplace address not valid')

      switch (order.sellAssetClass) {
        case AssetClass.ERC721:
          approveTx = await this.approveErc721(order.sellTokenAddress, order.sellerAddress)
          if(!approveTx) return
          break
        case AssetClass.ERC20:
          approveTx = await this.approveErc20(order.sellTokenAddress, order.sellerAddress, order.sellTokenAmount)
          if(!approveTx) return
          break
      }

      const orderHash = ethers.utils._TypedDataEncoder.hash(domain, properties, order)
      const orderSignature = await signer._signTypedData(domain, properties, order)
      order.orderHash = orderHash
      order.orderSignature = orderSignature
      return Webservices.Orders.post(order)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return
    }
  }

  public static async acceptOrder(order: OrderEntity) {
    if (!order.buyerAddress) throw Error('Invalid buyer address')
    const overrides: Partial<Transaction> = {}

    switch (order.askAssetClass) {
      case AssetClass.ETH:
        overrides.value = BigNumber.from(order.askTokenAmount)
        return this.executeOrder(order, overrides)
      case AssetClass.ERC20:
        const userBalance = (await this.getErc20Balance(
          order.askTokenAddress,
          order.buyerAddress as string,
        )) as BigNumber
        if (!userBalance) return
        if (userBalance.lt(BigNumber.from(order.askTokenAmount))) {
          store.dispatch(
            enqueueError({
              type: SMARTCONTRACT_ERROR,
              payload: { message: 'Insufficient token balance', errors: order },
            }),
          )
          return
        }
        await this.approveErc20(order.askTokenAddress, order.buyerAddress, order.askTokenAmount)
        return this.executeOrder(order, overrides)
      case AssetClass.ERC721:
        await this.approveErc721(order.askTokenAddress, order.buyerAddress)
        return this.executeOrder(order, overrides)
      default:
        throw Error('Unsupported protocol')
    }
  }

  public static async cancelOrder(order: OrderEntity) {
    try {
      const tokenAddress = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenAddress : order.askTokenAddress
      const tokenId = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenId : order.askTokenId
      const tx = await new Marketplace().cancelOrder(order)
      await tx.wait()
      await Webservices.Erc721.Tokens.synchronize(null, { tokenAddress, tokenId, force: 1 })
      return tx
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  private static async executeOrder(order: OrderEntity, overrides: Partial<Transaction>) {
    try {
      const tokenAddress = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenAddress : order.askTokenAddress
      const tokenId = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenId : order.askTokenId
      const tx = await new Marketplace().executeOrder(order, overrides)
      await tx.wait()
      await Webservices.Erc721.Tokens.synchronize(null, { tokenAddress, tokenId, force: 1 })
      return tx
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  private static async approveErc20(tokenAddress: string, owner: string, amount: string) {
    try {
      const erc20 = new Erc20(tokenAddress)
      const allowance = await erc20.getAllowance(owner)
      if (allowance.lt(BigNumber.from(amount))) {
        const tx = await erc20.approve(BigNumber.from(amount))
        await tx.wait()
        return tx
      }
      return allowance
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  private static async approveErc721(tokenAddress: string, owner: string) {
    try {
      const erc721 = new Erc721(tokenAddress)
      const isApprovedForAll = await erc721.isApprovedForAll(owner)
      if (!isApprovedForAll) {
        const tx = await erc721.setApprovedForAll(true)
        await tx.wait()
        return tx
      }
      return isApprovedForAll
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  public static async getMaxTotalSupply(tokenAddress: string, abi: Abi)  {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).maxTotalSupply()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  public static async getTotalSupply(tokenAddress: string, abi: Abi)  {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).totalSupply()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
    }
  }

  public static async getPublicSaleStatus(tokenAddress: string, abi: Abi) : Promise<boolean | null> {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).publicSaleStatus()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getPrivateSaleStatus(tokenAddress: string, abi: Abi)  : Promise<boolean | null>  {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).privateSaleStatus()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getNftStandardUserBalance(tokenAddress: string, abi: Abi,  tokenId: number) {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).balanceOfUser(tokenId)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getPrivateSaleHardcap(tokenAddress: string, abi: Abi)  : Promise<BigNumber | null>{
    try {
      return await new NftCollectionStandard(tokenAddress, abi).privateSaleHardcap()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getMaxPublicSaleMintPerTx(tokenAddress: string, abi: Abi)  : Promise<BigNumber| null>{
    try {
      return await new NftCollectionStandard(tokenAddress, abi).maxPublicSaleMintPerTx()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getMaxPrivateSaleMintPerTx(tokenAddress: string, abi: Abi)  : Promise<BigNumber | null>{
    try {
      return await new NftCollectionStandard(tokenAddress, abi).maxPrivateSaleMintPerTx()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getTotalSoldInPrivateSale(tokenAddress: string, abi: Abi)  {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).totalSoldInPrivateSale()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getTotalSoldInPublicSale(tokenAddress: string, abi: Abi)  {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).totalSoldInPublicSale()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getPrivateSalePrice(tokenAddress: string, abi: Abi)  : Promise<BigNumber | null> {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).privateSalePrice()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getPrivateSalePhaseId(tokenAddress: string, abi: Abi)  : Promise<number | null> {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).privateSalePhaseID()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getPublicSalePrice(tokenAddress: string, abi: Abi)  : Promise<BigNumber | null>{
    try {
      return await new NftCollectionStandard(tokenAddress, abi).publicSalePrice()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getQuantityAvailableForSale(tokenAddress: string, abi: Abi)  {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).availableForSale()
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async getUserPrivateSaleMintedAmountByPhaseId(tokenAddress: string,  userAddress: string, phaseId: number, abi: Abi,) {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).addressToPrivateSalePhaseToMintingAmount(userAddress, phaseId)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async mintPublicSale(tokenAddress: string, abi: Abi,  amount: BigNumber, price: BigNumber) {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).mintPublicSale(amount, price)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }

  public static async mintPrivateSale(tokenAddress: string, abi: Abi, signature: ISignature, amount: BigNumber, price: BigNumber) {
    try {
      return await new NftCollectionStandard(tokenAddress, abi).mintPrivateSale(signature, amount, price)
    } catch (e) {
      if (typeof e === 'object') {
        const providerError = e as ProviderRpcError
        store.dispatch(
          enqueueError({
            type: SMARTCONTRACT_ERROR,
            payload: { message: providerError.message, errors: providerError },
          }),
        )
      }
      return null
    }
  }
}
