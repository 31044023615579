import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const OpeningPackModalStyled = styled.div`
  backdrop-filter: blur(10px);
  overflow: visible;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2em 4em;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoints.lg}) {
    padding: 1em;
  }

  &.isOpen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const OpeningPackModalCross = styled.span`
  position: absolute;
  top: 100px;
  right: 60px;
  cursor: pointer;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #f8fafc;
  z-index: 3;
`

export const OpeningPackModalVideo = styled.video`
  max-width: 60vw;
  border-radius: 6px;
  box-shadow: 0 6px 12px 12px rgba(20, 20, 20, 0.2);

  @media (max-width: ${Breakpoints.lg}) {
    max-width: 100vw;
  }
`

export const OpeningPackModalTokenVideo = styled.video`
  max-height: 60vh;
  max-width: 90vw;
  border-radius: 6px;
  animation-name: opening-animation-video;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.19, 0.1, 0.22, 1.25);
  overflow: visible;
  opacity: 1;

  /*
    This animation is different than the image animation for one main reason:
    Video are heavier and take longer to load, the 0 to 80% at opacity 0
    gives some time to the user for loading the video, and for the browser to
    display the video element. Then after this delay has passed we start playing
    the actual animation.
  */
  @keyframes opening-animation-video {
    0% {
      opacity: 0;
    }

    80% {
      transform: rotateX(5deg) translate(0, -50px) scale(0.6);
      opacity: 0;
    }

    100% {
      transform: rotateX(0deg) translate(0, 0) scale(1);
      opacity: 1;
    }
  }
`

export const OpeningPackModalImage = styled.img`
  max-height: 60vh;
  animation-name: opening-animation;
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.19, 0.1, 0.22, 1.25);
  overflow: visible;
  opacity: 1;
  filter: drop-shadow(0 0 0.75rem rgba(20, 20, 20, 0.2));

  @keyframes opening-animation {
    from {
      transform: rotateX(5deg) translate(0, -10px) scale(0.6);
      opacity: 0;
    }

    to {
      transform: rotateX(0deg) translate(0, 0) scale(1);
      opacity: 1;
    }
  }
`
