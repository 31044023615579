import { NftEntity } from 'Entities'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Filter, FilterType, getOnOfferFilter } from 'utils/Filter'
import { addFiltersAndSortToUrl, getFilterAndSortFromSearchParams } from 'utils/SearchUtils'
import { Sort, Sorts, SortType } from 'utils/Sort'
import * as Webservices from 'Webservices'
import { IQueryString } from 'Webservices/Marketplace/Erc721/Tokens'
import { State } from '../../reducers/index'
import { ProfileView } from './Profile.view'

type IFetchParams = {
  filters: Filter[]
  sort: Sort
}

export const Profile = () => {
  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)
  const [tokens, setTokens] = useState<NftEntity[]>([])
  const [fetchParams, setFetchParams] = useState<IFetchParams>({
    filters: [],
    sort: Sorts[SortType.NO_SORT],
  })
  const [initialLoad, setInitialLoad] = useState(true)
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const history = useHistory()
  const location = useLocation()
  const url = '/profile'

  const getQueryString = React.useCallback((page: number, filters: Filter[], sort: Sort, address: string) => {
    let queryString
    queryString = { page: page, limit: 10 }
    let f = filters.reduce((query, next) => {
      return { ...next.f, ...query }
    }, {})
    let q = filters.reduce(
      (query, next) => {
        return { ...next.q, ...query }
      },
      { ownerAddress: address },
    )
    queryString = { f: f, q: q, ...queryString }
    if (sort) queryString = { sort: sort.sort, ...queryString }
    return queryString
  }, [])

  const getTotalItems = React.useCallback(
    async (filters: Filter[], sort: Sort, address: string) => {
      const total = (await Webservices.Erc721.Tokens.count(null, getQueryString(0, filters, sort, address))).count
      if (!total) return
      setTotalItems(total)
    },
    [getQueryString],
  )

  const fetchTokens = React.useCallback(async (queryString: IQueryString) => {
    const res = await Webservices.Erc721.Tokens.get(null, queryString)
    setTokens((state) => {
      if (!res) return state
      return state.concat(res)
    })
  }, [])

  const loadMore = React.useCallback(
    async (e) => {
      await setLoading(true)
      await fetchTokens(getQueryString(e, fetchParams.filters, fetchParams.sort, wallet.address!))
      await setLoading(false)
    },
    [fetchParams.filters, fetchParams.sort, fetchTokens, getQueryString, wallet.address],
  )

  const initialFetchTokens = React.useCallback(
    async (filters, sort, address: string) => {
      await getTotalItems(filters, sort, address)
      setInitialLoad(false)
    },
    [getTotalItems],
  )

  const changeFilter = React.useCallback(
    (newFilter: Filter) => {
      setTotalItems(0)
      if (newFilter.type === FilterType.ON_OFFER) {
        addFiltersAndSortToUrl(url, history, [getOnOfferFilter(wallet.address!)], fetchParams.sort)
        return
      }
      addFiltersAndSortToUrl(url, history, [newFilter], fetchParams.sort)
    },
    [history, fetchParams.sort, wallet.address],
  )

  // const removeSortAndFilters = React.useCallback(() => {
  //   addFiltersAndSortToUrl(url, history, [], fetchParams.sort)
  // }, [history, fetchParams.sort])

  const changeSort = React.useCallback(
    (sort: SortType) => {
      addFiltersAndSortToUrl(url, history, fetchParams.filters, Sorts[sort])
    },
    [history, fetchParams.filters],
  )

  const synchronizeSloeb = React.useCallback(async (chainId: number) => {
    try {
      const tokenSupports = await Webservices.TokensSupport.get(null, {
        q: { slug: 'sloeb', chainId: chainId },
        sort: { 'metadata.front.order': 1 },
      })
      tokenSupports.forEach((token) => {
        Webservices.Erc721.Tokens.synchronize(null, { tokenAddress: token.address })
        Webservices.Erc721.Tokens.synchronizeOwner(null, { tokenAddress: token.address })
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const resetState = React.useCallback(async () => {
    setTokens([])
    setTotalItems(0)
  }, [])

  const initialLoadData = React.useCallback(async () => {
    setInitialLoad(true)
    resetState()
    if (!process.env.REACT_APP_DEFAULT_CHAINID) return
    if (!nativeToken || !supportedTokens.length || !wallet.address) {
      setInitialLoad(false)
      return
    }
    await synchronizeSloeb(parseInt(process.env.REACT_APP_DEFAULT_CHAINID))
    const search = location.search
    const searchParams = new URLSearchParams(search)
    const { filters: _filters, sort: _sort } = getFilterAndSortFromSearchParams(searchParams, [
      nativeToken,
      ...supportedTokens,
    ])
    await setFetchParams({ filters: _filters, sort: _sort })
    initialFetchTokens(_filters, _sort, wallet.address)
  }, [wallet.address, resetState, synchronizeSloeb, nativeToken, supportedTokens, location.search, initialFetchTokens])

  useEffect(() => {
    initialLoadData()
  }, [initialLoadData])

  return (
    <ProfileView
      version={version}
      tokens={tokens}
      initialLoad={initialLoad}
      totalItems={totalItems}
      loadMore={loadMore}
      filters={fetchParams.filters}
      changeFilter={changeFilter}
      changeSort={changeSort}
      loading={loading}
    />
  )
}
