// prettier-ignore
import { enqueueError, SMARTCONTRACT_ERROR } from 'actions/Errors.actions'
import { updateModal } from 'app/App.components/Modal/Modal.actions'
import { TransactionModal } from 'app/App.components/TransactionModal/TransactionModal.controller'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { ethers } from 'ethers'
import { store } from 'index'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { IParamsPost as OrderParam } from 'Webservices/Marketplace/Orders'

import { MakeOfferView } from './MakeOffer.view'

interface MakeOfferProps {
  owner: string
  collection: string
  item: NftEntity
  callBack?: () => void
}

export const MakeOffer = ({ owner, collection, item, callBack }: MakeOfferProps) => {
  const dispatch = useDispatch()

  const wallet = useSelector((state: State) => state.wallet)

  const orderAskToken = React.useCallback(
    async (amount: string, token: TokenSupportEntity, expirationTimeDays: number) => {
      if (wallet) {
        const sellTokenAmount = ethers.utils.parseUnits(amount, token.decimals!)
        const balance = await SmartContractApi.getErc20Balance(token.address, wallet.address!)
        if (balance && balance.lt(sellTokenAmount)) {
          store.dispatch(
            enqueueError({
              type: SMARTCONTRACT_ERROR,
              payload: { message: 'Insufficient Token balance', errors: {} },
            }),
          )
          return
        }
        dispatch(updateModal('Transaction running', <TransactionModal finished={false} />))
        const expirationTime = new Date()
        expirationTime.setDate(expirationTime.getDate() + expirationTimeDays)
        const order: OrderParam = {
          sellerAddress: wallet.address!,
          askTokenAddress: item.tokenAddress,
          askTokenId: item.tokenId,
          askTokenAmount: '1',
          sellTokenAddress: token.address, //Replace with my tv token address
          sellTokenId: 0,
          sellTokenAmount: sellTokenAmount.toString(),
          startTime: 604411200,
          expirationTime: Math.floor(expirationTime.getTime() / 1000),
          salt: 0,
          sellAssetClass: AssetClass.ERC20,
          askAssetClass: AssetClass.ERC721,
          orderHash: '',
          orderSignature: '',
        }
        const response = await SmartContractApi.createOrder(order)
        if (response) {
          if (callBack) callBack()
          dispatch(updateModal('Transaction finished', <TransactionModal finished />))
        } else {
          dispatch(updateModal('Transaction failed', <TransactionModal finished failed />))
        }
      }
    },
    [wallet, item, dispatch, callBack],
  )

  return <MakeOfferView owner={owner} collection={collection} orderAskToken={orderAskToken} />
}
