import {
  ChallengeComingSoonDesktop,
  ChallengeComingSoonTablet,
  ChallengeComingSoonResponsive,
  ChallengeContainer,
  ChallengeStyled,
  ChallengeTitle,
} from './Challenge.style'

export const ChallengeView = () => {
  return (
    <ChallengeStyled>
      <ChallengeContainer>
        <ChallengeTitle>Challenge</ChallengeTitle>
      </ChallengeContainer>
      <ChallengeComingSoonDesktop src="/images/challenge-coming-soon-desktop.png" alt="" />
      <ChallengeComingSoonTablet src="/images/challenge-coming-soon-tablet.png" alt="" />
      <ChallengeComingSoonResponsive src="/images/challenge-coming-soon-responsive.png" alt="" />
    </ChallengeStyled>
  )
}
