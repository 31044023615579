/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import * as localstorageUtils from 'utils/localstorage'

import { OpeningPackModalCross, OpeningPackModalImage, OpeningPackModalStyled, OpeningPackModalTokenVideo, OpeningPackModalVideo } from './OpeningPackModal.style'

type OpeningPackModalViewProps = {
  openingVideo: string
  tokenId: number
  tokenAddress: string
  tokenUri: string
  onClose: () => void
  onVideoEnded?: () => void
}

export const OpeningPackModalView = ({
  openingVideo,
  onClose,
  tokenId,
  tokenAddress,
  tokenUri,
  onVideoEnded,
}: OpeningPackModalViewProps) => {
  const [isBlisterAlreadyOpened, setBlisterIsAlreadyOpened] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if click in the blurred background
       */
      function handleClickOutside(event: any) {
        if (ref.current && ref.current === event.target) {
          onCloseHandler()
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  function keydownHandler(key: KeyboardEvent) {
    if (key.key === 'Escape') {
      onCloseHandler()
    }
  }

  function videoEndedHandler() {
    localstorageUtils.addOpenedBlister(tokenAddress, tokenId)
    setBlisterIsAlreadyOpened(true)

    if (onVideoEnded) {
      onVideoEnded()
    }
  }

  function onCloseHandler() {
    videoEndedHandler()
    onClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler, false)

    return () => {
      document.removeEventListener('keydown', keydownHandler, false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler, false)

    return () => {
      document.removeEventListener('keydown', keydownHandler, false)
    }
  }, [])

  useEffect(() => {
    const element = document.getElementById('video')
    if (element) {
      element.addEventListener('ended', videoEndedHandler, false)
    }

    return () => {
      const element = document.getElementById('video')
      if (element) {
        element.removeEventListener('ended', videoEndedHandler, false)
      }
    }
  }, [tokenId])

  useEffect(() => {
    const isOpened = localstorageUtils.isBlisterAlreadyOpened(tokenAddress, tokenId)

    setBlisterIsAlreadyOpened(isOpened)
  }, [tokenAddress, tokenId])

  function displayImageOrVideo(uri: string) {
    const isMp4 = uri.endsWith('.mp4')

    return isMp4 ? (
      <OpeningPackModalTokenVideo preload="auto" autoPlay muted loop>
        <source src={tokenUri} type="video/mp4" />
      </OpeningPackModalTokenVideo>
    ) : (
      <OpeningPackModalImage src={uri} />
    )
  }

  return (
    <OpeningPackModalStyled ref={wrapperRef}>
      <OpeningPackModalCross
          onClick={() => {
            onCloseHandler()
          }}
        >
          <svg width={14} height={14}>
            <use xlinkHref="/images/sprites.svg#cross" />
          </svg>
        </OpeningPackModalCross>

        {isBlisterAlreadyOpened && displayImageOrVideo(tokenUri)}
        {!isBlisterAlreadyOpened && (
          <OpeningPackModalVideo id="video" preload="auto" autoPlay muted>
            <source src={openingVideo} type="video/mp4" />
          </OpeningPackModalVideo>
        )}
    </OpeningPackModalStyled>
  )
}
