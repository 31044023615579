import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const DropdownFanStyled = styled.div`
  > img {
    width: 100%;
  }
  display: flex;
  margin-bottom: var(--collection-margin);
  justify-content: space-around;
  flex-wrap: wrap;
  gap: var(--collection-margin);

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: 64px;
    margin-bottom: 64px;
    gap: 0px;
  }

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 0;
  }
`

export const Text = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  color: #f8fafc;
  font-weight: 300;
`

export const TextBold = styled.span`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #f8fafc;
  font-weight: 700;
`

export const LeftRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column-reverse;
    gap: 32px;
    margin-top: 64px;
  }
`
export const LeftPart = styled.div`
  max-width: 605px;
  text-align: left;

  @media (max-width: ${Breakpoints.md}) {
    text-align: center;
  }
`

export const RightPart = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 32px;
    > img {
      width: 202px;
    }
  }

  > img {
    height: 350px;
  }
`

export const ImgDesktop = styled.img`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const ImgMobile = styled.img`
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`

export const TextFan = styled(Text)`
  margin-top: 16px;
`
