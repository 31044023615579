const SibApiV3Sdk = require('@sendinblue/client')

export default function useSendinblueContactApi() {

  const SendinBlueInstance = new SibApiV3Sdk.ContactsApi()

  if(!process.env.REACT_APP_SENDINBLUE_API_KEY){
    throw new Error('Sendinblue api key missing')
  }
  SendinBlueInstance.setApiKey(SibApiV3Sdk.AccountApiApiKeys.apiKey, process.env.REACT_APP_SENDINBLUE_API_KEY)

  return SendinBlueInstance
}
