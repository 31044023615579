import { WalletState } from 'reducers/wallet'
import { ExplorerUrl } from 'utils/explorer'
import {
  TransactionModalContainer,
  TransactionModalLink,
  TransactionModalLoader,
  TransactionModalStyled,
} from './TransactionModal.style'

interface TransactionModalViewProps {
  version: number
  wallet: WalletState
  finished?: boolean
  failed?: boolean
  transactionHash?: string
}

export const TransactionModalView = ({
  version,
  wallet,
  finished,
  failed = false,
  transactionHash,
}: TransactionModalViewProps) => {
  return (
    <TransactionModalStyled failed={failed}>
      <TransactionModalContainer>
        <TransactionModalLoader
          src={
            finished
              ? `${failed ? '/images/transaction-loader-failed.png' : '/images/transaction-loader-finished.png'}`
              : '/images/transaction-loader.png'
          }
          alt={finished ? 'Transaction finished' : 'Transaction submitted'}
          finished={finished}
        />
        {transactionHash && (
          <TransactionModalLink href={`${ExplorerUrl}/${transactionHash}`} target="_blank">
            View in blockexplorer
          </TransactionModalLink>
        )}
      </TransactionModalContainer>
    </TransactionModalStyled>
  )
}
