import * as React from 'react'
import { VersionState } from 'reducers/version'

// prettier-ignore
import { VersionDebuggerContainer, VersionDebuggerSelect, VersionDebuggerStyled } from './VersionDebugger.style'

type VersionDebuggerViewProps = {
  version: VersionState
  changeVersion: (number: number) => void
}

export const VersionDebuggerView = ({ version, changeVersion }: VersionDebuggerViewProps) => {
  function handleChangeVersion(e: any) {
    changeVersion(e.target.value)
  }
  return (
    <VersionDebuggerStyled>
      <VersionDebuggerContainer>
        <VersionDebuggerSelect onChange={(e) => handleChangeVersion(e)} defaultValue={version.number}>
          <option value="0">V0</option>
          <option value="1">V1</option>
          <option value="2">V2</option>
        </VersionDebuggerSelect>
      </VersionDebuggerContainer>
    </VersionDebuggerStyled>
  )
}
