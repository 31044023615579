import { PremiumSeriesTitle, UniqueNftsTitle } from 'pages/Collection/Collection.style'
import ReactPlayer from 'react-player'

import {
  AttributeCard,
  AttributeImg,
  AttributeLevel,
  AttributesContainer,
  AttributeText,
  AttributeTitle,
  CardsContainer,
  CardSmallTitle,
  CardSmallTitleGold,
  CardText,
  CardTitle,
  CardTitleGold,
  DropdownPremiumCard,
  DropdownPremiumCardGold,
  DropdownPremiumCardGoldBg,
  DropdownPremiumStyled,
  DropdownPremiumSubtext,
  DropdownPremiumSubtextBold,
  FirstPartContainer,
  ImgDesktop,
  ImgMobile,
  LoebStandingContainer,
  UniqueContainer,
  UniqueContainerLeft,
  UniqueContainerRight,
  UniqueContainerRightSubTitle,
  UniqueContainerRightTitle,
} from './DropdownPremium.style'

export const DropdownPremium = () => {
  return (
    <DropdownPremiumStyled>
      <ImgDesktop src="/images/collection/premium_collection.png" alt="" width="100%" />
      <ImgMobile src="/images/collection/premium_collection_mobile.png" alt="" width="100%" />
      <FirstPartContainer>
        <PremiumSeriesTitle>77 Mythical handmade cards</PremiumSeriesTitle>
        <DropdownPremiumSubtext>
          <DropdownPremiumSubtextBold>
            The biggest number of Mythical tier NFT a collection will ever have. That’s the first, and we had to
            celebrate.
          </DropdownPremiumSubtextBold>
          <br />
          <br />
          We have worked for more than 6 months with our team of graphic designers to create modern NFTs that combine
          the most incredible 3D technology to 2004 vintage real highlights videos footage. Collect a real sports memory
          of a superstar champion and become a real MYTV True Fan.
        </DropdownPremiumSubtext>
      </FirstPartContainer>
      <LoebStandingContainer>
        <img src="/images/collection/loeb_standing.png" alt="" />
      </LoebStandingContainer>
      <CardsContainer>
        <DropdownPremiumCard>
          <CardSmallTitle>6 NFTs</CardSmallTitle>
          <CardTitle>“RALLY VICTORY”</CardTitle>
          <CardText>
            Those 6 super legendary NFTs represent Sébastien Loeb’s 2004 victories in Monte Carlo Rally, Swedish Rally,
            Cyprus Rally, Turkey Rally, Deutschland Rally, and Australia Rally.
          </CardText>
        </DropdownPremiumCard>
        <DropdownPremiumCardGold>
          <DropdownPremiumCardGoldBg>
            <CardSmallTitleGold>1 NFT</CardSmallTitleGold>
            <CardTitleGold>“THE TITLE”</CardTitleGold>
            <CardText>
              The masterpiece of this collection. This NFT represents the 2004 title, the birth of a legend!
            </CardText>
          </DropdownPremiumCardGoldBg>
        </DropdownPremiumCardGold>
        <DropdownPremiumCard>
          <CardSmallTitle>70 NFTs</CardSmallTitle>
          <CardTitle>“STAGE VICTORY”</CardTitle>
          <CardText>
            Those 70 legendary NFTs represent Sébastien Loeb’s 70 stage victories during 2004 World Championship
          </CardText>
        </DropdownPremiumCard>
      </CardsContainer>
      <UniqueContainer>
        <UniqueContainerLeft>
          <ReactPlayer
            url={'/images/collection/NFT_PREMIUM_ITALY_GOLD.mp4'}
            playing
            playsinline
            muted
            loop
            width="100%"
            height="100%"
          ></ReactPlayer>
        </UniqueContainerLeft>
        <UniqueContainerRight>
          <UniqueNftsTitle>1927 UNIQUE NFTs</UniqueNftsTitle>
          <UniqueContainerRightTitle>Some of them are common, others are extremely rare!</UniqueContainerRightTitle>
          <UniqueContainerRightSubTitle>
            Each NFT has special animated attributes that can take a variety of forms.You will discover the rarity of
            each attributes which will determine the overall value of your card.
          </UniqueContainerRightSubTitle>
          <AttributesContainer>
            <AttributeCard>
              <AttributeImg src="/images/collection/attributes/rallye.gif" alt="" />
              <AttributeText>Attribute</AttributeText>
              <AttributeTitle>RALLYE</AttributeTitle>
              <AttributeLevel>16 LEVELS OF RALLYE</AttributeLevel>
            </AttributeCard>
            <AttributeCard>
              <AttributeImg src="/images/collection/attributes/gearbox.gif" alt="" />
              <AttributeText>Attribute</AttributeText>
              <AttributeTitle>GEARBOX</AttributeTitle>
              <AttributeLevel>6 LEVELS OF GEARS</AttributeLevel>
            </AttributeCard>
            <AttributeCard>
              <AttributeImg src="/images/collection/attributes/rpm.gif" alt="" />
              <AttributeText>Attribute</AttributeText>
              <AttributeTitle>RPM</AttributeTitle>
              <AttributeLevel>4 LEVELS OF GEARS</AttributeLevel>
            </AttributeCard>
            <AttributeCard>
              <AttributeImg src="/images/collection/attributes/turbo.gif" alt="" />
              <AttributeText>Attribute</AttributeText>
              <AttributeTitle>TURBO</AttributeTitle>
              <AttributeLevel>5 LEVELS OF GEARS</AttributeLevel>
            </AttributeCard>
          </AttributesContainer>
        </UniqueContainerRight>
      </UniqueContainer>
    </DropdownPremiumStyled>
  )
}
