import styled, { css } from 'styled-components/macro'

export const TransactionModalStyled = styled.div<{ failed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

export const TransactionModalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const TransactionModalLoader = styled.img<{ finished?: boolean }>`
  ${(props) => {
    if (!props.finished) {
      return css`
        margin-top: 24px;
        animation: spin 2s linear infinite;
      `
    } else {
      return css`
        margin: 0;
      `
    }
  }}
`

export const TransactionModalLink = styled.a`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-decoration-line: underline;
  text-align: center;
  color: #f8fafc;
  width: 100%;
`
