// prettier-ignore
import * as React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../reducers/index'
import { TransactionModalView } from './TransactionModal.view'

interface TransactionModalProps {
  finished?: boolean
  failed?: boolean
  transactionHash?: string
}
export const TransactionModal = ({ finished, failed, transactionHash }: TransactionModalProps) => {
  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)

  return (
    <TransactionModalView
      version={version}
      wallet={wallet}
      finished={finished}
      failed={failed}
      transactionHash={transactionHash}
    />
  )
}
