import { SET_NATIVE_TOKEN } from 'actions/NativeToken.actions';
import { TokenSupportEntity } from 'Entities';


export type NativeTokenState =  TokenSupportEntity | null
export function nativeToken(state: TokenSupportEntity | null = null, action: {type: string; token: TokenSupportEntity}): NativeTokenState {
  switch (action.type) {
    case SET_NATIVE_TOKEN:
      return action.token
    default:
      return state
  }
}
