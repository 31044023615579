import { connectWallet } from 'actions/Wallet.actions'
import { Button } from 'app/App.components/Button/Button.view'
import { useDispatch } from 'react-redux'
import { Box } from 'styles'
import { getChainData } from 'utils'
import {
  ErrorWalletNotConnectedContainer,
  ErrorWalletNotConnectedImg,
  ErrorWalletNotConnectedLeft,
  ErrorWalletNotConnectedRight,
  ErrorWalletNotConnectedTitle,
  ErrorWalletStyled,
} from './ErrorWallet.style'

export const ErrorWalletView = ({ badChainId }: { badChainId?: boolean }) => {
  const dispatch = useDispatch()
  const network = getChainData(~~process.env.REACT_APP_DEFAULT_CHAINID!).name
  return (
    <ErrorWalletStyled>
      <ErrorWalletNotConnectedContainer>
        <ErrorWalletNotConnectedLeft>
          {!badChainId && (
            <ErrorWalletNotConnectedTitle>
              Please connect wallet to access the functionality
              <Box marginTop={18}>
                <Button
                  appearance={'primary'}
                  width={'221px'}
                  margin={false}
                  clickCallback={() => dispatch(connectWallet())}
                >
                  CONNECT WALLET
                </Button>
              </Box>
            </ErrorWalletNotConnectedTitle>
          )}

          {badChainId && (
            <ErrorWalletNotConnectedTitle>
              Please change to {capitalizeFirstLetter(network)} network to access the app
            </ErrorWalletNotConnectedTitle>
          )}
        </ErrorWalletNotConnectedLeft>
        <ErrorWalletNotConnectedRight>
          <ErrorWalletNotConnectedImg src="/images/activities-not-connected.png" alt="" />
        </ErrorWalletNotConnectedRight>
      </ErrorWalletNotConnectedContainer>
    </ErrorWalletStyled>
  )
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
