import { ReactNode, useState } from 'react'

import {
  SliderContainer,
  SliderContentContainer,
  SliderContentWrapper,
  SliderRadioButton,
  SliderRadioButtonsContainer,
  SliderWrapper,
} from './Slider.style'

interface SliderProps {
  children?: ReactNode
}

export const Slider = ({ children }: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)
  

  const [touchStartY, setTouchStartY] = useState(0)
  const [touchEndY, setTouchEndY] = useState(0)

  const length = 1

  function handleTouchStart(e: any) {
    setTouchStartX(e.targetTouches[0].clientX)
    setTouchStartY(e.targetTouches[0].clientY)
  }

  function handleTouchMove(e: any) {
    setTouchEndX(e.targetTouches[0].clientX)
    setTouchEndY(e.targetTouches[0].clientY)
  }

  function handleTouchEnd() {
    let diffX = touchStartX - touchEndX;
    let diffY = touchStartY - touchEndY;

    // If it's not a scroll up or down
    if(diffY < 50){
      // If the scroll is a scroll to the right superior or inferior to 120px
      if (diffX > 80) {
        if (currentIndex < length) {
          setCurrentIndex(currentIndex + 1)
        }
      } else if(diffX < -80){
        if (currentIndex !== 0) {
          setCurrentIndex(currentIndex - 1)
        }
      }
    }
  }

  return (
    <SliderContainer>
      <SliderWrapper
        onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
        onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => handleTouchEnd()}
      >
        <SliderContentContainer>
          <SliderContentWrapper currentIndex={currentIndex}>{children}</SliderContentWrapper>
        </SliderContentContainer>
      </SliderWrapper>
      <SliderRadioButtonsContainer>
        <SliderRadioButton selected={currentIndex === 0} onClick={() => setCurrentIndex(0)} />
        <SliderRadioButton selected={currentIndex === 1} onClick={() => setCurrentIndex(1)} />
      </SliderRadioButtonsContainer>
    </SliderContainer>
  )
}
