import styled from 'styled-components/macro'

export const WrongChainStyled = styled.div`
  position: fixed;
  z-index: 2;
  top: 100px;
  right: 10px;
`

export const WrongChainContainer = styled.div`
  background: rgba(12, 12, 12, 0.6);
  border: 1px solid #61616b;
  backdrop-filter: blur(44px);
  height: 56px;

  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
`

export const WrongChainText = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const WrongChainCross = styled.svg`
  cursor: pointer;
`
