export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_MODAL_IMG = 'SHOW_MODAL_IMG'
export const UPDATE_MODAL = 'UPDATE_MODAL'

export const showModal = (title: string, children: JSX.Element) => {
  return {
    type: SHOW_MODAL,
    title,
    children,
  }
}

export const showModalImg = (title: string, children: JSX.Element, img: string) => {
  return {
    type: SHOW_MODAL_IMG,
    title,
    children,
    img,
  }
}

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  }
}

export const updateModal = (title: string, children: JSX.Element) => {
  return {
    type: UPDATE_MODAL,
    title,
    children,
  }
}
