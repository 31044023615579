import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export function ScrollToTop() {
  const { pathname } = useLocation()
  const history = useHistory()

  let checkAction = useCallback(() => {
    if (history.action === 'PUSH') {
      window.scrollTo(0, 0)
    }
  }, [history])

  useEffect(() => {
    checkAction()
  }, [pathname, checkAction])

  return null
}
