import { BadgeStyled } from './Badge.style'

export enum BadgeType {
  CORE = 'CORE',
  RARE = 'RARE',
  MYTHICAL = 'MYTHICAL',
  LEGENDARY = 'LEGENDARY',
  NO_BADGE = 'NONE',
}
export interface BadgeProps {
  type: BadgeType
}

export const Badge = ({ type }: BadgeProps) => {
  return <BadgeStyled type={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</BadgeStyled>
}
