import { Button } from 'app/App.components/Button/Button.view'
import { TokenSupportEntity } from 'Entities'
import { ethers } from 'ethers'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { State } from 'reducers'
import { selectorStylesExpirationTime, selectorStylesToken } from 'styles/selectorStyle'
import { daysOptions } from 'utils'
import {
  CardActionBlueBar,
  CardActionFees,
  CardActionInputContainer,
  CardActionLine,
  CardActionLineLeft,
  CardActionLineRight,
  CardActionSeparator,
  CardActionStyled,
  CardActionSubTitle,
  ExpirationTimeContainer,
  ExpirationTimeText,
} from '../../CardAction.style'
import { CardActionInput } from '../../CardActionInput/CardActionInput'
import { CardActionSaleButtonContainer, CardActionSaleContainer } from './Sale.style'

interface CardActionViewProps {
  orderSellToken: (amount: string, token: TokenSupportEntity, expirationTimeDays: number) => void
}

export const SaleView = ({ orderSellToken }: CardActionViewProps) => {
  const [amount, setAmount] = useState('')
  const tokensSupport = useSelector((state: State) => state.supportedTokens)
  const [selectedExpirationDays, setSelectedExpirationDays] = useState<number>(7)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const [selectedToken, setSelectedToken] = useState<TokenSupportEntity | null>(nativeToken)

  const tokenOptions = [
    { value: nativeToken?.symbol, label: nativeToken?.symbol, address: nativeToken?.address },
  ].concat(
    tokensSupport.map((token: TokenSupportEntity) => ({
      value: token?.symbol,
      label: token?.symbol,
      address: token?.address,
    })),
  )

  return (
    <CardActionStyled>
      <CardActionSaleContainer>
        <CardActionSubTitle>Price</CardActionSubTitle>
        <CardActionInputContainer>
          <CardActionInput value={amount} onChange={setAmount} />
          <Select
            options={tokenOptions}
            styles={selectorStylesToken}
            defaultValue={tokenOptions[0]}
            onChange={(e) => setSelectedToken(tokensSupport.filter((token) => token.address === e?.address)[0])}
          />
        </CardActionInputContainer>
        <CardActionBlueBar src="/images/blue-bar.svg" alt="" />
        <CardActionLine>
          <CardActionLineLeft>Expiration time</CardActionLineLeft>
          <ExpirationTimeContainer>
            <Select
              options={daysOptions}
              styles={selectorStylesExpirationTime}
              defaultValue={daysOptions[2]}
              onChange={(e) => {
                setSelectedExpirationDays(e ? e.value : 7)
              }}
            />
            <ExpirationTimeText>day(s)</ExpirationTimeText>
          </ExpirationTimeContainer>
        </CardActionLine>
        <CardActionSeparator />
        <CardActionFees>Fees</CardActionFees>
        <CardActionLine>
          <CardActionLineLeft>Service + Royalties</CardActionLineLeft>
          <CardActionLineRight>7%</CardActionLineRight>
        </CardActionLine>
        <CardActionSaleButtonContainer>
          <Button
            appearance="primary"
            clickCallback={() => orderSellToken(amount, selectedToken!, selectedExpirationDays!)}
            disabled={!amount || ethers.utils.parseUnits(amount, selectedToken?.decimals).lte(0)}
          >
            SELL
          </Button>
        </CardActionSaleButtonContainer>
      </CardActionSaleContainer>
    </CardActionStyled>
  )
}
