import { connectWallet } from 'actions/Wallet.actions'
import { Button } from 'app/App.components/Button/Button.view'
import { TokenSupportEntity } from 'Entities'
import { PhaseStatus } from 'Entities/Marketplace/TokenSupportEntity'
import { BigNumber, ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import { Box } from 'styles'
import CGV from './general_conditions_mytv.pdf'
import {
  BlisterVideoContainer,
  BlisterVideoContainerMobile,
  Border,
  ButtonContainer,
  CheckBox,
  CheckBoxContainer,
  PackComponentStyled,
  PackNumber,
  PackNumberContainer,
  PackNumberInput,
  PackPrice,
  PackSelectionContainer,
  ShadedBox,
  TermsConditionsContainer,
  TermsConditionsLink,
  TermsCondtionsText,
  TextNumber,
} from './PackComponent.style'

interface PackComponentProps {
  tokenSupport: TokenSupportEntity
  videoName?: string
  price: BigNumber | null
  privateSaleHardCap: BigNumber | null
  maxMintablePerTx: BigNumber | null
  publicActive: boolean | null
  privateActive: boolean | null
  mintPublic: (numberOfNftToMint: BigNumber) => Promise<void>
  mintPrivate: (numberOfNftToMint: BigNumber) => Promise<void>
  userPrivateSaleHardcap: BigNumber
  isWhitelisted: boolean
  tokensSoldPrivate: BigNumber | null
}

export const PackComponent = ({
  videoName,
  price,
  privateSaleHardCap,
  maxMintablePerTx,
  publicActive,
  privateActive,
  mintPrivate,
  mintPublic,
  userPrivateSaleHardcap,
  isWhitelisted,
  tokenSupport,
  tokensSoldPrivate,
}: PackComponentProps) => {
  const [shaded, setShaded] = useState(true)
  const [numberOfNftToMint, setNumberOfNftToMint] = useState(BigNumber.from(1))
  const [maxBuyable, setMaxBuyable] = useState(BigNumber.from(0))

  const dispatch = useDispatch()

  const wallet = useSelector((state: State) => state.wallet)

  useEffect(() => {
    setMaxBuyable(BigNumber.from(0))
    if (publicActive && Boolean(maxMintablePerTx)) {
      setMaxBuyable(maxMintablePerTx!)
      return
    }
    if (!privateSaleHardCap || !maxMintablePerTx || !userPrivateSaleHardcap) {
      return
    }
    if (privateActive) {
      setMaxBuyable(
        BigNumber.from(
          Math.min(privateSaleHardCap.toNumber(), maxMintablePerTx.toNumber(), userPrivateSaleHardcap.toNumber()),
        ),
      )
    }
  }, [maxMintablePerTx, privateActive, privateSaleHardCap, publicActive, userPrivateSaleHardcap])

  useEffect(() => {
    if (numberOfNftToMint.eq(BigNumber.from(0))) {
      setShaded(true)
    }
  }, [numberOfNftToMint])

  const addNftToMint = useCallback(() => {
    if (maxBuyable && numberOfNftToMint.eq(maxBuyable)) return
    setNumberOfNftToMint((state) => state.add(BigNumber.from(1)))
  }, [maxBuyable, numberOfNftToMint])

  const removeNftToMint = useCallback(() => {
    if (numberOfNftToMint.eq(1)) return
    setNumberOfNftToMint((state) => state.sub(BigNumber.from(1)))
  }, [numberOfNftToMint])

  let imgName = videoName?.split('.mp4')[0] + '.png'
  return (
    <PackComponentStyled>
      <BlisterVideoContainer>
        <ReactPlayer
          url={'/videos/' + videoName}
          playing
          playsinline
          muted
          loop
          width="180%"
          height="100%"
          style={{ right: '238px', position: 'relative' }}
        />
      </BlisterVideoContainer>
      <BlisterVideoContainerMobile>
        <img src={`/videos/${imgName}`} alt="" />
      </BlisterVideoContainerMobile>
      <Box display="flex" flexDirection="column" alignItems="center">
        <PackSelectionContainer>
          <PackNumberContainer>
            <PackNumberInput type="image" src="/images/collection/less.svg" onClick={() => removeNftToMint()} />
            <PackNumber>
              <TextNumber>{numberOfNftToMint.toString()}</TextNumber>
            </PackNumber>
            <PackNumberInput type="image" src="/images/collection/more.svg" onClick={() => addNftToMint()} />
          </PackNumberContainer>
          <Border />
          <PackPrice>
            {price ? ethers.utils.formatUnits(BigNumber.from(price).mul(numberOfNftToMint)) : '--'} BNB
          </PackPrice>
        </PackSelectionContainer>
        {(publicActive || privateActive) && !wallet.address && (
          <ButtonContainer shaded={false}>
            <Button width="240px" appearance="primary" clickCallback={() => dispatch(connectWallet())}>
              CONNECT WALLET
            </Button>
          </ButtonContainer>
        )}
        {(publicActive || privateActive) && wallet.address && (
          <>
            {isWhitelisted && (
              <ShadedBox shaded={shaded}>
                <TermsConditionsContainer>
                  <CheckBoxContainer>
                    <CheckBox
                      checked={!shaded}
                      type="checkbox"
                      onChange={() => {
                        if (numberOfNftToMint.gt(0) && isWhitelisted && maxBuyable.gt(0)) {
                          setShaded(!shaded)
                        }
                      }}
                    />
                  </CheckBoxContainer>
                  <TermsCondtionsText>
                    By clicking this box you agree the&nbsp;
                    <TermsConditionsLink href={CGV} target="_blank">
                      General Terms &amp; Conditions of Sale
                    </TermsConditionsLink>
                  </TermsCondtionsText>
                </TermsConditionsContainer>
                <ButtonContainer shaded={shaded}>
                  <Button
                    width="240px"
                    appearance="primary"
                    clickCallback={() => {
                      if (publicActive) {
                        mintPublic(numberOfNftToMint)
                        return
                      }
                      if (privateActive) {
                        mintPrivate(numberOfNftToMint)
                        return
                      }
                    }}
                  >
                    BUY NFT
                  </Button>
                </ButtonContainer>
              </ShadedBox>
            )}
            {!isWhitelisted && (
              <ButtonContainer shaded={false}>
                <PackPrice>Your wallet is not eligible</PackPrice>
              </ButtonContainer>
            )}
          </>
        )}
        {!publicActive &&
          !privateActive &&
          tokenSupport?.metadata?.front?.nextPhase !== PhaseStatus.PRIVATE_SALE_1 &&
          !tokensSoldPrivate?.isZero() && (
            <ButtonContainer shaded={false}>
              <PackPrice>MINT CLOSED</PackPrice>
            </ButtonContainer>
          )}
        {!publicActive &&
          !privateActive &&
          (tokenSupport?.metadata?.front?.nextPhase === PhaseStatus.PRIVATE_SALE_1 || tokensSoldPrivate?.isZero()) && (
            <ShadedBox shaded={true}>
              <ButtonContainer shaded={true}>
                <Button width="240px" appearance="primary">
                  NOT OPENED YET
                </Button>
              </ButtonContainer>
            </ShadedBox>
          )}
      </Box>
    </PackComponentStyled>
  )
}
