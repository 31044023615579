
const BLISTER_STORAGE_KEY = 'opened-nft-blisters-list'

export function isBlisterAlreadyOpened(tokenAddress: string, tokenId: number): boolean {
  const identifier = getBlisterIdentifier(tokenAddress, tokenId)
  const openedBlisters = getAlreadyOpenedBlisters()

  return openedBlisters.includes(identifier)
}

export function addOpenedBlister(tokenAddress: string, tokenId: number) {
  const identifier = getBlisterIdentifier(tokenAddress, tokenId)

  setAlreadyOpenedBlisters([...getAlreadyOpenedBlisters(), identifier])
}

function getBlisterIdentifier(tokenAddress: string, tokenId: number): string {
  return `${tokenAddress}/${tokenId}`
}

function getAlreadyOpenedBlisters(): string[] {
  const storageString = localStorage.getItem(BLISTER_STORAGE_KEY) ?? '[]'
  const currentOpenedBlisters = JSON.parse(storageString)

  return currentOpenedBlisters
}

function setAlreadyOpenedBlisters(blisters: string[]) {
  // remove duplicates from the list:
  const setOfBlisters = new Set(blisters)
  const distinctArray = Array.from(setOfBlisters)

  localStorage.setItem(BLISTER_STORAGE_KEY, JSON.stringify(distinctArray))
}