import { ErrorWallet } from 'pages/ErrorWallet/ErrorWallet.controller'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

interface PrivateRouteProps {
  component: JSX.Element
}
const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const wallet = useSelector((state: State) => state.wallet)
  if (!process.env.REACT_APP_DEFAULT_CHAINID) throw Error('Missing chainId')
  const chainId = process.env.REACT_APP_DEFAULT_CHAINID
  if (!wallet.isConnected) return <ErrorWallet />
  if (wallet.chainId!.toString() !== chainId) return <ErrorWallet badChainId />
  return props.component
}

export default PrivateRoute
