import { BigPurpleText } from 'pages/Collection/Collection.style'
import styled from 'styled-components/macro'
import { Image } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const DropdownUtilityStyled = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: var(--collection-margin);
  margin-bottom: var(--collection-margin);

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
`

export const FirstPart = styled.div`
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  max-width: 1036px;
  margin-bottom: var(--collection-margin);

  @media (max-width: ${Breakpoints.lg}) {
    margin-bottom: 64px;
  }
`

export const Banniere = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: fit-content;
  gap: 50px;
  margin-bottom: var(--collection-margin);

  background: linear-gradient(102.62deg, #101010 0%, #19191b 54.69%, #232327 76.86%, #101010 100%);

  padding: 0 100px;

  @media (max-width: ${Breakpoints.lg}) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -16px;
    margin-bottom: 64px;
    gap: 0;
    padding: 24px 0;
  }
`

export const WhatIfLines = styled.img`
  height: 100%;
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const LeftPartBanniere = styled.div`
  max-width: 392px;
  align-self: center;
  text-align: left;

  @media (max-width: ${Breakpoints.lg}) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`

export const RightPartBanniere = styled.div`
  max-width: 604px;
  align-self: center;
  text-align: left;

  @media (max-width: ${Breakpoints.lg}) {
    max-width: 392px;
  }
`

export const SecondPart = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: var(--collection-margin);

  @media (max-width: ${Breakpoints.xl}) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }

  @media (max-width: ${Breakpoints.lg}) {
    margin-bottom: 64px;
  }
`

export const LeftPart = styled.div`
  text-align: left;
  max-width: 604px;
  margin-right: 138px;

  @media (max-width: ${Breakpoints.xl}) {
    margin-right: 0;
  }
`

export const RightPart = styled.div`
  width: 498px;
  height: 280.33px;

  @media (max-width: ${Breakpoints.xl}) {
    margin-bottom: 32px;
  }
`

export const ThirdPartComponent = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.xl}) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`

export const ImageBox = styled.div`
  width: fit-content;
  box-shadow: inset 5px 5px 40px 20px #101010;
`

export const SloebImage = styled(Image)`
  max-width: 539px;
  width: 100%;
  mix-blend-mode: lighten;
`

export const TextContainer = styled.div`
  max-width: 740px;
  text-align: left;
  padding-left: 91px;

  @media (max-width: ${Breakpoints.xl}) {
    padding-left: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--collection-margin);
  align-self: center;

  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`

export const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  align-self: center;

  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 64px;
  }

  @media (min-width: ${Breakpoints.sm}) {
    display: none;
  }
`

export const Table = styled.div`
  max-width: 1240px;
  background: linear-gradient(102.62deg, #101010 0%, #19191b 54.69%, #232327 76.86%, #101010 100%);
`

export const Row = styled.div<{ borderBottom?: boolean; height?: number }>`
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${(props) => (props.borderBottom ? '1px solid #36373E;' : '')};
  @media (max-width: ${Breakpoints.md}) {
    height: 120px;
  }
  @media (max-width: ${Breakpoints.sm}) {
    height: ${(props) => (props.height ? props.height : 65)}px;
    gap: 50px;
  }
`

export const Element = styled.div`
  max-width: 413px;
  width: 25vw;
  @media (max-width: ${Breakpoints.sm}) {
    text-align: center;
    max-width: 130px;
    width: 100%;
  }
`

export const ElementLeft = styled.div`
  max-width: 413px;
  width: 25vw;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}) {
    justify-self: center;
    text-align: center;
    max-width: 358px;
    width: 100%;
    padding: 32px;
  }
`

export const Title = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  text-align: center;
  text-transform: uppercase;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const TitleTermina = styled.div`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(90.65deg, #a2a2a2 7.3%, #e7e7e7 27.18%, #ffffff 35.63%, #b8b8b8 46.07%, #b8b8b8 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const TitlePremium = styled(TitleTermina)`
  background: linear-gradient(90.65deg, #be9b4b 7.3%, #fbe999 27.18%, #ffffff 35.63%, #ffffc0 46.07%, #d0b463 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`

export const TitleFan = styled(Title)`
  background: linear-gradient(90.65deg, #a2a2a2 7.3%, #e7e7e7 27.18%, #ffffff 35.63%, #b8b8b8 46.07%, #b8b8b8 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`

export const TitlePremiumMobile = styled(TitlePremium)`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TitleFanMobile = styled(TitleFan)`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TitlePrizeTable = styled.div`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  text-align: left;
  text-transform: uppercase;
  color: white;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TitleFanTable = styled(TitleTermina)`
  font-size: 18px;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const TitlePremiumTable = styled(TitlePremium)`
  font-size: 18px;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TitleWinKind = styled(Title)`
  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
`

export const TitleWinAMoment = styled(BigPurpleText)`
  @media (max-width: ${Breakpoints.md}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const TitleWhatIf = styled(Title)`
  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const SLoebDriving = styled.img`
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`

export const HelicopterContainer = styled.div`
  > div {
    @media (max-width: ${Breakpoints.md}) {
      width: 100% !important;
      margin-bottom: 64px !important;
    }
  }
`
