import styled from 'styled-components'

export const ArrowStyled = styled.span`
  width: 22px;
  height: 10px;

  > svg {
    width: 22px;
    height: 10px;
  }
`