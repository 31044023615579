
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity';
import BaseTokenContract from './BaseContract';

export default class Erc721 extends BaseTokenContract {
  protected protocol: EProtocol = EProtocol.ERC721;
  protected static ctx: Erc721
  
  constructor(address: string) {
    super()
    if (Erc721.ctx?.address === address) return Erc721.ctx
    this.address = address
    Erc721.ctx = this
  }

  public async getErc721OwnerOf(tokenId: number) {
    const instance = await this.getSmartContractInstance();
    if (!instance) return
    return instance["ownerOf"](tokenId);
  }

  public async isApprovedForAll(owner: string) {
    const instance = await this.getSmartContractInstance();
    if (!instance) return
    return instance["isApprovedForAll"](owner, this.getOperator());
  }

  public async setApprovedForAll(approved: boolean ) {
    const contractWithSigner = await this.getSmartContractWithSigner();
    if(!contractWithSigner) return
    return contractWithSigner["setApprovalForAll"](this.getOperator(), approved);
  }

  public getOperator() : string {
    const operator = this.contractConfig?.transferProxy
    if(!operator) throw Error(`Invalid transfer proxy for protocol ${this.protocol}`)
    return operator
  }
}