import { Button } from 'app/App.components/Button/Button.view'
import { TokenSupportEntity } from 'Entities'
import { ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { State } from 'reducers'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { selectorStylesExpirationTime, selectorStylesToken } from 'styles/selectorStyle'
import { daysOptions, formatAddress } from 'utils'
import {
  CardActionBalance,
  CardActionBalanceContainer,
  CardActionBalanceTitle,
  CardActionBlueBar,
  CardActionBold,
  CardActionButtonContainer,
  CardActionContainer,
  CardActionFees,
  CardActionFirstLine,
  CardActionInputContainer,
  CardActionLine,
  CardActionLineLeft,
  CardActionLineRight,
  CardActionSeparator,
  CardActionStyled,
  CardActionSubTitle,
  ExpirationTimeContainer,
  ExpirationTimeText,
} from '../../CardAction.style'
import { CardActionInput } from '../../CardActionInput/CardActionInput'

interface CardActionViewProps {
  owner: string
  collection: string
  orderAskToken: (price: string, token: TokenSupportEntity, expirationTimeDays: number) => void
}

export const MakeOfferView = ({ owner, collection, orderAskToken }: CardActionViewProps) => {
  const [price, setPrice] = useState('')
  const tokensSupport = useSelector((state: State) => state.supportedTokens)
  const [selectedToken, setSelectedToken] = useState<TokenSupportEntity | null>(null)
  const [selectedExpirationDays, setSelectedExpirationDays] = useState<number>(7)
  const [balance, setBalance] = useState('0')
  const wallet = useSelector((state: State) => state.wallet)

  const updateBalance = useCallback(
    async (selectedToken: TokenSupportEntity) => {
      if (!wallet.isConnected) return
      const erc20Balance = await SmartContractApi.getErc20Balance(selectedToken?.address, wallet.address!)
      if (!erc20Balance) return
      setBalance(ethers.utils.formatUnits(erc20Balance, selectedToken.decimals))
    },
    [wallet],
  )

  const tokenOptions = tokensSupport.map((token: TokenSupportEntity) => ({
    value: token?.address,
    label: token?.symbol,
  }))

  useEffect(() => {
    if (!selectedToken) return
    updateBalance(selectedToken)
  }, [selectedToken, updateBalance])

  useEffect(() => {
    if (!selectedToken && tokensSupport.length) {
      setSelectedToken(tokensSupport[0])
    }
  }, [selectedToken, tokensSupport])

  return (
    <CardActionStyled>
      <CardActionContainer>
        <CardActionFirstLine>
          You make an offer for <CardActionBold>{formatAddress(owner)}</CardActionBold> by{' '}
          <CardActionBold>{collection}</CardActionBold>
        </CardActionFirstLine>
        <CardActionSubTitle>Your offer</CardActionSubTitle>
        <CardActionInputContainer>
          <CardActionInput value={price} onChange={setPrice} />
          <Select
            options={tokenOptions}
            styles={selectorStylesToken}
            defaultValue={tokenOptions[0]}
            onChange={(e) => setSelectedToken(tokensSupport.filter((token) => token.address === e?.value)[0])}
          />
        </CardActionInputContainer>
        <CardActionBalanceContainer>
          <CardActionBalanceTitle>Your balance</CardActionBalanceTitle>
          <CardActionBalance>
            {balance} {selectedToken?.symbol.toUpperCase()}
          </CardActionBalance>
        </CardActionBalanceContainer>
        <CardActionBlueBar src="/images/blue-bar.svg" alt="" />
        <CardActionLine>
          <CardActionLineLeft>Expiration time</CardActionLineLeft>
          <ExpirationTimeContainer>
            <Select
              options={daysOptions}
              styles={selectorStylesExpirationTime}
              defaultValue={daysOptions[2]}
              onChange={(e) => {
                setSelectedExpirationDays(e ? e.value : 7)
              }}
            />
            <ExpirationTimeText>day(s)</ExpirationTimeText>
          </ExpirationTimeContainer>
        </CardActionLine>
        <CardActionSeparator />
        <CardActionFees>Fees</CardActionFees>
        <CardActionLine>
          <CardActionLineLeft>Service + Royalties</CardActionLineLeft>
          <CardActionLineRight>7%</CardActionLineRight>
        </CardActionLine>
        <CardActionButtonContainer>
          <Button
            appearance="primary"
            clickCallback={() => orderAskToken(price, selectedToken!, selectedExpirationDays!)}
            disabled={!price || ethers.utils.parseUnits(price, selectedToken?.decimals).lte(0)}
          >
            MAKE AN OFFER
          </Button>
        </CardActionButtonContainer>
      </CardActionContainer>
    </CardActionStyled>
  )
}
