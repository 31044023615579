import { ArrowImage, Content, FirstLine, SecondLine, SubInfoStyled, Title } from './SubInfo.style'

interface SubInfoProps {
  title: string
  value?: string
}

export const SubInfo = ({ title, value }: SubInfoProps) => {
  return (
    <SubInfoStyled>
      <FirstLine>
        <ArrowImage src="/images/collection/neon-arrow.png" />
        <Title>{title}</Title>
      </FirstLine>

      {value && (
        <SecondLine>
          <Content>{value}</Content>
        </SecondLine>
      )}
    </SubInfoStyled>
  )
}
