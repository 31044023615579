import { TokenSupportEntity } from 'Entities'

import BaseService from '../BaseService'

type IParamsGet = {} | null

type IQueryString = {
  q?: object
  page?: number
  limit?: number
  sort?: object
}

export default class TokensSupport extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/tokens-support/`
  public static async get(params: IParamsGet, queryString: IQueryString): Promise<TokenSupportEntity[]> {
    const webServiceUrl = new URL(`${TokensSupport.baseUrl}`)
    Object.entries(queryString).forEach(([key, value]) => webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return this.httpGet(webServiceUrl)
  }

}
