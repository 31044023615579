import { BaseEntity } from './BaseEntity'
import { Abi, EProtocol } from './EthContractConfigEntity'

export enum PhaseStatus {
  PRIVATE_SALE_1 = 0,
  PRIVATE_SALE_2 = 1,
  PUBLIC_SALE = 2,
  NONE = 999,
}

export interface IPhase {
  phase: number
  phaseName: string
  myTvchain: string
  staring: string
  mintDate: string
}

export type MetaDataFront = {
  order: number
  emailListId: number
  banner: {
    video: string
    title: string
  }
  serieName: string
  phases: IPhase[]
  pack: {
    video: string
  }
  nextPhase: PhaseStatus

  revealDate: Date
  revealDelay: number
  blister: string
  openingVideo: string
}

type IMetadata = {
  [key: string]: any
  event?: string
  front?: MetaDataFront
  abi?: Abi
}

export default class TokenSupportEntity extends BaseEntity {
  public protocol!: EProtocol

  public name!: string

  public decimals?: number

  public symbol!: string

  public address!: string

  public chainId?: number

  public slug?: string

  public description?: string

  public avatarImage?: string

  public bannerImage?: string

  public featuredImage?: string

  public discordUrl?: string

  public externalUrl?: string

  public twitterUsername?: string

  public mediumUsername?: string

  public instagramUrl?: string

  public telegramUrl?: string

  public metadata?: IMetadata

  public disabled: boolean = false
}
