import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const ComingSoonImgStyled = styled.div<{ margin: [number, number] }>`
  margin-top: ${({ margin }) => margin[0]}px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: ${({ margin }) => margin[1]}px;
  }
`

export const ComingSoonImg = styled.img`
  width: 100%;
`
export const ComingSoonImgDesktop = styled(ComingSoonImg)`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const ComingSoonImgResponsive = styled(ComingSoonImg)`
  display: none;
  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`
