import styled, { css } from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const CardExtendedStyled = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 80px 0 0 0;
  margin-top: 80px;

  padding-left: 144px;
  padding-right: 119px;

  padding-bottom: 50px;

  @media (max-width: ${Breakpoints.lg}) {
    padding: 32px;
    margin-top: 120px;
  }
`

export const FirstPartContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 32px;

  @media (max-width: ${Breakpoints.lg}) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
`

export const LeftPartContainer = styled.div`
  min-width: 260px;
  width: 20%;
  height: fit-to-content;
  @media (max-width: ${Breakpoints.lg}) {
    width: 70%;
    margin-bottom: 50px;
    margin-top: 70px;
  }

  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    padding: 0 32px 64px 32px;
    margin-bottom: 0;
    margin-top: 0;
  }
`

export const RightPartContainer = styled.div`
  width: 80%;
  padding-left: 94px;
  text-align: left;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
    padding: 0;
  }
`

export const CardImage = styled.img`
  width: 100%;
`

export const CardVideo = styled.video`
  width: 100%;
  border-radius: 6px;
`

export const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`
export const CardTitle = styled.p`
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;

  color: #f8fafc;
  padding: 0;
  margin: 0px;

  @media (max-width: ${Breakpoints.lg}) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (max-width: ${Breakpoints.xs}) {
    font-size: 18px;
    line-height: 29px;
  }
`

export const ResponsiveBadgeContainer = styled.div`
  display: none;
  margin-bottom: 16px;
  @media (max-width: ${Breakpoints.lg}) {
    display: flex;
    justify-content: space-between;
  }
`

export const BadgeContainer = styled.div`
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const TopButtonsContainerResponsive = styled.div`
  display: flex;
  gap: 8px;
`
export const TopButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const LinkIcon = styled.img<{ isRefreshing?: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.isRefreshing &&
    css`
      animation: spin linear 1s;
    `};
`
export const OwnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`
export const CardOwner = styled.p`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;

  color: #f8fafc;

  padding: 0;
  margin: 24px 0px 0px 0px;
`

export const FederationType = styled.span`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #f8fafc;
  align-self: center;
  margin-left: 8px;
`

export const OwnerRightSide = styled.div`
  display: flex;
  align-items: center;
`

export const CardText = styled.p`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #f8fafc;

  padding: 0;
  margin: 16px 0px 0px 0px;
`

export const PriceAndButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: ${Breakpoints.md}) {
    flex-wrap: wrap;
  }
`
export const PriceContainer = styled.div``
export const Price = styled.p`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: rgba(248, 250, 252, 0.5);
  margin-bottom: 0;
`
export const PriceInMyTv = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #f8fafc;
  margin: auto;
`
export const ButtonsContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;

  @media (max-width: ${Breakpoints.md}) {
    width: calc(100% - 32px);
    grid-template-columns: calc(60% - 16px) calc(40% - 16px);
    > div {
      &:last-of-type {
        margin: 0 7px 0 0;
      }
      &:first-of-type {
        margin: 0 0 0 7px;
      }
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    grid-gap: 16px;
    width: calc(100% - 16px);
    grid-template-columns: calc(60% - 8px) calc(40% - 8px);
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 16px;
`

export const DetailsBlock = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 35% 65%;
  margin-bottom: 16px;

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: 100%;
  }
`
export const DetailsName = styled.p`
  margin: 0;
  text-align: left;

  font-family: Circe;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`
export const DetailsValue = styled.p`
  margin: 0;
  text-align: left;

  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const AttributeBlocksSuperiorContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const AttributeBlocksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  padding-top: 16px;

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: repeat(5, 150px);
  }
`

export const MoreAttributesContainer = styled.div`
  margin: 16px 0px 0px 0px;
  text-align: right;
  cursor: pointer;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: rgba(248, 250, 252, 0.8);

  display: none;

  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`

export const TwoAttributeBlocksContainerResponsive = styled.div<{ showing: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-gap: 16px;
  padding-top: 16px;

  ${(props) =>
    props.showing &&
    css`
      display: none;
    `};

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const MoreAttributeBlocksContainerResponsive = styled.div<{ showing: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-gap: 16px;
  padding-top: 16px;

  ${(props) =>
    props.showing &&
    css`
      display: none;
    `};

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const CollectionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 26px;
  @media (max-width: ${Breakpoints.xl}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 0px;
    max-height: 1fr;
    overflow: hidden;
  }

  @media (max-width: ${Breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 0px;
  }

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`

export const CollectionTitle = styled.div`
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  text-align: left;
  color: #f8fafc;
  margin-bottom: 33px;
  margin-top: 80px;
  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`

export const OfferContainer = styled.div`
  margin-top: 67px;
  border: 1px solid #61616b;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

export const OfferTitle = styled.div`
  grid-column: span 4;
  border-bottom: 1px solid #61616b;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #f8fafc;
  padding: 19px 24px;
`

export const OfferHeaderTr = styled.div`
  border-bottom: 1px solid #61616b;
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #f8fafc;
  padding: 21px 24px;
`

export const OfferHeaderTd = styled.span`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f8fafc;
  padding: 14px 24px;
  margin: auto 0;
`

export const OfferCopyLogo = styled.img`
  width: 17px;
  height: 17px;
  margin-left: 10px;
  cursor: pointer;
`

export const ModalError = styled.p`
  margin: 48px 0 0 0;
  text-align: center;
`
