import { SET_SUPPORTED_TOKENS } from 'actions/SupportedTokens.actions'
import { TokenSupportEntity } from 'Entities'

export type SupportedTokensState = TokenSupportEntity[]

const supportedTokensStateInitialState: SupportedTokensState = []

export function supportedTokens(
  state = supportedTokensStateInitialState,
  action: { type: string; tokens: TokenSupportEntity[] },
): SupportedTokensState {
  switch (action.type) {
    case SET_SUPPORTED_TOKENS:
      return action.tokens ? [...action.tokens] : []
    default:
      return state
  }
}
