import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'reducers'
import { setVersion } from 'actions/Version.actions'
import { VersionDebuggerView } from './VersionDebugger.view'

export const VersionDebugger = () => {
  const dispatch = useDispatch()
  const version = useSelector((state: State) => state.version)

  const setVersionCallback = (number: number) => {
    dispatch(setVersion(number))
  }

  return <>{version.debug && <VersionDebuggerView version={version} changeVersion={setVersionCallback} />}</>
}
