import { TokenSupportEntity } from 'Entities'
import { OrderStatus } from 'Entities/Marketplace/OrderEntity'
import { ethers } from 'ethers'
import { useHistory } from 'react-router'
import { printPartialAddress } from 'utils'
import { ExplorerUrl } from 'utils/explorer'
import { ISale } from '../SalesHistory.controller'

import {
  TableLineDescriptive,
  TableLineImg,
  TableLineLogo,
  TableLineSeparator,
  TableLineStyled,
  TableLineTxt,
} from './TableLine.style'

interface TableLineViewProps {
  image: string
  sale: ISale
  buyToken?: TokenSupportEntity
  loading: boolean
}

export const TableLineView = ({ image, sale, buyToken, loading: boolean }: TableLineViewProps) => {
  const date = sale.order.createdAt ? new Date(sale.order.createdAt) : new Date('')
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const year = date.getFullYear() % 1000
  const price = sale.order.sellAssetClass === 1 ? sale.order.sellTokenAmount : sale.order.askTokenAmount
  const history = useHistory()

  return (
    <TableLineStyled>
      <TableLineImg
        src={image}
        alt={sale.token.name}
        onClick={() => history.push(`/assets/${sale.token.tokenAddress}/${sale.token.tokenId}`)}
      />
      <TableLineDescriptive>Price</TableLineDescriptive>
      {buyToken && (
        <TableLineTxt>
          {ethers.utils.formatUnits(price, buyToken.decimals)} {buyToken.symbol}
        </TableLineTxt>
      )}
      {!buyToken && <TableLineTxt></TableLineTxt>}
      <TableLineDescriptive>Date</TableLineDescriptive>
      <TableLineTxt>{`${month}/${day}/${year}`}</TableLineTxt>
      <TableLineDescriptive>Name</TableLineDescriptive>
      <TableLineTxt>{sale.token.name}</TableLineTxt>
      <TableLineDescriptive>Token ID</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(String(sale.token.tokenId))
        }}
      >
        {sale.token.tokenId}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive>Contract address</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(sale.token.tokenAddress)
        }}
      >
        {printPartialAddress(sale.token.tokenAddress)}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive>Sale type</TableLineDescriptive>
      <TableLineTxt>{sale.type}</TableLineTxt>
      <TableLineDescriptive>Tx hash</TableLineDescriptive>
      {sale.order.transactionHash && sale.order.orderStatus === OrderStatus.Executed && (
        <TableLineTxt
          onClick={() => {
            if (!window) return
            window.open(`${ExplorerUrl}/${sale.order.transactionHash}`, '_blank')!.focus()
          }}
        >
          {printPartialAddress(sale.order.transactionHash)}
          <TableLineLogo src="/images/exit.svg" alt="" />
        </TableLineTxt>
      )}
      {sale.order.orderStatus === OrderStatus.Canceled && <TableLineTxt>Canceled</TableLineTxt>}
      {!sale.order.transactionHash && sale.order.orderStatus === OrderStatus.Executed && (
        <TableLineTxt>Not available</TableLineTxt>
      )}

      <TableLineSeparator className="separator" />
    </TableLineStyled>
  )
}
