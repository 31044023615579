import * as React from 'react'
import { Image } from '../../../../styles/components'
import {
  CardDetails,
  CardEvent,
  CardImage,
  CardName,
  CardNumber,
  MarketplaceCardStyled,
  PriceLine,
} from './MarketplaceCardLoading.style'

export const MarketplaceCardLoading = () => {
  return (
    <MarketplaceCardStyled>
      <CardImage className="gradient-background-animation" />
      <CardDetails>
        <CardName className="gradient-background-animation">
          <CardNumber>&nbsp;</CardNumber>
        </CardName>
        <CardEvent className="gradient-background-animation">&nbsp;</CardEvent>
        <Image alt="Card separator" src={`/images/marketplace/card_line.svg`}></Image>
        <PriceLine className="gradient-background-animation">&nbsp;</PriceLine>
        {/* <CardPriceUSD className="gradient-background-animation">&nbsp;</CardPriceUSD> */}
      </CardDetails>
    </MarketplaceCardStyled>
  )
}
