import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { Dispatch } from 'redux'
import * as Webservices from 'Webservices'

export const SET_SUPPORTED_TOKENS = 'SET_SUPPORTED_TOKENS'

export const setSupportedTokens =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!process.env.REACT_APP_DEFAULT_CHAINID) return
    const tokensSupports = await Webservices.TokensSupport.get(null, {
      q: { protocol: EProtocol.ERC20, chainId: process.env.REACT_APP_DEFAULT_CHAINID },
    })
    if (!tokensSupports) return
    dispatch({
      type: SET_SUPPORTED_TOKENS,
      tokens: tokensSupports,
    })
  }
