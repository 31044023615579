import { Button } from 'app/App.components/Button/Button.view'
import { ComingSoonImg } from 'app/App.components/ComingSoonImg/ComingSoonImg.view'
import { MarketplaceCard } from 'app/App.components/Marketplace/MarketplaceCard/MarketplaceCard.controller'
import { MarketplaceCardLoading } from 'app/App.components/Marketplace/MarketplaceCardLoading/MarketplaceCardLoading.view'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { BigNumber } from 'ethers'
import React, { useCallback, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { Filter, Filters, FilterType, getOnOfferFilter } from 'utils/Filter'
import { PriceSorts, Sort, Sorts, SortType } from 'utils/Sort'
import { Image } from '../../styles'
import {
  ArrowImage,
  ButtonContainer,
  CardsContainer,
  DeleteFiltersButton,
  FilterButton,
  FiltersContainer,
  FiltersContainerResponsive,
  FilterSelector,
  FilterSelectorSearch,
  FilterSelectorSearchContainer,
  FilterSelectorSelect,
  FiltersSelectorContainer,
  FilterStatusButton,
  FilterStatusContainer,
  MarketplaceContainer,
  MarketplaceHeader,
  MarketplaceSearch,
  MarketplaceSearchContainer,
  MarketplaceStyled,
  MinMaxContainer,
  MinMaxInput,
  ResponsiveFilterButton,
  ResultsNumber,
} from './Marketplace.style'

interface MarketPlaceViewProps {
  version: number
  tokens: NftEntity[]
  filters: Filter[]
  toggleFilter: (filter: Filter) => void
  removeSortAndFilters: () => void
  changeSort: (sort: SortType) => void
  initialLoad: boolean
  loadMore: (page: number) => void
  totalItems: number
  sort?: Sort
  selectedToken: TokenSupportEntity | null
  setSelectedToken: React.Dispatch<React.SetStateAction<TokenSupportEntity | null>>
  min: string | null
  setMin: React.Dispatch<React.SetStateAction<string | null>>
  max: string | null
  setMax: React.Dispatch<React.SetStateAction<string | null>>
  priceSort: Sort
  setPriceSort: React.Dispatch<React.SetStateAction<Sort>>
  fetchTokensWithTokenFilter: (selectedToken: TokenSupportEntity | null) => void
  loading: boolean
}
export const MarketplaceView = ({
  version,
  tokens,
  filters,
  toggleFilter,
  removeSortAndFilters,
  changeSort,
  initialLoad,
  loadMore,
  totalItems,
  sort,
  selectedToken,
  setSelectedToken,
  min,
  max,
  setMin,
  setMax,
  fetchTokensWithTokenFilter,
  priceSort,
  setPriceSort,
  loading,
}: MarketPlaceViewProps) => {
  const baseMarketplaceImageUrl = '/images/marketplace/'
  const [showing, setShowing] = useState(false)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const wallet = useSelector((state: State) => state.wallet)

  function toggleMobileFilterContainer() {
    if (!showing) {
      window.scrollTo({
        top: 0
      })
    }

    setShowing(!showing)
  }

  const getSortElement = useCallback(() => {
    let isActive = sort?.type !== '' ? true : false
    return (
      <FilterSelector>
        <h3>Sort</h3>
        <FilterSelectorSearchContainer>
          <FilterSelectorSelect
            value={sort?.type}
            placeholder="Sort by"
            onChange={(e) => {
              const sortType = e.target.value as SortType
              changeSort(sortType)
            }}
            active={isActive}
          >
            {Object.keys(Sorts).map((key, index) => (
              <option key={index} value={Sorts[key].type}>
                {Sorts[key].label}
              </option>
            ))}
          </FilterSelectorSelect>
          <ArrowImage alt="search icon" src={`${baseMarketplaceImageUrl}/arrow-down.svg`} />
        </FilterSelectorSearchContainer>
      </FilterSelector>
    )
  }, [changeSort, sort?.type])

  const getPriceElement = useCallback(() => {
    return (
      <FilterSelector>
        <h3>Token</h3>
        <FilterSelectorSearchContainer>
          <FilterSelectorSelect
            value={selectedToken?.address ?? ''}
            placeholder="Token"
            onChange={(e) => {
              if (nativeToken && BigNumber.from(e.target.value).isZero()) {
                setSelectedToken(nativeToken)
                return
              }
              setSelectedToken(supportedTokens.filter((token) => token.address === e.target.value)[0])
            }}
          >
            <option value="" disabled hidden>
              Token
            </option>
            {nativeToken && <option value={nativeToken.address}>{nativeToken.symbol.toUpperCase()}</option>}
            {supportedTokens.map((token, index) => (
              <option key={index} value={token.address}>
                {token.symbol.toUpperCase()}
              </option>
            ))}
          </FilterSelectorSelect>
          <ArrowImage alt="search icon" src={`${baseMarketplaceImageUrl}/arrow-down.svg`} />
        </FilterSelectorSearchContainer>
        <h3>Price</h3>
        <FilterSelectorSearchContainer>
          <FilterSelectorSearchContainer>
            <FilterSelectorSelect
              value={priceSort?.type}
              placeholder="Sort by"
              onChange={(e) => {
                const sortType = e.target.value as SortType
                setPriceSort(PriceSorts[sortType])
              }}
            >
              <option value={Sorts[SortType.NO_SORT].type}>{Sorts[SortType.NO_SORT].label}</option>
              {Object.keys(PriceSorts).map((key, index) => (
                <option key={index} value={PriceSorts[key].type}>
                  {PriceSorts[key].label}
                </option>
              ))}
            </FilterSelectorSelect>
            <ArrowImage alt="search icon" src={`${baseMarketplaceImageUrl}/arrow-down.svg`} />
          </FilterSelectorSearchContainer>
        </FilterSelectorSearchContainer>
        <MinMaxContainer>
          <MinMaxInput
            min="0"
            type={'number'}
            placeholder="Min"
            value={min ?? ''}
            // disabled={!selectedToken}
            onChange={(e) => {
              setMin(e.target.value)
            }}
          ></MinMaxInput>
          à
          <MinMaxInput
            min="0"
            type={'number'}
            placeholder="Max"
            value={max ?? ''}
            // disabled={!selectedToken}
            onChange={(e) => setMax(e.target.value)}
          ></MinMaxInput>
        </MinMaxContainer>
        <ButtonContainer>
          <Button appearance="primary" clickCallback={() => fetchTokensWithTokenFilter(selectedToken)}>
            APPLY
          </Button>
        </ButtonContainer>
      </FilterSelector>
    )
  }, [
    fetchTokensWithTokenFilter,
    max,
    min,
    nativeToken,
    priceSort?.type,
    selectedToken,
    setMax,
    setMin,
    setPriceSort,
    setSelectedToken,
    supportedTokens,
  ])

  const getStatusElement = useCallback(() => {
    let onOfferActive = false
    let onSaleActive = false

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === 'on_offer') {
        onOfferActive = true
      }
      if (filters[i].type === 'on_sale') {
        onSaleActive = true
      }
    }
    return (
      <FilterSelector>
        <h3>Status</h3>
        <FilterStatusContainer>
          <FilterStatusButton onClick={() => toggleFilter(Filters[FilterType.ON_SALE])} active={onSaleActive}>
            On Sale
          </FilterStatusButton>
          <FilterStatusButton
            onClick={() => {
              if (!wallet.address) {
                return
              }
              toggleFilter(getOnOfferFilter())
            }}
            active={onOfferActive}
          >
            On Offer
          </FilterStatusButton>
        </FilterStatusContainer>
      </FilterSelector>
    )
  }, [toggleFilter, wallet.address, filters])

  const getCreatorElement = useCallback(() => {
    return (
      <FilterSelector>
        <h3>Creator</h3>
        <FilterSelectorSearchContainer>
          <FilterSelectorSearch placeholder="Search" icon={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}></FilterSelectorSearch>
        </FilterSelectorSearchContainer>
      </FilterSelector>
    )
  }, [])

  const getCollectionElement = useCallback(() => {
    return (
      <FilterSelector>
        <h3>Collection</h3>
        <FilterSelectorSearchContainer>
          <FilterSelectorSearch placeholder="Search" icon={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}></FilterSelectorSearch>
        </FilterSelectorSearchContainer>
      </FilterSelector>
    )
  }, [])

  const getSportElement = useCallback(() => {
    return (
      <FilterSelector>
        <h3>Sport</h3>
        <FilterSelectorSearchContainer>
          <FilterSelectorSearch placeholder="Search" icon={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}></FilterSelectorSearch>
        </FilterSelectorSearchContainer>
      </FilterSelector>
    )
  }, [])

  const getSearchElement = useCallback(() => {
    return (
      <MarketplaceSearch placeholder="Search" icon={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}></MarketplaceSearch>
    )
  }, [])

  const getSearchParamsBarElement = useCallback(
    (isMobile: boolean) => {
      if (isMobile) {
        return (
          <FiltersContainerResponsive>
            {filters.map((filter, id) => {
              return (
                <FilterButton key={filter.label}>
                  {filter.label}
                  <Image
                    style={{ cursor: 'pointer' }}
                    marginLeft={8}
                    alt="Close selected filter"
                    src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
                    onClick={() => {
                      toggleFilter(filter)
                    }}
                  />
                </FilterButton>
              )
            })}
            {sort && sort.type !== SortType.NO_SORT && (
              <FilterButton key={sort.label}>
                {sort.label}
                <Image
                  style={{ cursor: 'pointer' }}
                  marginLeft={8}
                  alt="Close selected filter"
                  src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
                  onClick={() => {
                    changeSort(SortType.NO_SORT)
                  }}
                />
              </FilterButton>
            )}

            {filters.length >= 1 && (
              <DeleteFiltersButton onClick={() => removeSortAndFilters()}>Delete filters</DeleteFiltersButton>
            )}
          </FiltersContainerResponsive>
        )
      }
      return (
        <FiltersContainer>
          {filters.map((filter, id) => {
            return (
              <FilterButton key={filter.label}>
                {filter.label}
                <Image
                  style={{ cursor: 'pointer' }}
                  marginLeft={8}
                  alt="Close selected filter"
                  src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
                  onClick={() => {
                    toggleFilter(filter)
                  }}
                />
              </FilterButton>
            )
          })}
          {sort && sort.type !== SortType.NO_SORT && (
            <FilterButton key={sort.label}>
              {sort.label}
              <Image
                style={{ cursor: 'pointer' }}
                marginLeft={8}
                alt="Close selected filter"
                src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
                onClick={() => {
                  changeSort(SortType.NO_SORT)
                }}
              />
            </FilterButton>
          )}

          {filters.length >= 1 && (
            <DeleteFiltersButton onClick={() => removeSortAndFilters()}>Delete filters</DeleteFiltersButton>
          )}
        </FiltersContainer>
      )
    },
    [changeSort, filters, removeSortAndFilters, sort, toggleFilter],
  )

  return (
    <MarketplaceStyled>
      <MarketplaceHeader>
        <MarketplaceSearchContainer>
          {version === 2 && getSearchElement()}
          <ResultsNumber>{totalItems} Results</ResultsNumber>
        </MarketplaceSearchContainer>
        {getSearchParamsBarElement(false)}
        <ResponsiveFilterButton onClick={() => toggleMobileFilterContainer()}>
          <Image style={{ cursor: 'pointer' }} alt="Filter button" src={`${baseMarketplaceImageUrl}/filter_icon.svg`} />
        </ResponsiveFilterButton>
      </MarketplaceHeader>
      {getSearchParamsBarElement(true)}
      <MarketplaceContainer>
        <FiltersSelectorContainer showing={showing}>
          {getStatusElement()}
          {getSortElement()}
          {getPriceElement()}

          {version === 2 && (
            <>
              {getCreatorElement()}
              {getCollectionElement()}
              {getSportElement()}
            </>
          )}
        </FiltersSelectorContainer>

        <CardsContainer>
          {version >= 1 && initialLoad && (
            <div>
              {Array.from({ length: 10 }, (_: any, index: number) => (
                <MarketplaceCardLoading key={index} />
              ))}
            </div>
          )}
          {version >= 1 && !initialLoad && (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={!loading && totalItems > tokens.length}
              loader={<MarketplaceCardLoading key={0} />}
            >
              {tokens.map((item: NftEntity) => {
                return (
                  <MarketplaceCard erc721Prop={item} key={item.tokenAddress + '_' + item.tokenId} saleOnHover={false} />
                )
              })}
            </InfiniteScroll>
          )}
        </CardsContainer>

        {version === 0 && <ComingSoonImg margin={[200, 85]} />}
      </MarketplaceContainer>
    </MarketplaceStyled>
  )
}
