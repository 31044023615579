import { CardActionInputStyle } from './CardActionInput.style'

type CardActionInputProps = {
  onChange: (e: any) => void
  value: any
}

export const CardActionInput = ({ onChange, value }: CardActionInputProps) => {
  return (
    <CardActionInputStyle
      value={value}
      placeholder="Enter amount"
      type="number"
      step="any"
      inputMode="decimal"
      pattern="^[0-9]*[.,]?[0-9]{0,18}$"
      autoComplete="off"
      autoCorrect="off"
      size={18}
      minLength={1}
      maxLength={17}
      min={0.00000000000000001}
      max={'999999999999999999'}
      onChange={(e) => {
        if (e.target.value.length < 18) {
          onChange(e.target.value)
        }
      }}
    />
  )
}
