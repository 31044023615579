export const selectorStylesToken = {
  option: () => ({
    color: '#F8FAFC',
    borderBottom: '1px solid #36373E;',
    padding: 15,
    fontFamily: 'Fira Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    backgroundColor: '#171719',
    cursor: 'pointer',
  }),
  container: () => ({
    backgroundColor: '#171719',
    width: '110px',
    display: 'flex',
  }),
  control: () => ({
    width: '100%',
    display: 'flex',
  }),
  valueContainer: () => ({
    display: 'flex',
    margin: 'auto',
  }),
  singleValue: () => ({
    margin: 'auto',
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#F8FAFC',
    margin: 'auto',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'none',
    marginTop: 0,
    width: 110,
  }),
}

export const selectorStylesExpirationTime = {
  option: () => ({
    color: '#F8FAFC',
    borderBottom: '1px solid #36373E;',
    padding: 15,
    fontFamily: 'Fira Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    backgroundColor: '#171719',
    cursor: 'pointer',
  }),
  container: () => ({
    backgroundColor: '#171719',
    width: '70px',
    display: 'flex',
  }),
  control: () => ({
    width: '100%',
    display: 'flex',
  }),
  valueContainer: () => ({
    display: 'flex',
    margin: 'auto',
  }),
  singleValue: () => ({
    margin: 'auto',
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#F8FAFC',
    margin: 'auto',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'none',
    marginTop: 0,
    width: 70,
  }),
}

