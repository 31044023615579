import { BigNumber, ethers } from 'ethers'
import { State } from 'reducers'
import { WalletState } from 'reducers/wallet'
import { Dispatch } from 'redux'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { getWeb3Modal } from 'utils/web3modal'

export const SET_ADDRESS = 'SET_ADDRESS'
export const SET_BALANCE = 'SET_BALANCE'
export const SET_MYTV_BALANCE = 'SET_MYTV_BALANCE'
export const SET_WEB3 = 'SET_WEB3'
export const SET_PROVIDER = 'SET_PROVIDER'
export const SET_CONNECTED = 'SET_CONNECTED'
export const SET_CHAINID = 'SET_CHAINID'
export const SET_WEB3MODAL = 'SET_WEB3MODAL'
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'

export type IWalletAction = Partial<WalletState> & { type: string }

export const login = (state: WalletState): IWalletAction => {
  return {
    type: LOGIN,
    ...state,
  }
}
export const setAddress = (address: string) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_ADDRESS,
    isConnected: true,
    address,
  })
}

export const setChainId = (chainId: number): IWalletAction => {
  return {
    type: SET_CHAINID,
    chainId,
  }
}

export const setConnected = (isConnected: boolean): IWalletAction => {
  return {
    type: SET_CONNECTED,
    isConnected,
  }
}

export const setProvider = (provider: any): IWalletAction => {
  return {
    type: SET_PROVIDER,
    provider,
  }
}

export const setWeb3 = (web3: ethers.providers.Web3Provider): IWalletAction => {
  return {
    type: SET_WEB3,
    web3,
  }
}
export const setMyTvBalance = (account: string) => async (dispatch: Dispatch<any>, getState: () => State) => {
  const myTvTokenAddress = getState().myTvToken.address
  if (!myTvTokenAddress) return
  const myTvBalance = await SmartContractApi.getErc20Balance(myTvTokenAddress, account)
  dispatch({
    type: SET_MYTV_BALANCE,
    myTvBalance,
  })
}

export const setBalance = (balance: BigNumber) => {
  return {
    type: SET_BALANCE,
    balance,
  }
}
export const logout = () => async (dispatch: Dispatch) => {
  const web3Modal = getWeb3Modal()
  if (web3Modal.cachedProvider) {
    await web3Modal.clearCachedProvider()
  }
  dispatch({
    type: LOGOUT,
  })
}

const onChangeAccount = async (accounts: string[], web3: ethers.providers.Web3Provider, dispatch: Dispatch<any>) => {
  if (!accounts[0]) {
    dispatch(logout())
  } else {
    await dispatch(setAddress(ethers.utils.getAddress(accounts[0])))
    await dispatch(setBalance(await web3?.getBalance(accounts[0])))
  }
}

const changeChainId = async (chainId: number, dispatch: Dispatch<any>) => {
  window.location.reload()
  // await dispatch(setChainId(parseInt(chainId.toString())))
}

export const connectWallet = () => async (dispatch: Dispatch<any>) => {
  try {
    if (!process.env.REACT_APP_DEFAULT_CHAINID) throw Error('Invalid chain id')
    const web3Modal = getWeb3Modal()
    const provider = await web3Modal.connect()
    const chainId = parseInt(provider.chainId.toString())
    const web3 = new ethers.providers.Web3Provider(provider)
    const accounts = await web3.listAccounts()
    const address = accounts[0]
    await dispatch(
      login({
        isConnected: true,
        address,
        web3,
        provider,
        chainId,
        web3Modal,
      }),
    )
    if (chainId === ~~process.env.REACT_APP_DEFAULT_CHAINID) {
      await dispatch(setBalance(await web3?.getBalance(address)))
    }
    provider.on('accountsChanged', (accounts: string[]) => {
      onChangeAccount(accounts, web3, dispatch)
    })
    provider.on('chainChanged', (chainId: string) => changeChainId(~~chainId, dispatch))
    provider.on('disconnect', () => dispatch(logout()))
  } catch (err) {
    console.error(err)
  }
}
