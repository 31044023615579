import BaseService from 'Webservices/BaseService'

type IParamsGet = {} | null

type IQueryString = {
  userAddress: string
  tokenAddress: string
}

type IAllowanceResponse = {
  allowed: boolean
}

export default class Allowance extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/erc721/allowance`
  public static async get(params: IParamsGet, queryString: IQueryString): Promise<IAllowanceResponse> {
    const webServiceUrl = new URL(`${Allowance.baseUrl}`)
    Object.entries(queryString).forEach(([key, value]) => webServiceUrl.searchParams.set(key, value))
    return this.httpGet(webServiceUrl)
  }
}
