import styled from 'styled-components'

export const CardActionInputStyle = styled.input`
  background: #171719;
  border: none;
  padding: 15px 24px;
  width: calc(100% - 105px);
  color: rgba(248, 250, 252, 0.5);
  border-right: 1px solid #36373e;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  @media (max-width: 767px) {
    width: calc(100% - 120px);
  }

  z-index: 2;

  ::placeholder {
    color: rgba(248, 250, 252, 0.5);

    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`
