import { Box, MaxScreenWidth } from 'styles'

import { BigPurpleText } from '../Collection.style'
import { SubInfo } from '../InfoComponent/SubInfo/SubInfo.view'
import {
  AboutRarity,
  CollectionStoryStyled,
  Description,
  FirstPart,
  MythicalNfts,
  MythicalNftsContainer,
  PartsContainer,
  SloebImage,
  SloebImageContainer,
  SubInfosContainer,
  Text,
  TextBold,
  TextRarity,
  Title,
  YellowImage,
} from './CollectionStory.style'
import { Dropdown } from './Dropdown/Dropdown.view'
import { DropdownFan } from './DropdownsContent/DropdownFan/DropdownFan.view'
import { DropdownPremium } from './DropdownsContent/DropdownPremium/DropdownPremium.view'
import { DropdownStory } from './DropdownsContent/DropdownStory/DropdownStory.view'
import { DropdownUtility } from './DropdownsContent/DropdownUtility/DropdownUtility.view'
import HowToBuy from './HowToBuy/HowToBuy.view'

export const CollectionStory = () => {
  return (
    <CollectionStoryStyled>
      <MaxScreenWidth maxWidth="1440px">{descriptionComponent()}</MaxScreenWidth>
      <MaxScreenWidth maxWidth="1440px">
        <Dropdown title="the premium collection" number={1}>
          <DropdownPremium />
        </Dropdown>
        <Dropdown title="the fan collection" number={2}>
          <DropdownFan />
        </Dropdown>
        <Dropdown title="Win S. LOEB experiences" number={3}>
          <DropdownUtility />
        </Dropdown>
        <Dropdown title="the story of a legend" number={4}>
          <DropdownStory />
        </Dropdown>
        <HowToBuy />
      </MaxScreenWidth>
    </CollectionStoryStyled>
  )

  function descriptionComponent() {
    return (
      <Description>
        <FirstPart>
          <Title>MyTVchain will release soon its first NFT collection ever:</Title>
          <Box marginBottom={24}>
            <BigPurpleText>“THE FIRST”</BigPurpleText>
          </Box>
          <Box marginBottom={8}>
            <Text>
              MyTVchain is the Web3 sports fan experience where you can collect legendary NFTs, support your favorite
              athletes, watch competitions and earn money while doing it. Our first NFT collection will feature the
              first WRC title of the racing legend Sébastien Loeb.
            </Text>
          </Box>
          <TextBold>And it couldn't be a better fit for our first collection.</TextBold>
        </FirstPart>
        <PartsContainer>
          <AboutRarity>
            <Title>It's all about rarity</Title>
            <TextRarity>
              There are four tiers of MYTV NFTs, based on rarity, ranging from the collectible Core tier to the
              ultra-sacred Mythical tier.{' '}
            </TextRarity>
            <TextBold>MYTV Marketplace Rarity ladder:</TextBold>
            <SubInfosContainer>
              <SubInfo title={'Core'} />
              <SubInfo title={'Rare'} />
              <SubInfo title={'Legendary'} />
              <SubInfo title={'Mythical'} />
            </SubInfosContainer>
          </AboutRarity>
          <SloebImageContainer>
            <SloebImage src="/images/collection/Loeb_Xsara_Homepage_vente_01_Black_BG.png?i=2022" />
          </SloebImageContainer>
        </PartsContainer>
        <MythicalNftsContainer>
          <MythicalNfts>
            <Text>
              Mythical NFTs are very different from the other NFTs. It does not comply with rules and standards. It's a timeless
              digital memorabilia that celebrates the beauty of sports and the shivers of victory.
              <br />
              <br />
              It's so rare and unique that it takes NFT rarity to an all-new level. Possessing a Mythical NFT in your wallet
              is the absolute mark of being a True Fan, it's the culmination of your Sports NFT collector's journey.
            </Text>
          </MythicalNfts>
        </MythicalNftsContainer>
        <YellowImage src="/images/yellow-paint.svg" />
      </Description>
    )
  }
}
