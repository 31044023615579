import { MintEntity } from 'Entities'
import BaseService from '../BaseService'

type IParamsGet = { userAddress: string; phaseId: number; slug: string }

type IQueryString = {}

export type IParamsPost = {
  address: string | null
  phaseId: number
}

export default class Whitelist extends BaseService {
  protected static readonly baseUrl = `${process.env['REACT_APP_WHITELIST_API']}`

  public static async isWhitelisted(params: IParamsGet, queryString: IQueryString = {}): Promise<boolean> {
    const webServiceUrl = new URL(
      `${Whitelist.baseUrl}/mytv-${params.slug}/isWhitelisted/${params.phaseId}/${params.userAddress}`,
    )
    return await this.httpGet(webServiceUrl)
  }

  public static async signMessage(slug: string, params: IParamsPost): Promise<MintEntity> {
    const webServiceUrl = new URL(`${Whitelist.baseUrl}/mytv-${slug}/signMessage`)
    return await this.httpPost(webServiceUrl, params)
  }
}
