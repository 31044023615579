import { TokenSupportEntity } from 'Entities'
import { Dispatch } from 'redux'

export const SET_TOKEN = 'SET_TOKEN'

export type IMyTvTokenAction = {
  type: string
  token: TokenSupportEntity
}
export const setMyTvToken = (supportedTokens: TokenSupportEntity[]) => (dispatch: Dispatch) => {
  const mytvTokenFilter = supportedTokens.filter(
    (token) => token.name === 'MYTV' && token.chainId === ~~process.env.REACT_APP_DEFAULT_CHAINID!,
  )
  if (!mytvTokenFilter.length) return
  dispatch({
    type: SET_TOKEN,
    token: mytvTokenFilter[0],
  })
}
