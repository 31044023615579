import { NftEntity } from 'Entities'
import { WalletState } from 'reducers/wallet'
import { Accept } from './ActionType/Accept/Accept.controller'
import { Bid } from './ActionType/Bid/Bid.controller'
import { MakeOffer } from './ActionType/MakeOffer/MakeOffer.controller'
import { Sale } from './ActionType/Sale/Sale.controller'
import { UpAuction } from './ActionType/UpAuction/UpAuction.controller'
import { ModalType } from './CardAction.enum'

interface CardActionViewProps {
  version: number
  owner: string
  collection: string
  wallet: WalletState
  type: ModalType
  item: NftEntity
  callBack?: () => void
}

export const CardActionView = ({ version, owner, collection, type, item, callBack }: CardActionViewProps) => {
  let component = <></>
  switch (type) {
    case ModalType.BID:
      component = <Bid owner={owner} collection={collection} item={item} />
      break
    case ModalType.MAKE_OFFER:
      component = <MakeOffer callBack={callBack} owner={owner} collection={collection} item={item} />
      break
    case ModalType.SALE:
      component = <Sale callBack={callBack} owner={owner} collection={collection} item={item} />
      break
    case ModalType.UP_AUCTION:
      component = <UpAuction owner={owner} collection={collection} item={item} />
      break
    case ModalType.ACCEPT:
      component = <Accept subTotal={2500} />
      break
  }

  if (version >= 1) {
    return component
  } else {
    return <></>
  }
}
