/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, BadgeType } from 'app/App.components/Badge/Badge.view'
import { Button } from 'app/App.components/Button/Button.view'
import { CardAction } from 'app/App.components/CardAction/CardAction.controller'
import { ModalType } from 'app/App.components/CardAction/CardAction.enum'
import { MarketplaceCard } from 'app/App.components/Marketplace/MarketplaceCard/MarketplaceCard.controller'
import { showModal, showModalImg } from 'app/App.components/Modal/Modal.actions'
import { TransactionModal } from 'app/App.components/TransactionModal/TransactionModal.controller'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { ethers } from 'ethers'
import { useIsWrongChain } from 'hooks/useIsWrongChain'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { State } from 'reducers'
import SmartContractApi from 'SmartContracts/SmartContractApi'
import { Box, Image } from 'styles'
import { formatAddress, getToken, timeSince } from 'utils'
import {
  AttributeBlocksContainer,
  AttributeBlocksSuperiorContainer,
  BadgeContainer,
  ButtonContainer,
  ButtonsContainer,
  CardExtendedStyled,
  CardImage,
  CardOwner,
  CardText,
  CardTitle,
  CardTitleContainer,
  CardVideo,
  CollectionContainer,
  CollectionTitle,
  DetailsBlock,
  DetailsContainer,
  DetailsName,
  DetailsValue,
  FirstPartContainer,
  LeftPartContainer,
  LinkIcon,
  MoreAttributesContainer,
  OfferContainer,
  OfferCopyLogo,
  OfferHeaderTd,
  OfferHeaderTr,
  OfferTitle,
  OwnerContainer,
  Price,
  PriceAndButtonsContainer,
  PriceContainer,
  PriceInMyTv,
  ResponsiveBadgeContainer,
  RightPartContainer,
  TopButtonsContainer,
  TopButtonsContainerResponsive,
} from './CardExtended.style'
import { AttributeBlock } from './Components/AttributeBlock/AttributeBlock.view'
import { CardSubMenu } from './Components/CardSubMenu/CardSubMenu.view'

enum FrameType {
  CARBON = 'CARBON',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  RALLY = 'RALLY',
  SPECIAL_STAGE = 'SPECIAL_STAGE',
  TITLE = 'TITLE',
}
interface CardExtendedViewProps {
  version: number
  erc721: NftEntity | null
  image: string
  sameCollection: NftEntity[]
  buyToken: TokenSupportEntity | null
  refreshToken: () => void
  isRefreshing: boolean
  getErc721: () => void
  price: string | null
  isRevealed: boolean
  openBlister: () => void
}

export const CardExtendedView = ({
  version,
  erc721,
  image,
  sameCollection,
  buyToken,
  refreshToken,
  isRefreshing,
  getErc721,
  price,
  isRevealed,
  openBlister,
}: CardExtendedViewProps) => {
  const [showing, setShowing] = useState(false)
  const wallet = useSelector((state: State) => state.wallet)
  const dispatch = useDispatch()
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const history = useHistory()
  const isWrongChain = useIsWrongChain()

  const checkHasAskOrder = useCallback(() => {
    if (!erc721) return false
    return erc721.hasAskOrders && erc721.askOrders?.filter((order) => order.sellerAddress === wallet.address).length > 0
  }, [erc721, wallet])

  const checkHasOffers = useCallback(() => {
    if (!erc721) return false
    return erc721.hasAskOrders && Boolean(erc721.askOrders.length)
  }, [erc721, wallet])

  const checkIsNftOwner = useCallback(() => {
    if (!erc721) return false
    return erc721.ownerAddress === wallet.address
  }, [erc721, wallet])

  const checkHasSellOrder = useCallback(() => {
    if (!erc721) return false
    return erc721.sellOrder?.askTokenAmount
  }, [erc721, wallet])

  const getBadgeFromFrameAttribute = useCallback(() => {
    const frameAttribute = erc721?.attributes.find((e) => e.trait_type === 'FRAME')
    switch (frameAttribute?.value.toUpperCase()) {
      case FrameType.CARBON:
        return BadgeType.CORE
      case FrameType.SILVER:
        return BadgeType.RARE
      case FrameType.GOLD:
        return BadgeType.LEGENDARY
      case FrameType.TITLE:
      case FrameType.SPECIAL_STAGE:
      case FrameType.RALLY:
        return BadgeType.MYTHICAL
      default:
        return BadgeType.NO_BADGE
    }
  }, [erc721])

  if (erc721) {
    return (
      <CardExtendedStyled>
        <FirstPartContainer>
          <LeftPartContainer>
            {erc721.animationUrl && isRevealed ? (
              <CardVideo preload="auto" controls autoPlay loop muted>
                <source src={erc721.animationUrl} type="video/mp4" />
              </CardVideo>
            ) : (
              <CardImage alt={`${erc721.name} #${erc721.tokenId}`} src={image}></CardImage>
            )}
          </LeftPartContainer>
          <RightPartContainer>
            <ResponsiveBadgeContainer>
              <Badge type={getBadgeFromFrameAttribute()}></Badge>
              <TopButtonsContainerResponsive>
                <LinkIcon
                  alt="Link icon"
                  src="/images/refresh.svg"
                  onClick={() => refreshToken()}
                  isRefreshing={isRefreshing}
                ></LinkIcon>
                <LinkIcon
                  alt="Link icon"
                  src="/images/link-icon.svg"
                  onClick={() => navigator.clipboard.writeText(window.location.toString())}
                ></LinkIcon>
              </TopButtonsContainerResponsive>
            </ResponsiveBadgeContainer>
            <CardTitleContainer>
              <CardTitle>
                {erc721.name} #{erc721.tokenId}
              </CardTitle>
              <BadgeContainer>
                <Badge type={getBadgeFromFrameAttribute()}></Badge>
              </BadgeContainer>
              <div></div>
              <TopButtonsContainer>
                <LinkIcon
                  alt="Link icon"
                  src="/images/refresh.svg"
                  onClick={() => refreshToken()}
                  isRefreshing={isRefreshing}
                ></LinkIcon>
                <LinkIcon
                  alt="Link icon"
                  src="/images/link-icon.svg"
                  onClick={() => navigator.clipboard.writeText(window.location.toString())}
                ></LinkIcon>
              </TopButtonsContainer>
            </CardTitleContainer>
            <Image alt="blue line separator" src="/images/blue-line-separator.svg"></Image>
            <OwnerContainer>
              <CardOwner>Owner : {formatAddress(erc721.ownerAddress as string)}</CardOwner>
            </OwnerContainer>
            <CardText>{erc721.description}</CardText>
            <PriceAndButtonsContainer>
              {price ? (
                <PriceContainer>
                  <Price>Price</Price>
                  <Box display="flex" marginRight={14} marginBottom={24}>
                    <PriceInMyTv>
                      {ethers.utils.formatUnits(price, buyToken?.decimals!)} {buyToken?.symbol.toUpperCase()}
                    </PriceInMyTv>
                    {buyToken?.avatarImage && <Image marginLeft={14} src={buyToken?.avatarImage} />}
                  </Box>
                </PriceContainer>
              ) : (
                <div></div>
              )}

              {version > 0 && (
                <ButtonsContainer>
                  {!isWrongChain && wallet.address && !checkIsNftOwner() && !checkHasAskOrder() && (
                    <Button
                      style={{ maxWidth: 194, maxHeight: 48 }}
                      appearance="primary_empty"
                      width={'192px'}
                      clickCallback={() =>
                        dispatch(
                          showModal(
                            'Make an offer',
                            <CardAction
                              owner={erc721.ownerAddress!}
                              collection={erc721.name!}
                              type={ModalType.MAKE_OFFER}
                              item={erc721}
                              callBack={getErc721}
                            />,
                          ),
                        )
                      }
                    >
                      MAKE AN OFFER
                    </Button>
                  )}
                  {!isWrongChain && wallet.address && !price && checkIsNftOwner() && !checkHasSellOrder() && (
                    <Button
                      style={{ maxWidth: 194, maxHeight: 48 }}
                      appearance="primary_empty"
                      width={'192px'}
                      clickCallback={() =>
                        dispatch(
                          showModalImg(
                            'Item for sale',
                            <CardAction
                              owner={erc721.ownerAddress!}
                              collection={erc721.name!}
                              type={ModalType.SALE}
                              item={erc721}
                              callBack={getErc721}
                            />,
                            image,
                          ),
                        )
                      }
                    >
                      SELL
                    </Button>
                  )}
                  {!isWrongChain && !checkIsNftOwner() && checkHasAskOrder() && (
                    <Button
                      style={{ maxWidth: 160, maxHeight: 48 }}
                      appearance="primary"
                      width={'192px'}
                      clickCallback={async () => {
                        const order = erc721.askOrders.filter((order) => order.sellerAddress === wallet.address)[0]
                        const tx = await SmartContractApi.cancelOrder(order)
                        if (!tx) {
                          return
                        }
                        await getErc721()
                        dispatch(
                          showModal('Transaction finished', <TransactionModal finished transactionHash={tx.hash} />),
                        )
                      }}
                    >
                      CANCEL OFFER
                    </Button>
                  )}
                  {!isWrongChain && !checkIsNftOwner() && checkHasSellOrder() && (
                    <Button
                      style={{ maxWidth: 160, maxHeight: 48 }}
                      appearance="primary"
                      width={'192px'}
                      clickCallback={async () => {
                        const order = erc721.sellOrder!
                        order.buyerAddress = wallet.address
                        const tx = await SmartContractApi.acceptOrder(order)

                        if (!tx) {
                          return
                        }
                        await getErc721()
                        dispatch(
                          showModal('Transaction finished', <TransactionModal finished transactionHash={tx.hash} />),
                        )
                      }}
                    >
                      BUY
                    </Button>
                  )}
                  {!isWrongChain && wallet.address && checkIsNftOwner() && checkHasSellOrder() && (
                    <Button
                      style={{ maxWidth: 160, maxHeight: 48 }}
                      appearance="primary"
                      width={'192px'}
                      clickCallback={async () => {
                        const tx = await SmartContractApi.cancelOrder(erc721.sellOrder!)
                        if (!tx) {
                          return
                        }
                        await getErc721()
                        dispatch(
                          showModal('Transaction finished', <TransactionModal finished transactionHash={tx.hash} />),
                        )
                      }}
                    >
                      CANCEL SALE
                    </Button>
                  )}
                </ButtonsContainer>
              )}
              {version === 0 && (
                <>
                  {!isRevealed && wallet.address && checkIsNftOwner() && (
                    <Button
                      style={{ maxWidth: 194, maxHeight: 48 }}
                      appearance="primary_empty"
                      width={'192px'}
                      clickCallback={openBlister}
                    >
                      OPEN NOW
                    </Button>
                  )}
                </>
              )}
            </PriceAndButtonsContainer>
            <CardSubMenu title="Details">
              <DetailsContainer>
                <DetailsBlock>
                  <DetailsName>Contract address</DetailsName>
                  <DetailsValue>{formatAddress(erc721.tokenAddress)}</DetailsValue>
                </DetailsBlock>
                <DetailsBlock>
                  <DetailsName>Token standard</DetailsName>
                  <DetailsValue>BEP721</DetailsValue>
                </DetailsBlock>
                <DetailsBlock>
                  <DetailsName>Token ID</DetailsName>
                  <DetailsValue>{erc721.tokenId}</DetailsValue>
                </DetailsBlock>
                <DetailsBlock>
                  <DetailsName>Blockchain</DetailsName>
                  <DetailsValue>Binance Smart Chain</DetailsValue>
                </DetailsBlock>
              </DetailsContainer>
            </CardSubMenu>
            <CardSubMenu title="Attributes">
              <AttributeBlocksSuperiorContainer>
                <AttributeBlocksContainer>
                  {erc721.attributes.map((attribute: any, key: number) => {
                    return (
                      <AttributeBlock title={attribute.trait_type} subTitle={attribute.value} key={key + erc721._id!} />
                    )
                  })}
                </AttributeBlocksContainer>
              </AttributeBlocksSuperiorContainer>
              {/* </AttributeBlocksSuperiorContainer>
            <AttributeBlocksSuperiorContainer>
              <AttributeBlocksContainer>
                <AttributeBlock title="Frame" subTitle="Silver" percentage={23}></AttributeBlock>
                <AttributeBlock title="Background" subTitle="Blue" percentage={23}></AttributeBlock>
                <AttributeBlock title="Badge" subTitle="European champion" percentage={7}></AttributeBlock>
                <AttributeBlock title="Signature" subTitle="Gold" percentage={0.4}></AttributeBlock>
                <AttributeBlock title="Values" subTitle="Fairplay" percentage={15}></AttributeBlock>
              </AttributeBlocksContainer>
            </AttributeBlocksSuperiorContainer>
            <TwoAttributeBlocksContainerResponsive showing={showing}>
              <AttributeBlock title="Frame" subTitle="Silver" percentage={23}></AttributeBlock>
              <AttributeBlock title="Background" subTitle="Blue" percentage={23}></AttributeBlock>
            </TwoAttributeBlocksContainerResponsive>
            <MoreAttributeBlocksContainerResponsive showing={!showing}>
              <AttributeBlock title="Frame" subTitle="Silver" percentage={23}></AttributeBlock>
              <AttributeBlock title="Background" subTitle="Blue" percentage={23}></AttributeBlock>
              <AttributeBlock title="Badge" subTitle="European champion" percentage={7}></AttributeBlock>
              <AttributeBlock title="Signature" subTitle="Gold" percentage={0.4}></AttributeBlock>
              <AttributeBlock title="Values" subTitle="Fairplay" percentage={15}></AttributeBlock>
            </MoreAttributeBlocksContainerResponsive> */}
              <MoreAttributesContainer
                onClick={() => {
                  setShowing(!showing)
                }}
              >
                {showing ? 'View less attributes ←' : 'View more attributes →'}
              </MoreAttributesContainer>
            </CardSubMenu>
            {checkIsNftOwner() && checkHasOffers() && (
              <OfferContainer>
                <OfferTitle>Offers</OfferTitle>
                <OfferHeaderTr>Price</OfferHeaderTr>
                <OfferHeaderTr>Time</OfferHeaderTr>
                <OfferHeaderTr>From</OfferHeaderTr>
                <OfferHeaderTr></OfferHeaderTr>
                {erc721.askOrders.map((order) => {
                  const token = getToken(nativeToken, supportedTokens, order.sellTokenAddress)
                  return (
                    <React.Fragment key={order.orderHash}>
                      <OfferHeaderTd>
                        {ethers.utils.formatUnits(order.sellTokenAmount, token.decimals).toUpperCase()} {token.symbol}
                      </OfferHeaderTd>
                      <OfferHeaderTd>{order.createdAt && timeSince(new Date(order.createdAt))}</OfferHeaderTd>
                      <OfferHeaderTd
                        onClick={() => {
                          navigator.clipboard.writeText(order.sellerAddress)
                        }}
                      >
                        {formatAddress(order.sellerAddress)}
                        <OfferCopyLogo src="/images/address.svg" alt="Copy logo"></OfferCopyLogo>
                      </OfferHeaderTd>
                      <OfferHeaderTd>
                        <Button
                          style={{ maxWidth: 194, maxHeight: 48 }}
                          appearance="primary_empty"
                          width={'192px'}
                          clickCallback={async () => {
                            const order = erc721.askOrders![0]
                            order.buyerAddress = wallet.address
                            const tx = await SmartContractApi.acceptOrder(order)
                            if (!tx) {
                              return
                            }
                            await getErc721()
                            dispatch(
                              showModal(
                                'Transaction finished',
                                <TransactionModal finished transactionHash={tx.hash} />,
                              ),
                            )
                          }}
                        >
                          ACCEPT
                        </Button>
                      </OfferHeaderTd>
                    </React.Fragment>
                  )
                })}
              </OfferContainer>
            )}
          </RightPartContainer>
        </FirstPartContainer>
        {sameCollection.length >= 1 && (
          <>
            <CollectionTitle>On the same collection</CollectionTitle>
            <CollectionContainer>
              {sameCollection.map((item: NftEntity, key: any) => {
                return (
                  <MarketplaceCard
                    erc721Prop={item}
                    grayed={item.tokenAddress === erc721.tokenAddress && item.tokenId === erc721.tokenId}
                    key={item.tokenAddress + item.tokenId}
                    saleOnHover={false}
                  />
                )
              })}
            </CollectionContainer>
            {version === 2 && (
              <ButtonContainer>
                <Button appearance="primary_empty" clickCallback={() => history.push('/marketplace')}>
                  VIEW COLLECTION
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
      </CardExtendedStyled>
    )
  } else {
    return <CardExtendedStyled></CardExtendedStyled>
  }
}
