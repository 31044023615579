export const backgroundColor = '#101010'
export const containerColor = '#232327'
export const borderColor = '#61616b'
export const textColor = '#FFFFFF'
export const subTextColor = '#7E8794'
export const backgroundTextColor = '#F7F9FD'
export const placeholderColor = '#F7F9FD'
export const primaryColor = '#0023FF'
export const secondaryColor = '#73d7c3'
export const tertiaryColor = '#EFCB4A;'
export const upColor = '#00E205'
export const downColor = '#F90021'
export const selectedColor = '#aea3ff3d'