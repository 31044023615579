import { TokenSupportEntity } from 'Entities'
import { OrderStatus } from 'Entities/Marketplace/OrderEntity'
import { ethers } from 'ethers'
export interface Filter {
  type: string
  label: string
  q?: { [key: string]: any }
  f?: { [key: string]: any }
  args?: { [key: string]: any }
}

export enum FilterType {
  NO_FILTER = '',
  ON_SALE = 'on_sale',
  ON_OFFER = 'on_offer',
  PRICE = 'price',
}

export const Filters = {
  [FilterType.NO_FILTER]: {
    type: FilterType.NO_FILTER,
    label: 'Filter by',
    q: {},
    f: {},
  },
  [FilterType.ON_SALE]: {
    type: FilterType.ON_SALE,
    label: 'On Sale',
    q: { hasSellOrder: true },
  },
  [FilterType.ON_OFFER]: {
    type: FilterType.ON_OFFER,
    label: 'On Offer',
    q: {},
    f: {},
  },
  [FilterType.PRICE]: {
    type: FilterType.PRICE,
    label: '',
    q: {},
    f: {},
  },
}

export function getOnOfferFilter(ownerAddress?: string): Filter {
  if (!ownerAddress) {
    return {
      type: FilterType.ON_OFFER,
      label: 'On Offer',
      q: { hasAskOrders: true },
    }
  }
  return {
    type: FilterType.ON_OFFER,
    label: 'On Offer',
    q: { hasAskOrders: true, ownerAddress: ownerAddress },
    args: { ownerAddress },
  }
}

export function getTokenPriceFilter(
  selectedToken: TokenSupportEntity,
  min: string | null,
  max: string | null,
): Filter {
  const f: any = {
    sellOrder: [{ $eq: ['$orderStatus', OrderStatus.Pending] }, { $eq: ['$askTokenAddress', selectedToken.address] }],
  }
  const args : any = { token: selectedToken.symbol }

  if (min) {
    f.sellOrder.push({
      $gte: ['$askTokenAmount', { $toDecimal: ethers.utils.parseUnits(min, selectedToken.decimals).toString() }],
    })
    args.min = min
  }
  if (max) {
    f.sellOrder.push({
      $lte: ['$askTokenAmount', { $toDecimal: ethers.utils.parseUnits(max, selectedToken.decimals).toString() }],
    })
    args.max = max
  }

  const filter: Filter = {
    type: FilterType.PRICE,
    label: selectedToken.symbol.toUpperCase(),
    f,
    args
  }

  return filter
}
