import { Title } from '../../CollectionStory.style'
import {
  DropdownFanStyled,
  ImgDesktop,
  ImgMobile,
  LeftPart,
  LeftRightContainer,
  RightPart,
  TextBold,
  TextFan,
} from './DropdownFan.style'

export const DropdownFan = () => {
  return (
    <DropdownFanStyled>
      <ImgDesktop src="/images/collection/fan_collection.png" alt="" width="100%" />
      <ImgMobile src="/images/collection/fan_collection_mobile.png" alt="" width="100%" />
      <LeftRightContainer>
        <LeftPart>
          <Title>We have a surprise.</Title>
          <TextFan>
            If your budget is short and you are not able to purchase one of our 2004 Premium Series NFT, don't worry, we
            have created a <TextBold>FAN SERIES</TextBold> Collection.
            <br />
            <br /> The Fan Series collection is also made of 2004 NFTs. Those NFT contain generative pictures of the
            2004 first title.
            <br />
            <br /> In the Fan Series collection, you will mostly get “Common” NFT.
          </TextFan>
        </LeftPart>
        <RightPart>
          <img alt="" src="/images/collection/booster_fan_series.gif" />
        </RightPart>
      </LeftRightContainer>
    </DropdownFanStyled>
  )
}
