import styled from 'styled-components/macro'
import { Image } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const CollectionStoryStyled = styled.div`
  margin: var(--collection-margin) 0;
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: ${Breakpoints.lg}) {
    margin: 96px 0 64px 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`
export const Description = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--collection-margin);

  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 0;
  }
`

export const FirstPart = styled.div`
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  max-width: 816px;
  margin-bottom: var(--collection-margin);

  @media (max-width: ${Breakpoints.sm}) {
    margin-bottom: 64px;
  }
`

export const Font = styled.div`
  font-family: 'Circe';
  font-style: normal;
`

export const Title = styled(Font)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Text = styled(Font)`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
  }
`

export const TextRarity = styled(Text)`
  margin: 24px 0 32px 0;
`

export const TextBold = styled.span`
  font-size: 18px;
  line-height: 24px;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
  }
  font-weight: 700;
`

export const PartsContainer = styled.div`
  display: flex;
  gap: 200px;
  margin-bottom: 24px;

  @media (max-width: 1300px) {
    gap: 50px;
  }

  @media (max-width: ${Breakpoints.md}) {
    gap: 0px;
    flex-direction: column;
  }
`

export const AboutRarity = styled.div`
  text-align: left;
`

export const MythicalNftsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const MythicalNfts = styled.div`
  background: #101010;
  padding: 40px;
  width: 708px;
  border: 1px solid #61616b;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.md}) {
    margin-right: -16px;
    font-size: 16px;
    border-bottom: none;
  }
`

export const YellowImage = styled(Image)`
  position: absolute;
  bottom: -150px;
  left: -420px;
  transform: scaleX(-1) rotate(360deg);
  z-index: -1;
`

export const SloebImageContainer = styled.div`
  box-shadow: inset 5px 5px 40px 20px #101010;
  z-index: -1;
  width: 25vw;
  max-width: 460px;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    margin-top: 24px;
    max-width: none;
  }
`

export const SloebImage = styled.img`
  width: 25vw;
  max-width: 460px;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    max-width: none;
  }
`

export const SubInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
`
