import { APPLICATION_ERROR, enqueueError } from 'actions/Errors.actions'
import { OpeningPackModal } from 'app/App.components/OpeningPackModal/OpeningPackModal.controller'
import { NftEntity, TokenSupportEntity } from 'Entities'
import { EProtocol } from 'Entities/Marketplace/EthContractConfigEntity'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import { SebastienLoebSeries } from 'pages/CardExtended/CardExtended.controller'
import * as localstorageUtils from 'utils/localstorage'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import { getIpfsLink, isUrl } from 'utils'
import * as Webservices from 'Webservices'
import { ProfileCardView } from './ProfileCard.view'

type ProfileCardProps = {
  erc721Prop: NftEntity
  grayed?: boolean
}

export const ProfileCard = ({ erc721Prop, grayed = false }: ProfileCardProps) => {
  const [image, setImage] = useState('/images/marketplace/cards/loading.png')
  const [loading, setLoading] = useState(false)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const [buyToken, setBuyToken] = useState<TokenSupportEntity | null>(null)
  const [price, setPrice] = useState<string | undefined>()
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const [erc721, setErc721] = useState<NftEntity>(erc721Prop)
  const [isOpenedBlisterModal, setIsOpenedBlisterModal] = useState(false)
  const [isRevealed, setIsRevealed] = useState(false)
  const dispatch = useDispatch()
  const priceUsd = 100

  const checkIsRevealed = React.useCallback((erc721: NftEntity) => {
    if (!erc721.tokenSupport) return true
    switch (erc721.tokenSupport.name) {
      case SebastienLoebSeries.PREMIUM:
      case SebastienLoebSeries.FAN:
        if (localstorageUtils.isBlisterAlreadyOpened(erc721.tokenAddress, erc721.tokenId)) return true
        const frontMetadata = erc721.tokenSupport.metadata!.front
        if (new Date().getTime() < new Date(frontMetadata!.revealDate).getTime()) {
          return true
        }
        const maxRevealDate = new Date(frontMetadata!.revealDate)
        maxRevealDate.setDate(maxRevealDate.getDate() + frontMetadata!.revealDelay)
        return new Date().getTime() > maxRevealDate.getTime()
      default:
        return true
    }
  }, [])

  const setImageFromReveal = React.useCallback((isRevealed: boolean, erc721: NftEntity) => {
    if (!erc721.tokenSupport || !erc721.docUri) return
    const frontMetadata = erc721.tokenSupport.metadata?.front
    if (isRevealed || !frontMetadata) {
      if (isUrl(erc721.docUri)) {
        setImage(erc721.docUri)
      } else {
        setImage(getIpfsLink(erc721.docUri))
      }
    } else {
      setImage(frontMetadata!.blister)
    }
  }, [])

  const openBlister = React.useCallback(() => {
    setIsOpenedBlisterModal(true)
  }, [])

  const onCloseBlisterOpening = React.useCallback(() => {
    setIsOpenedBlisterModal(false)
  }, [])

  const onVideoEndedBlisterOpening = React.useCallback(() => {
    setImageFromReveal(true, erc721)
    setIsRevealed(true)
  }, [erc721, setImageFromReveal])

  const getErc721 = React.useCallback(async () => {
    // Getting a single card
    const responseData = await Webservices.Erc721.Tokens.get(null, {
      q: { tokenAddress: erc721Prop.tokenAddress, tokenId: ~~erc721Prop.tokenId },
    })
    if (!responseData || !responseData[0]) return

    // If the card exists
    // Setting the card infos
    const erc721 = responseData[0]
    setErc721(erc721)

    // If the image is a URL, setting the URL else format with the IPFS gateway

    const isRevealed = checkIsRevealed(erc721)
    setIsRevealed(isRevealed)
    setImageFromReveal(isRevealed, erc721)
  }, [checkIsRevealed, erc721Prop.tokenAddress, erc721Prop.tokenId, setImageFromReveal])

  const getIpfsInfos = React.useCallback(async () => {
    setLoading(false)
    if (erc721.tokenUri) {
      try {
        if (!erc721.docUri) return
        if (isUrl(erc721.docUri)) {
          setImage(erc721.docUri)
        } else {
          setImage(getIpfsLink(erc721.docUri))
        }
      } catch (error) {
        console.error(error)
      }
    }
    setLoading(false)
  }, [erc721])

  useEffect(() => {
    getIpfsInfos()
  }, [getIpfsInfos])

  useEffect(() => {
    if (!erc721.sellOrder?.askTokenAmount) return
    const order = erc721.sellOrder
    if (order.askAssetClass === AssetClass.ETH) {
      setBuyToken(nativeToken)
      return
    }
    const supportedToken = supportedTokens.filter(
      (token) => token.protocol === EProtocol.ERC20 && token.address === erc721.sellOrder?.askTokenAddress,
    )
    if (!supportedToken.length) {
      dispatch(
        enqueueError({
          type: APPLICATION_ERROR,
          payload: { message: `Unsupported token erc20 for order ${erc721.sellOrder._id}` },
        }),
      )
    }
    setBuyToken(supportedToken[0])
  }, [erc721, supportedTokens, nativeToken, dispatch])

  const event = React.useMemo(() => {
    if (!erc721.tokenSupport?.metadata?.event) return
    return erc721.tokenSupport?.metadata?.event
  }, [erc721])

  useEffect(() => {
    if (erc721.sellOrder?.askTokenAmount) setPrice(erc721.sellOrder.askTokenAmount)
  }, [erc721])

  useEffect(() => {
    const isRevealed = checkIsRevealed(erc721Prop)
    setIsRevealed(isRevealed)
    setImageFromReveal(isRevealed, erc721Prop)
  }, [checkIsRevealed, erc721Prop, setImageFromReveal])

  return (
    <>
      {erc721 && (
        <>
          <ProfileCardView
            image={image}
            event={event}
            priceUsd={priceUsd}
            grayed={grayed}
            erc721={erc721}
            detailsOnHover={false}
            loading={loading}
            buyToken={buyToken}
            price={price}
            getErc721={getErc721}
            isRevealed={isRevealed}
            openBlister={openBlister}
          />
          {isOpenedBlisterModal && (
            <OpeningPackModal
              tokenAddress={erc721.tokenAddress}
              tokenId={erc721.tokenId}
              tokenUri={erc721.animationUrl ? erc721.animationUrl : erc721?.docUri ?? ''}
              onClose={onCloseBlisterOpening}
              onVideoEnded={onVideoEndedBlisterOpening}
              openingVideo={erc721.tokenSupport?.metadata?.front?.openingVideo ?? ''}
            />
          )}
        </>
      )}
    </>
  )
}
