import styled from 'styled-components/macro'
import { Page } from 'styles'
export const ErrorWalletStyled = styled(Page)`
  padding: 80px 0 0 0 !important;
  min-height: 50vh;
`
export const ErrorWalletNotConnectedContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const ErrorWalletNotConnectedLeft = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 105px;
`
export const ErrorWalletNotConnectedRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`

export const ErrorWalletNotConnectedImg = styled.img`
  height: calc(100vh - 240px);
`

export const ErrorWalletNotConnectedTitle = styled.h1`
  margin: 0;
  max-width: 600px;
  width: 65vw;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 53px;
  z-index: 6;

  color: #f8fafc;

  > span {
    margin-top: 32px;
    margin-left: 15px;
    font-family: Circe;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #f8fafc;
  }
`
