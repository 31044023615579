import { NftEntity } from 'Entities'
import BaseService from 'Webservices/BaseService'

type IParamsGet = {} | null

type IQueryString = {
  userAddress: string
  tokenAddress?: string
  page?: number
  limit?: number
}

export default class Balances extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/erc721/balances`

  public static async get(params: IParamsGet, queryString: IQueryString): Promise<NftEntity[]> {
    const webServiceUrl = new URL(`${Balances.baseUrl}`)
    Object.entries(queryString).forEach(([key, value]) => webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return this.httpGet(webServiceUrl)
  }
}
