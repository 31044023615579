import { NftEntity, OrderEntity } from 'Entities'
import { AssetClass } from 'Entities/Marketplace/OrderEntity'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Webservices from 'Webservices'
import { State } from '../../reducers/index'
import { SaleType } from './SalesHistory.enum'
import { SalesHistoryView } from './SalesHistory.view'

export type ISale = {
  order: OrderEntity
  token: NftEntity
  type: SaleType
}

export const SalesHistory = () => {
  const version = useSelector((state: State) => state.version.number)
  const [sales, setSales] = useState<ISale[]>([])
  const wallet = useSelector((state: State) => state.wallet)

  const loadOrders = React.useCallback(async (walletAdress: string) => {
    const responseData = await Webservices.Orders.get(null, {
      q: {
        $or: [
          { sellerAddress: walletAdress, $or: [{ orderStatus: 1 }, { orderStatus: 2 }] },
          { buyerAddress: walletAdress, $or: [{ orderStatus: 1 }, { orderStatus: 2 }] },
        ],
      },
      sort: { createdAt: -1 },
    })
    if (!responseData) return
    const orders = responseData.data
    const pairOrdersNftOffersMade: ISale[] = []
    orders
      .filter((order) => order.sellAssetClass)
      .forEach(async (order) => {
        const tokenAddress = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenAddress : order.askTokenAddress
        const tokenId = order.sellAssetClass === AssetClass.ERC721 ? order.sellTokenId : order.askTokenId
        const tokens = await Webservices.Erc721.Tokens.get(null, {
          q: { tokenAddress: tokenAddress, tokenId: ~~tokenId },
        })
        // return await previousValue
        if (!tokens?.length) return
        pairOrdersNftOffersMade.push({
          order: order,
          token: tokens[0],
          type: getSaleType(walletAdress, order),
        })
      }, Promise.resolve([]))
    setSales(pairOrdersNftOffersMade)
  }, [])

  useEffect(() => {
    if (!wallet.address) return
    loadOrders(wallet.address)
  }, [wallet.address, loadOrders])

  return <SalesHistoryView version={version} sales={sales} />
}

function getSaleType(walletAddress: string, order: OrderEntity) {
  if (walletAddress === order.sellerAddress) {
    if (order.sellAssetClass === AssetClass.ERC20 || order.sellAssetClass === AssetClass.ETH) return SaleType.OFFER
    if (order.sellAssetClass === AssetClass.ERC721) return SaleType.SALE
  }

  if (walletAddress === order.buyerAddress) {
    if (order.sellAssetClass === AssetClass.ERC20 || order.sellAssetClass === AssetClass.ETH) return SaleType.OFFER
    if (order.sellAssetClass === AssetClass.ERC721) return SaleType.BOUGHT
  }
  return SaleType.UNDEFINED
}
