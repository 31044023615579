import { OrderEntity } from 'Entities'
import { TypedDataField } from '@ethersproject/abstract-signer'
import BaseService from '../BaseService'

type IParamsGet = {} | null

export type IParamsPost = {
  sellerAddress: string
  sellTokenAddress: string
  sellTokenId: number
  sellTokenAmount: string
  askTokenAddress: string
  askTokenId: number
  askTokenAmount: string
  startTime: number
  expirationTime: number
  salt: number
  sellAssetClass: number
  askAssetClass: number
  orderHash: string
  orderSignature: string
}

type IQueryString = {
  q?: object
  page?: number
  limit?: number
  sort?: object
}

type IValidateQueryString = {
  tokenId: string
  tokenAddress: string
}

type IPropertiesQueryString = {} | null

type OrderEntityPaginateResponse = {
  totalItems: number
  data: OrderEntity[]
}

export type OrderProperties = Record<string, TypedDataField[]>

export class OrderValidationResponse {
  target?: OrderEntity
  property!: string
  value?: any
  constraints?: {
    [type: string]: string
  }
  children?: OrderValidationResponse[]
  contexts?: {
    [type: string]: any
  }
}

export default class Orders extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/orders`

  public static async get(params: IParamsGet, queryString: IQueryString): Promise<OrderEntityPaginateResponse> {
    const webServiceUrl = new URL(`${Orders.baseUrl}`)
    Object.entries(queryString).forEach(([key, value]) => webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return await this.httpGetPagination(webServiceUrl)
  }

  public static async post(params: IParamsPost): Promise<Response> {
    const webServiceUrl = new URL(`${Orders.baseUrl}`)
    return this.httpPost(webServiceUrl, params)
  }

  public static async validate(params: IParamsGet, queryString: IValidateQueryString): Promise<Response> {
    const webServiceUrl = new URL(`${Orders.baseUrl}/validate`)
    Object.entries(queryString).forEach(([key, value]) => webServiceUrl.searchParams.set(key, value.toString()))
    return this.httpGet(webServiceUrl)
  }

  public static async properties(params: IParamsGet, queryString: IPropertiesQueryString): Promise<OrderProperties> {
    const webServiceUrl = new URL(`${Orders.baseUrl}/properties`)
    return this.httpGet(webServiceUrl)
  }
}
