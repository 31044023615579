import { TokenSupportEntity } from 'Entities'
import { BigNumber } from 'ethers'

import supportedChains from './chains'

export interface IAssetData {
  symbol: string
  name: string
  decimals: string
  contractAddress: string
  balance?: string
}

export interface IChainData {
  name: string
  short_name: string
  chain: string
  network: string
  chain_id: number
  network_id: number
  rpc_url: string
  native_currency: IAssetData
}

function formatAddress(address: string) {
  if (address.length <= 10) {
    return address
  }
  return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length)
}

function getIpfsLink(link: string) {
  let tokenUri = link.split('ipfs://')[1]
  if (tokenUri !== undefined) {
    tokenUri = link.split('ipfs:/')[1]
    if (tokenUri !== undefined) {
      return process.env.REACT_APP_IPFS_GATEWAY + tokenUri
    }
    return link
  } else {
    if (isUrl(link)) {
      return link
    } else {
      return process.env.REACT_APP_IPFS_GATEWAY + '/' + link
    }
  }
}

function isUrl(link: string) {
  if (link.includes('http://') || link.includes('https://')) {
    return true
  } else {
    return false
  }
}

function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

function parseDecimals(number: string, decimals: number) {
  return (~~number * Math.pow(10, decimals!)).toString()
}

function getChainData(chainId: number): IChainData {
  const chainData = supportedChains.filter((chain: any) => chain.chain_id === chainId)[0]
  
  if (!chainData) {
    throw new Error('ChainId missing or not supported')
  }

  const API_KEY = process.env.REACT_APP_INFURA_ID

  if (chainData.rpc_url.includes('infura.io') && chainData.rpc_url.includes('%API_KEY%') && API_KEY) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY)

    return {
      ...chainData,
      rpc_url: rpcUrl,
    }
  }

  return chainData
}

function parseNumber(number: string, decimals: number) {
  return (Number(number) * Math.pow(10, -decimals)).toString()
}

function printPartialAddress(address: String) {
  let length = address.length
  return address.substring(0, 4) + '...' + address.substring(length - 3, length)
}

function isNativeToken(address: string) {
  return BigNumber.from(address).eq(0)
}

const daysOptions = [
  { value: 1, label: '1' },
  { value: 3, label: '3' },
  { value: 7, label: '7' },
  { value: 14, label: '14' },
  { value: 31, label: '31' },
  { value: 365, label: '365' },
]

function timeSince(date: Date) {
  var seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years ago'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months ago'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days ago'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes ago'
  }
  return Math.floor(seconds) + ' seconds ago'
}

function getToken(nativeToken: TokenSupportEntity | null, supportedTokens: TokenSupportEntity[], tokenAddress: string, ) {
  if(!nativeToken) throw new Error('Native Token not found')
  if(BigNumber.from(tokenAddress).isZero()) return nativeToken
  const tokens = supportedTokens.filter((token: TokenSupportEntity) => token.address === tokenAddress)
  if (!tokens.length) throw new Error('Supported token not found')
  return tokens[0]
}

export {
  formatAddress,
  getIpfsLink,
  isUrl,
  shuffleArray,
  getChainData,
  parseNumber,
  parseDecimals,
  printPartialAddress,
  daysOptions,
  isNativeToken,
  timeSince,
  getToken
}
