import { NftEntity } from 'Entities'
import BaseService from 'Webservices/BaseService'

type IParamsGet = {} | null

export type IQueryString = {
  q?: object
  page?: number
  limit?: number
  sort?: object
  f?: {
    tokenSupport?: any[],
    sellOrder?: any[],
    askOrders?: any[]
  }
}

type IQuerySynchronize = {
  tokenAddress: string
  tokenId?: number
  force?: 1 | 0
}

export type ICountResponse = {
  count: number
}

export default class Tokens extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/erc721/tokens`

  public static async get(params: IParamsGet = null, queryString: IQueryString): Promise<NftEntity[]> {
    const webServiceUrl = new URL(`${Tokens.baseUrl}`)
    const searchParams = this.addFilterEnabledTokens(queryString)
    Object.entries(searchParams).forEach(([key, value]) => webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return await this.httpGet(webServiceUrl)
  }

  public static async count(params: IParamsGet, queryString: IQueryString): Promise<ICountResponse> {
    const webServiceUrl = new URL(`${Tokens.baseUrl}/count`)
    const searchParams = this.addFilterEnabledTokens(queryString)
    Object.entries(searchParams).forEach(([key, value]) => webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return await this.httpGet(webServiceUrl)
  }

  public static async synchronize(params: IParamsGet, queryString: IQuerySynchronize): Promise<boolean> {
    const webServiceUrl = new URL(`${Tokens.baseUrl}/synchronize`)
    Object.entries(queryString).forEach(([key, value]) => {
      if (typeof value === 'string') {
        webServiceUrl.searchParams.set(key, value)
      } else {
        webServiceUrl.searchParams.set(key, JSON.stringify(value))
      }
    })

    const apiResponse = await this.httpGet(webServiceUrl)
    return apiResponse.data?.synchronized
  }

  public static async synchronizeOwner(params: IParamsGet, queryString: IQuerySynchronize): Promise<boolean> {
    const webServiceUrl = new URL(`${Tokens.baseUrl}/synchronizeOwner`)
    Object.entries(queryString).forEach(([key, value]) => {
      if (typeof value === 'string') {
        webServiceUrl.searchParams.set(key, value)
      } else {
        webServiceUrl.searchParams.set(key, JSON.stringify(value))
      }
    })

    const apiResponse = await this.httpGet(webServiceUrl)
    return apiResponse.data?.synchronized
  }

  private static addFilterEnabledTokens(queryString: IQueryString) {
    if (!queryString.f) {
      queryString.f = {
        tokenSupport: [{ $eq: ['$disabled', false] }, { $eq: ['$disabled', false] }],
      }
    } else {
      queryString.f.tokenSupport = [{ $eq: ['$disabled', false] }]
    }
    return queryString
  }
}
