import Torus from '@toruslabs/torus-embed'
import { default as WalletConnect } from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'

export function getWeb3Modal(): Web3Modal {
  return new Web3Modal(getWeb3ModalOptions())
}

function getWeb3ModalOptions() {
  const network = process.env.REACT_APP_DEFAULT_NETWORK
  if (!process.env.REACT_APP_DEFAULT_NETWORK) throw Error('Invalid Network Name')
  const providerOptions = getProviderOptions()
  return {
    network: network,
    cacheProvider: true,
    providerOptions: providerOptions,
  }
}

function getProviderOptions() {
  const providerOptions = {
    walletconnect: {
      package: WalletConnect,
      options: {
        rpc: {
          4: process.env.REACT_APP_RINKEBY_RPC_PROVIDER,
          56: process.env.REACT_APP_BSC_RPC_PROVIDER,
        },
      },
    },
    torus: {
      package: Torus,
      options: {
        networkParams: {
          host: process.env.REACT_APP_TORUS_DEFAULT_NETWORK,
          chainId: process.env.REACT_APP_DEFAULT_CHAINID
        },
      },
    },
    binancechainwallet: {
      package: true,
    },
  }
  return providerOptions
}
