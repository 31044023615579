import styled from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const ChallengeStyled = styled(Page)`
  padding: 80px 0 0 0 !important;
`

export const ChallengeContainer = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 0;

  padding: 0px 100px;

  text-align: left;

  min-height: unset;
  @media (max-width: ${Breakpoints.lg}) {
    padding: 0px 32px 0px 32px;
  }
`

export const ChallengeTitle = styled.h1`
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;

  color: #f8fafc;
  margin: 32px 0 0 0;
  @media (max-width: ${Breakpoints.lg}) {
    font-size: 26px;
    line-height: 31px;
  }
`
export const ChallengeComingSoonDesktop = styled.img`
  width: 100%;
  margin-top: -20px;
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const ChallengeComingSoonTablet = styled.img`
  width: 100%;
  display: none;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
  }

  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`
export const ChallengeComingSoonResponsive = styled.img`
  width: 100%;

  display: none;
  @media (max-width: ${Breakpoints.sm}) {
    display: block;
  }
`
