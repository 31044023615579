import { useSelector } from 'react-redux'
import { State } from 'reducers'

import { CheckoutModalView } from './CheckoutModal.view'

interface CheckoutModalProps {
  type: 'booster' | 'card'
  number: number
}
export const CheckoutModal = ({ type, number }: CheckoutModalProps) => {
  const version = useSelector((state: State) => state.version.number)
  return <CheckoutModalView version={version} type={type} number={number} />
}
