
import { EthContractConfigEntity } from 'Entities'
import BaseService from '../BaseService'

type IParamsGet = {} | null

type IQueryString = {
  protocol: string
  chainId: string
}

export default class Contract extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/contracts`
  public static async get(params: IParamsGet, queryString: IQueryString): Promise<EthContractConfigEntity> {
    const webServiceUrl = new URL(`${Contract.baseUrl}`)
    Object.entries(queryString).forEach(([key, value]) => {
      if (!value) return
      webServiceUrl.searchParams.set(key, value.toString())
    })
    return this.httpGet(webServiceUrl)
  }
}
