import styled, { css } from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const AcceptStyled = styled.div``

export const AcceptSubtotal = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #9542ff;
  margin-top: 24px;
  margin-bottom: 8px;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`
export const AcceptFees = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
  margin-top: 16px;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const AcceptInputContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  position: relative;

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.3;
      `
    }
    return
  }}
`

export const AcceptInputSkew = styled.div`
  position: absolute;
  background: #171719;
  transform: skew(-21deg);
  width: 50px;
  height: 100%;
  top: 0;
  z-index: 1;
`
export const AcceptInputSkewLeft = styled(AcceptInputSkew)`
  right: calc(86.5px + 17px);
`

export const AcceptInputSkewRight = styled(AcceptInputSkew)`
  right: calc(86.5px - 40px);
`

export const AcceptInput = styled.input`
  background: #171719;
  border: none;
  padding: 15px 24px;
  width: calc(100% - 130px);
  color: rgba(248, 250, 252, 0.5);

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  @media (max-width: 767px) {
    width: calc(100% - 120px);
  }

  z-index: 2;

  ::placeholder {
    color: rgba(248, 250, 252, 0.5);

    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`

export const AcceptInputRight = styled.p`
  margin: 0;
  padding: 14px 20px;
  width: 86.5px;
  color: white;
  background: #171719;
  z-index: 2;
`

export const AcceptFeesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 8px;
`

export const AcceptFeesTxt = styled.div`
  display: flex;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  margin-top: 8px;
`

export const AcceptFeesLeft = styled(AcceptFeesTxt)`
  font-size: 16px;
  line-height: 24px;
  color: #f8fafc;
  justify-content: flex-start;
`

export const AcceptFeesRight = styled(AcceptFeesTxt)`
  font-size: 14px;
  line-height: 21px;
  color: rgba(248, 250, 252, 0.5);
  justify-content: flex-end;
  text-align: right;
`

export const AcceptSeparator = styled.div`
  height: 1px;
  border-bottom: 1px solid #61616b;
  margin-top: 24px;
`

export const AcceptEarnings = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;

  color: #f8fafc;
  margin-top: 24px;
  padding-bottom: 8px;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const AcceptButtonContainer = styled.div`
  margin-top: 37px;
  width: 50%;
  margin: 37px auto 0 auto;

  > span {
    margin: 0;
    max-width: unset;
    min-width: unset;
    font-size: 16px !important;
  }

  @media (max-width: ${Breakpoints.lg}) {
    width: 30%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    width: 70%;
  }
`
