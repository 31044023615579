import styled from 'styled-components/macro'
//import { Breakpoints } from 'styles/breakpoints'

export const AttributeBlockStyled = styled.div`
  border: 1px solid #232327;
  padding: 9px;
`

export const Title = styled.p`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #3ad8ed;
  margin: 0px 0px 2px 0px;
`
export const SubTitle = styled.p`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;
  margin: 0px 0px 2px 0px;
`
export const Percentage = styled.p`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;

  margin: 0;
`
