import { TokenSupportEntity } from 'Entities'
import BaseService from 'Webservices/BaseService'

type IParamsGet = [] | null

type IQueryString = {
  userAddress: string
  tokenAddress?: string
}

export interface ITokenSupportBalance {
  name: TokenSupportEntity['name']
  address: TokenSupportEntity['address']
  symbol: TokenSupportEntity['symbol']
  decimals: TokenSupportEntity['decimals']
  balance: string
}

export default class Balances extends BaseService {
  protected static readonly baseUrl = `${process.env.REACT_APP_MARKETPLACE_API}/erc20/balances`
  public static async get(params: IParamsGet , queryString: IQueryString): Promise<ITokenSupportBalance[]> {
    const webServiceUrl = new URL(`${Balances.baseUrl}`)
    Object.entries(queryString).forEach(([key, value]) => webServiceUrl.searchParams.set(key, value))
    return (await this.httpGet(webServiceUrl))
  }
}
