import { Button } from 'app/App.components/Button/Button.view'
import { useHistory } from 'react-router-dom'

import {
  HowToBuyCard,
  HowToBuyCardBtn,
  HowToBuyCardLogo,
  HowToBuyCardText,
  HowToBuyContainers,
  HowToBuyStyled,
  HowToBuyText,
  HowToBuyTitle,
} from './HowToBuy.style'

export default function HowToBuy() {
  const history = useHistory()
  return (
    <HowToBuyStyled>
      <HowToBuyTitle>HOW TO BUY NFTs and be part of the Web 3.0 Sports Revolution?</HowToBuyTitle>
      <HowToBuyText>
        To buy an NFT of our first-ever collection, you need to be whitelisted. But, first, just give us your email, and
        we will tell you what’s next.
      </HowToBuyText>
      <HowToBuyContainers>
        <HowToBuyCard>
          <HowToBuyCardLogo src="/images/discord_logo.png" alt="" />
          <HowToBuyCardText>To win a WL, there is only one place to be: our discord server</HowToBuyCardText>
          <HowToBuyCardBtn>
            <Button
              appearance="primary_empty"
              style={{ margin: 0 }}
              clickCallback={() => window.open('https://discord.com/invite/M2GAQQ64Fa', '_blank')?.focus()}
            >
              DISCORD
            </Button>
          </HowToBuyCardBtn>
        </HowToBuyCard>
        <HowToBuyCard>
          <HowToBuyCardLogo src="/images/mint_logo.png" alt="" />
          <HowToBuyCardText>After the sale, you will fint NFTs directly on our marketplace</HowToBuyCardText>
          <HowToBuyCardBtn>
            <Button appearance="primary_empty" style={{ margin: 0 }} clickCallback={() => history.push('/marketplace')}>
              MARKETPLACE
            </Button>
          </HowToBuyCardBtn>
        </HowToBuyCard>
      </HowToBuyContainers>
    </HowToBuyStyled>
  )
}
