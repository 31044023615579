// prettier-ignore
import * as React from 'react'
import { AcceptView } from './Accept.view'

interface AcceptProps {
  subTotal: number
}
export const Accept = ({ subTotal }: AcceptProps) => {
  let serviceFees = 2.5
  let royalties = 10
  let earnings = subTotal * ((100 - serviceFees - royalties) / 100)
  return <AcceptView subTotal={subTotal} earnings={earnings} />
}
