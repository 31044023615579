import { ComingSoonImgStyled, ComingSoonImgDesktop, ComingSoonImgResponsive } from './ComingSoonImg.style'

export interface ComingSoonImgProps {
  margin: [number, number]
}

export const ComingSoonImg = ({ margin }: ComingSoonImgProps) => {
  return (
    <ComingSoonImgStyled margin={margin}>
      <ComingSoonImgDesktop src="/images/coming-soon-bands.png" alt="" />
      <ComingSoonImgResponsive src="/images/coming-soon-bands-responsive.png" alt="" />
    </ComingSoonImgStyled>
  )
}
