import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const HowToBuyStyled = styled.div`
  margin-top: var(--collection-margin);

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 64px;
  }
`

export const HowToBuyTitle = styled.p`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;
  margin: 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const HowToBuyText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #f8fafc;
  margin: 24px 0 0 0;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`

export const HowToBuyContainers = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 64px;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
  }
`
export const HowToBuyCard = styled.div`
  border: 1px solid #61616b;
  padding: 40px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HowToBuyCardLogo = styled.img``
export const HowToBuyCardText = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #f8fafc;

  margin-top: 24px;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }
`
export const HowToBuyCardBtn = styled.div`
  margin-top: 24px;
  width: 220px;

  span {
    margin: 0;
  }
`
