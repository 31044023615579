import styled from 'styled-components/macro'
import { backgroundColor } from 'styles'

import { Breakpoints } from './breakpoints'

interface DivProps {
  margin?: string
  marginLeft?: number
  marginRight?: number
  marginTop?: number
  marginBottom?: number
  padding?: string
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  paddingBottom?: number
  height?: number
  width?: number
  position?: string
  top?: number
  bottom?: number
  right?: number
  left?: number
}

interface BoxProps extends DivProps {
  display?: string
  flexDirection?: string
  alignItems?: string
  justifyContent?: string
  textAlign?: string
  backgroundColor?: string
}

interface TextProps {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  fontSize?: string
  lineHeight?: string
  color?: string
}

interface IconRightProps {
  icon?: string
}

const divStyling = (props: DivProps) => {
  return `
    ${props.margin ? `margin : ${props.margin};` : ''}
    ${props.marginLeft ? `margin-left : ${props.marginLeft + 'px'};` : ''}
    ${props.marginRight ? `margin-right : ${props.marginRight + 'px'};` : ''}
    ${props.marginTop ? `margin-top : ${props.marginTop + 'px'};` : ''}
    ${props.marginBottom ? `margin-bottom : ${props.marginBottom + 'px'};` : ''}
    ${props.padding ? `padding : ${props.padding};` : ''}
    ${props.paddingLeft ? `padding-left : ${props.paddingLeft + 'px'};` : ''}
    ${props.paddingRight ? `padding-right : ${props.paddingRight + 'px'};` : ''}
    ${props.paddingTop ? `padding-top : ${props.paddingTop + 'px'}` : ''}
    ${props.paddingBottom ? `padding-bottom : ${props.paddingBottom + 'px'};` : ''}
    ${props.height ? `height : ${props.height + 'px'};` : ''}
    ${props.width ? `width : ${props.width + 'px'};` : ''}
    ${props.top !== undefined ? `top : ${props.top + 'px'};` : ''}
    ${props.right !== undefined ? `right : ${props.right + 'px'};` : ''}
    ${props.bottom !== undefined ? `bottom : ${props.bottom + 'px'};` : ''}
    ${props.left !== undefined ? `left : ${props.left + 'px'};` : ''}
    ${props.position ? `position: ${props.position};`: ''}
  `
}

export const Page = styled.div`
  margin: auto;
  padding: 80px 40px 40px 40px;
  /* max-width: 1280px; */
  width: 100%;
  position: relative;
  background-color: ${backgroundColor};
  min-height: 100vh;
  max-width: 2400px;
`

export const MainLogo = styled.img`
  display: block;
  margin: 30px auto;
`

export const Box = styled.div<BoxProps>`
  ${(props) => divStyling(props)}
  ${(props) => `
    ${props.display ? `display : ${props.display};` : ''}
    ${props.flexDirection ? `flex-direction : ${props.flexDirection};` : ''}
    ${props.alignItems ? `align-items : ${props.alignItems};` : ''}
    ${props.justifyContent ? `justify-content : ${props.justifyContent};` : ''}
    ${props.textAlign ? `text-align : ${props.textAlign};` : ''}
    ${props.backgroundColor ? `background-color : ${props.backgroundColor};` : ''}
  `}
`

export const Image = styled.img<DivProps>`
  max-width: 100%;
  ${(props) => divStyling(props)}
`

export const Text = styled.p<TextProps>`
  ${(props) => `
  ${props.fontFamily ? `font-family : ${props.fontFamily};` : ''}
  ${props.fontStyle ? `font-style : ${props.fontStyle};` : ''}
  ${props.fontWeight ? `font-weight : ${props.fontWeight};` : ''}
  ${props.fontSize ? `font-size : ${props.fontSize};` : ''}
  ${props.lineHeight ? `line-height: ${props.lineHeight};` : ''}
  ${props.color ? `color: ${props.color}` : ''}

  `}
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const Card = styled(Box)`
  display: block;
  margin: auto;
  padding: 50px;
  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(235, 236, 240, 0.2) 0%,
    rgba(235, 236, 240, 0.0447917) 77.08%,
    rgba(235, 236, 240, 0) 100%
  );
  backdrop-filter: blur(14px);
  border-radius: 40px;
  width: ${(props) => props.width || '650px'};

  border: 2px solid;
  border-image-source: radial-gradient(80.38% 222.5% at -13.75% -12.36%, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(80.69% 208.78% at 108.28% 112.58%, #ffffff 0%, rgba(18, 30, 52, 0) 100%);

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const MaxScreenWidth = styled.div<{ maxWidth?: string }>`
  position: relative;
  max-width: ${Breakpoints.xxxl};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : `${Breakpoints.xxxl}`)};
  margin: auto;
`

/**
 * A simple input with an icon on its right
 */
 export const InputIconRight = styled.input<IconRightProps>`
 background: #232327 url(${props => props.icon || ''}) 90% center no-repeat;
`