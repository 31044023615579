import { ComingSoonImg } from 'app/App.components/ComingSoonImg/ComingSoonImg.view'
import { TutorialsContainer, TutorialsStyled, TutorialsTitle } from './Tutorials.style'

export const TutorialsView = () => {
  return (
    <TutorialsStyled>
      <TutorialsContainer>
        <TutorialsTitle>Tutorials</TutorialsTitle>
      </TutorialsContainer>
      <ComingSoonImg margin={[100, 85]} />
    </TutorialsStyled>
  )
}
