import { SubInfo } from 'pages/Collection/InfoComponent/SubInfo/SubInfo.view'
import ReactPlayer from 'react-player'
import { Box } from 'styles'

import { Text } from '../../CollectionStory.style'
import {
  Banniere,
  Container,
  ContainerMobile,
  DropdownUtilityStyled,
  Element,
  ElementLeft,
  FirstPart,
  HelicopterContainer,
  ImageBox,
  LeftPart,
  LeftPartBanniere,
  RightPart,
  RightPartBanniere,
  Row,
  SecondPart,
  SLoebDriving,
  SloebImage,
  Table,
  TextContainer,
  ThirdPartComponent,
  Title,
  TitleFanMobile,
  TitleFanTable,
  TitlePremiumMobile,
  TitlePremiumTable,
  TitlePrizeTable,
  TitleWhatIf,
  TitleWinAMoment,
  TitleWinKind,
  WhatIfLines,
} from './DropdownUtility.style'

export const DropdownUtility = () => {
  return (
    <DropdownUtilityStyled>
      {firstPartComponent()}
      {firstBanniereComponent()}
      {secondPartComponent()}
      {tableComponent()}
      {tableComponentMobile()}
      <HelicopterContainer>
        <ReactPlayer
          url={'/images/collection/helicopter.mp4'}
          playing
          playsinline
          muted
          loop
          width="80%"
          height="100%"
          style={{ margin: 'auto auto 90px auto' }}
        ></ReactPlayer>
      </HelicopterContainer>
      {secondBanniereComponent()}
      {thirdPartComponent()}
    </DropdownUtilityStyled>
  )

  function firstPartComponent() {
    return (
      <FirstPart>
        <Box marginBottom={4}>
          <TitleWinKind>WIN THE KIND OF STUFF MONEY CAN'T BUY</TitleWinKind>
        </Box>
        <Box marginBottom={24}>
          <TitleWinAMoment>WIN A MOMENT YOU WILL NEVER FORGET</TitleWinAMoment>
        </Box>
        <Box marginBottom={8}>
          <Text>Our first collection of NFT featured 9 times World Champion of Rally: Sébastien LOEB.</Text>
          <Text>
            This more than just an NFT collection. This is a ticket to a real fan experience with your champion.
          </Text>
          <Text>You have watched him push back the limit of speed so many times on TV.</Text>
        </Box>
      </FirstPart>
    )
  }

  function firstBanniereComponent() {
    return (
      <Banniere>
        <LeftPartBanniere>
          <TitleWhatIf style={{ textAlign: 'left' }}>
            What if you could be a co-driver with sebastien loeb ?
          </TitleWhatIf>
        </LeftPartBanniere>
        <WhatIfLines src="/images/collection/whatif_lines.png" alt="" style={{ height: '100%' }} />
        <RightPartBanniere>
          <Box marginBottom={16}>
            <Text>
              Yes, with him! You can go to live the most intense driving experience of your life, next to Sébastien
              Loeb.
            </Text>
          </Box>
          <Text>
            Each NFT comes with a lottery ticket, and 22 lucky owners will randomly win a driving experience with
            Sébastien Loeb on a private circuit, in a real Rally Car.
          </Text>
        </RightPartBanniere>
      </Banniere>
    )
  }

  function secondPartComponent() {
    return (
      <SecondPart>
        <LeftPart>
          <Text>
            You will be able to drive with Sébastien as a co-driver, but also to hold your breath on the co-driver seat
            as Sébastien Loeb is driving.
            <br />
            <br /> But what would be a great experience without the memory that goes with it?
            <br />
            <br /> Your full driving experience with Sébastien Loeb will be filmed and recorded for your best pleasure.
            <br />
            <br />
            You can keep it to yourself or offer it to someone you love.
            <br />
            <br /> But one thing is sure, nobody will believe you when you'll tell that you have driven next to the most
            legendary rally driver of all time.
          </Text>
        </LeftPart>
        <RightPart>
          <SLoebDriving src="/images/collection/drive_sebastien_loeb.png" alt="" />
        </RightPart>
      </SecondPart>
    )
  }

  function tableComponent() {
    return (
      <Container>
        <Box marginBottom={32}>
          <Title>the prizes you will earn with this collection</Title>
        </Box>
        <Table>
          <Row borderBottom={true}>
            <Element>
              <TitlePrizeTable>Prize</TitlePrizeTable>
            </Element>
            <Element>
              <TitlePremiumTable>Premium Series</TitlePremiumTable>
            </Element>
            <Element>
              <TitleFanTable>Fan Series</TitleFanTable>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Helicopter flight with Sébastien Loeb, for 2 people</Text>
            </ElementLeft>
            <Element>
              <Text>1</Text>
            </Element>
            <Element>
              <Text>1</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Driving session with Sébastien Loeb</Text>
            </ElementLeft>
            <Element>
              <Text>20</Text>
            </Element>
            <Element>
              <Text>2</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Call with Sébastien Loeb</Text>
            </ElementLeft>
            <Element>
              <Text>10</Text>
            </Element>
            <Element>
              <Text>10</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>VIP invitation on a Sébastien Loeb race, for 2 people</Text>
            </ElementLeft>
            <Element>
              <Text>100</Text>
            </Element>
            <Element>
              <Text>50</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Signed Rally Gloves</Text>
            </ElementLeft>
            <Element>
              <Text>50</Text>
            </Element>
            <Element>
              <Text>100</Text>
            </Element>
          </Row>
          <Row borderBottom={false}>
            <ElementLeft>
              <Text>Membership Pass LOEB</Text>
            </ElementLeft>
            <Element>
              <Text>2004</Text>
            </Element>
            <Element>
              <Text>2004</Text>
            </Element>
          </Row>
        </Table>
      </Container>
    )
  }

  function tableComponentMobile() {
    return (
      <ContainerMobile>
        <Box marginBottom={32}>
          <Title>the prizes you will earn with this collection</Title>
        </Box>
        <Table>
          <Row borderBottom={true} height={81}>
            <Element>
              <TitlePremiumMobile>Premium Series</TitlePremiumMobile>
            </Element>
            <Element>
              <TitleFanMobile>Fan Series</TitleFanMobile>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Helicopter flight with Sébastien Loeb, for 2 people</Text>
            </ElementLeft>
          </Row>
          <Row borderBottom={true}>
            <Element>
              <Text>1</Text>
            </Element>
            <Element>
              <Text>1</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Driving session with Sébastien Loeb</Text>
            </ElementLeft>
          </Row>
          <Row borderBottom={true}>
            <Element>
              <Text>20</Text>
            </Element>
            <Element>
              <Text>2</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Call with Sébastien Loeb</Text>
            </ElementLeft>
          </Row>
          <Row borderBottom={true}>
            <Element>
              <Text>10</Text>
            </Element>
            <Element>
              <Text>10</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>VIP invitation on a Sébastien Loeb race, for 2 people</Text>
            </ElementLeft>
          </Row>
          <Row borderBottom={true}>
            <Element>
              <Text>100</Text>
            </Element>
            <Element>
              <Text>50</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Signed Rally Gloves</Text>
            </ElementLeft>
          </Row>
          <Row borderBottom={true}>
            <Element>
              <Text>50</Text>
            </Element>
            <Element>
              <Text>100</Text>
            </Element>
          </Row>
          <Row borderBottom={true}>
            <ElementLeft>
              <Text>Membership Pass LOEB</Text>
            </ElementLeft>
          </Row>
          <Row borderBottom={false}>
            <Element>
              <Text>2004</Text>
            </Element>
            <Element>
              <Text>2004</Text>
            </Element>
          </Row>
        </Table>
      </ContainerMobile>
    )
  }

  function secondBanniereComponent() {
    return (
      <Banniere>
        <LeftPartBanniere>
          <TitleWhatIf style={{ textAlign: 'left' }}>Keep your prize, or trade it on the marketplace.</TitleWhatIf>
        </LeftPartBanniere>
        <WhatIfLines src="/images/collection/whatif_lines.png" alt="" style={{ height: '100%' }} />
        <RightPartBanniere>
          <Box marginBottom={16}>
            <Text>
              Each NFT holder will automatically participate in the prize lottery. The winners of the lottery will
              receive an NFT of the prize directly in their wallet.
            </Text>
          </Box>
          <Text>
            Once you receive a prize NFT in your wallet, you will be able to keep it, sell it on the marketplace to
            other fans, or ask for the service.
          </Text>
        </RightPartBanniere>
      </Banniere>
    )
  }

  function thirdPartComponent() {
    return (
      <ThirdPartComponent>
        <TextContainer>
          <Box marginBottom={24}>
            <Title style={{ textAlign: 'left' }}>THE MOST EXCLUSIVE WEB3 SPORTS FAN COMMUNITY.</Title>
          </Box>
          <Box marginBottom={24}>
            <Text>
              Each NFT is also a pass to enter the MyTVchain Universe, for now, and forever. A magical world for true
              sports fans where living your passion can make you a living.
            </Text>
          </Box>
          <Box marginBottom={24}>
            <Text>
              Be part of the strongest Sports FAN family of the Web3, and live sport like you always wanted to:{' '}
            </Text>
          </Box>
          <Box marginBottom={24}>
            <SubInfo title={'Access to an exceptional #Fan2Earn experience and win $MYTV tokens into ”The Stadium”'} />
          </Box>
          <Box marginBottom={24}>
            <SubInfo title={'Exclusive rewards and advantages depending on the rarity of your NFT'} />
          </Box>
          <Box marginBottom={24}>
            <SubInfo title={'Access to unique and exclusive staking pools on our Sports DeFi dashboard'} />
          </Box>
          <Box marginBottom={24}>
            <SubInfo title={'Exclusive deal with partners'} />
          </Box>
        </TextContainer>
        <ImageBox>
          <SloebImage src="/images/collection/packets.png" />
        </ImageBox>
      </ThirdPartComponent>
    )
  }
}
