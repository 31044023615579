import { NftEntity, TokenSupportEntity } from 'Entities'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { State } from 'reducers'
import { Filter, FilterType, getTokenPriceFilter } from 'utils/Filter'
import { addFiltersAndSortToUrl, getFilterAndSortFromSearchParams } from 'utils/SearchUtils'
import { Sort, Sorts, SortType } from 'utils/Sort'
import * as Webservices from 'Webservices'
import { IQueryString } from 'Webservices/Marketplace/Erc721/Tokens'
import { MarketplaceView } from './Marketplace.view'

type IFetchParams = {
  filters: Filter[]
  sort: Sort
  priceSort: Sort
}

export const Marketplace = () => {
  const version = useSelector((state: State) => state.version.number)
  const [fetchParams, setFetchParams] = useState<IFetchParams>({
    filters: [],
    sort: Sorts[SortType.NO_SORT],
    priceSort: Sorts[SortType.NO_SORT],
  })
  const [tokens, setTokens] = useState<NftEntity[]>([])
  const [initialLoad, setInitialLoad] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const nativeToken = useSelector((state: State) => state.nativeToken)
  const supportedTokens = useSelector((state: State) => state.supportedTokens)
  const [selectedToken, setSelectedToken] = useState<TokenSupportEntity | null>(null)
  const [min, setMin] = useState<string | null>(null)
  const [max, setMax] = useState<string | null>(null)
  const [priceSort, setPriceSort] = useState<Sort>(Sorts[SortType.NO_SORT])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const url = '/marketplace'

  const getTotalItems = React.useCallback(async (filters: Filter[], sort: Sort, priceSort: Sort) => {
    const total = (await Webservices.Erc721.Tokens.count(null, getQueryString(0, filters, sort, priceSort))).count
    if (!total) return
    setTotalItems(total)
  }, [])

  const fetchTokens = React.useCallback(async (queryString: IQueryString) => {
    const res = await Webservices.Erc721.Tokens.get(null, queryString)
    if (!res) return
    setTokens((state) => {
      return state.concat(res)
    })
  }, [])

  const loadMore = React.useCallback(
    async (e) => {
      await setLoading(true)
      await fetchTokens(getQueryString(e, fetchParams.filters, fetchParams.sort, fetchParams.priceSort))
      await setLoading(false)
    },
    [fetchParams.filters, fetchParams.priceSort, fetchParams.sort, fetchTokens],
  )

  const initialFetchTokens = React.useCallback(
    async (filters, sort, priceSort) => {
      await getTotalItems(filters, sort, priceSort)
      setInitialLoad(false)
    },
    [getTotalItems],
  )

  const resetState = React.useCallback(async () => {
    await setTokens([])
    await setTotalItems(0)
  }, [])

  const fetchTokensWithTokenFilter = React.useCallback(
    (selectedToken: TokenSupportEntity | null) => {
      if (!selectedToken) return
      const actualFilters = fetchParams.filters.slice()
      const tokenPriceFilter = getTokenPriceFilter(selectedToken, min, max)
      addFiltersAndSortToUrl(
        url,
        history,
        [...actualFilters.filter((filter) => filter.type !== FilterType.PRICE), tokenPriceFilter],
        fetchParams.sort,
        priceSort,
      )
    },
    [fetchParams.filters, fetchParams.sort, min, max, priceSort, history],
  )

  const toggleFilter = React.useCallback(
    (newFilter: Filter) => {
      setTotalItems(0)
      const actualFilters = fetchParams.filters.slice()
      if (actualFilters.some((filters) => filters.type === newFilter.type)) {
        addFiltersAndSortToUrl(
          url,
          history,
          actualFilters.filter((filter) => filter.type !== newFilter.type),
          fetchParams.sort,
          fetchParams.priceSort,
        )
        return
      }
      addFiltersAndSortToUrl(url, history, [newFilter, ...fetchParams.filters], fetchParams.sort, fetchParams.priceSort)
    },
    [fetchParams, history],
  )

  const removeSortAndFilters = React.useCallback(() => {
    addFiltersAndSortToUrl(url, history, [], Sorts[SortType.NO_SORT], Sorts[SortType.NO_SORT])
  }, [history])

  const changeSort = React.useCallback(
    async (sort: SortType) => {
      addFiltersAndSortToUrl(url, history, fetchParams.filters, Sorts[sort], priceSort)
    },
    [history, fetchParams.filters, priceSort],
  )

  const initialLoadData = React.useCallback(async () => {
    await setInitialLoad(true)
    resetState()
    if (!nativeToken || !supportedTokens.length) {
      setInitialLoad(false)
      return
    }
    const search = location.search
    const searchParams = new URLSearchParams(search)
    const {
      filters: _filters,
      sort: _sort,
      priceSort: _priceSort,
      selectedToken: _selectedToken,
    } = getFilterAndSortFromSearchParams(searchParams, [nativeToken!, ...supportedTokens])

    await setSelectedToken(_selectedToken)
    await setPriceSort(_priceSort)
    await setFetchParams({ filters: _filters, sort: _sort, priceSort: _priceSort })
    initialFetchTokens(_filters, _sort, _priceSort)
  }, [initialFetchTokens, location.search, nativeToken, resetState, supportedTokens])

  useEffect(() => {
    if (nativeToken) setSelectedToken(nativeToken)
  }, [nativeToken])

  useEffect(() => {
    initialLoadData()
  }, [initialLoadData])

  return (
    <MarketplaceView
      version={version}
      tokens={tokens}
      filters={fetchParams.filters}
      toggleFilter={toggleFilter}
      removeSortAndFilters={removeSortAndFilters}
      initialLoad={initialLoad}
      totalItems={totalItems}
      changeSort={changeSort}
      sort={fetchParams.sort}
      selectedToken={selectedToken}
      setSelectedToken={setSelectedToken}
      loadMore={loadMore}
      min={min}
      max={max}
      setMin={setMin}
      setMax={setMax}
      priceSort={priceSort}
      setPriceSort={setPriceSort}
      fetchTokensWithTokenFilter={fetchTokensWithTokenFilter}
      loading={loading}
    />
  )
}

function getQueryString(page: number, filters: Filter[], sort: Sort, priceSort: Sort) {
  let queryString
  queryString = { page: page, limit: 10 }
  let f = filters.reduce((query, next) => {
    return { ...next.f, ...query }
  }, {})
  let q = filters.reduce((query, next) => {
    return { ...next.q, ...query }
  }, {})

  let querySort = {}
  if (sort) querySort = { ...querySort, ...sort.sort }
  if (priceSort) querySort = { ...querySort, ...priceSort.sort }

  queryString = { f: f, q: q, sort: querySort, ...queryString }

  return queryString
}
